import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import { Link, useHistory } from "react-router-dom";
import { has } from "lodash";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { routes } from "../../routes";
import { UserContext } from "../../context/userContext";
import { isAuthed } from "../../utils/authorization";
import styles from "./Sidebar.module.scss";
import {
  Avatar,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Paper,
  Typography,
  Collapse,
  IconButton,
  MenuItem,
} from "@material-ui/core";
import Icon from "@mdi/react";
import { mdiMap, mdiHammerWrench, mdiSettings, mdiChevronDown, mdiCogs } from "@mdi/js";
import StorageIcon from "@material-ui/icons/Storage";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Menu from "../../components/Menu/Menu";
import Version from "../Version/Version";
import { isProd } from "../../utils/const";

const Sidebar = ({ params, org, open, handleClose, isDesktop }) => {
  const { user, setUser } = useContext(UserContext);
  const [collapse, setCollapse] = useState();
  const { data, loading } = useQuery(GET_PLANS, {
    variables: { id: params.org },
    fetchPolicy: "network-only", // Used for first execution
    nextFetchPolicy: "cache-first", // Used for subsequent executions
  });
  const fiscalYear = org.fiscalYear;
  const history = useHistory();

  const handleCollapse = (name) => () => {
    if (name === collapse) {
      setCollapse();
    } else {
      setCollapse(name);
    }
  };

  const handleClick = (id, sharedPlanId) => () => {
    setUser({ ...user, departmentFilter: { id, sharedPlanId } });
    if (_.isNil(id)) {
      sessionStorage.removeItem("departmentFilterId");
    } else {
      sessionStorage.setItem("departmentFilterId", id);
    }

    if (_.isNil(sharedPlanId)) {
      sessionStorage.removeItem("departmentFilterSharedId");
    } else {
      sessionStorage.setItem("departmentFilterSharedId", sharedPlanId);
    }
  };

  const navigateToSettings = () => {
    history.push(`/${params.org}/settings`);
  };

  if (!user || loading) return null;
  const { name, profilePicture, position, auth, plan } = user.user;

  const userPlans = _.get(data, "plans", []);
  const currentUserPlans = userPlans.filter((plan) => plan.year === fiscalYear);
  const hasCorporate = currentUserPlans.some((plan) => plan.departmentName === "Corporate");
  const selectedDept = currentUserPlans.find((plan) => plan.sharedPlanId === user.departmentFilter.sharedPlanId);

  return (
    <div>
      <Drawer
        open={open}
        onClose={handleClose}
        variant={isDesktop ? "persistent" : "temporary"}
        PaperProps={{ className: isDesktop ? styles.paper : styles.paperMobile }}
      >
        <Paper className={styles.user}>
          <div className={styles.userWrapper}>
            <div className={styles.avatarWrapper}>
              <Avatar className={styles.avatar} src={profilePicture} onClick={navigateToSettings}>
                {name.first[0]}
                {name.last[0]}
              </Avatar>
            </div>
            <div className={styles.columnFlex}>
              <Typography className={styles.userName} align="center">
                {name.first} {name.last}
              </Typography>
              <Typography variant="subtitle2" align="center" className={styles.authState}>
                {auth} {has(plan, "departmentName") && <>({plan.departmentName})</>}
              </Typography>
            </div>
          </div>
          <Typography variant="subtitle2" align="center">
            {position}
          </Typography>

          <div className={styles.center}>
            <Menu
              button={
                <span className={styles.departmentSelector}>
                  {_.get(selectedDept, "departmentName", "All Departments")}{" "}
                  <Icon color="#fff" size={0.75} path={mdiChevronDown} className={styles.icon} />
                </span>
              }
            >
              <MenuItem onClick={handleClick(null, null)} disabled={user.departmentFilter.id === null}>
                All Departments
              </MenuItem>
              {data &&
                currentUserPlans.map(({ id, sharedPlanId, departmentName }) => (
                  <MenuItem key={id} onClick={handleClick(id, sharedPlanId)} disabled={user.departmentFilter.id === id}>
                    {departmentName}
                  </MenuItem>
                ))}
            </Menu>
          </div>
        </Paper>
        <div className={styles.wrapper}>
          <List component="nav" dense>
            {routes
              .slice(0)
              .reverse()
              .map((props) => {
                // Loop through reverse so dashboard appears on top
                const { path, name, type, layout, icon } = props;
                if (type !== "primary" || !layout.includes(auth)) return null;
                return (
                  <Link to={`/${params.org}/${path}`} key={name} className={styles.link}>
                    <ListItem button>
                      <ListItemIcon>
                        <Icon path={icon} className={styles.icon} size={1} color="white" />
                      </ListItemIcon>
                      <ListItemText className={styles.text}>{name}</ListItemText>
                    </ListItem>
                  </Link>
                );
              })}
            <ListItem button onClick={handleCollapse("Strategic Roadmap")}>
              <ListItemIcon>
                <Icon path={mdiMap} className={styles.icon} size={1} color="white" />
              </ListItemIcon>
              <ListItemText className={styles.text}>Strategic Roadmap</ListItemText>
            </ListItem>
            <Collapse in={collapse === "Strategic Roadmap"}>
              {routes.map((props) => {
                const { path, name, type, layout, icon } = props;
                if (type !== "strategic roadmap" || !layout.includes(auth) || (!hasCorporate && path === "3-year")) return null;
                return (
                  <Link to={`/${params.org}/${path}`} key={name} className={styles.link}>
                    <ListItem button className={styles.nested}>
                      <ListItemIcon>
                        <Icon path={icon} className={styles.icon} size={1} color="white" />
                      </ListItemIcon>
                      <ListItemText className={styles.text}>{name}</ListItemText>
                    </ListItem>
                  </Link>
                );
              })}
            </Collapse>
            <ListItem button onClick={handleCollapse("Execution Tools")}>
              <ListItemIcon>
                <Icon path={mdiHammerWrench} className={styles.icon} size={1} color="white" />
              </ListItemIcon>
              <ListItemText>Execution Tools</ListItemText>
            </ListItem>
            <Collapse in={collapse === "Execution Tools"}>
              {routes.map((props) => {
                const { path, name, type, layout, icon } = props;
                if (type !== "execution tools" || !layout.includes(auth)) return null;
                return (
                  <Link to={`/${params.org}/${path}`} key={name} className={styles.link}>
                    <ListItem button className={styles.nested}>
                      <ListItemIcon>
                        <Icon path={icon} className={styles.icon} size={1} color="white" />
                      </ListItemIcon>
                      <ListItemText className={styles.text}>{name}</ListItemText>
                    </ListItem>
                  </Link>
                );
              })}
            </Collapse>
          </List>
          <List className={styles.list} dense>
            {routes.map((props) => {
              const { path, name, type, layout, icon } = props;
              if (type !== "tertiary" || !layout.includes(auth)) return null;
              return (
                <Link to={`/${params.org}/${path}`} key={name} className={styles.link}>
                  <ListItem button>
                    <ListItemIcon>
                      <Icon path={icon} className={styles.icon} size={1} color="white" />
                    </ListItemIcon>
                    <ListItemText className={styles.text}>{name}</ListItemText>
                  </ListItem>
                </Link>
              );
            })}
            {isAuthed(user.user, "ventrek facilitator") && (
              <>
                <ListItem button onClick={handleCollapse("System")}>
                  <ListItemIcon>
                    <Icon path={mdiCogs} className={styles.icon} size={1} color="white" />
                  </ListItemIcon>
                  <ListItemText className={styles.text}>System</ListItemText>
                </ListItem>
                <Collapse in={collapse === "System"}>
                  {routes.map((props) => {
                    const { path, name, type, layout, icon } = props;
                    if (type !== "system" || !layout.includes(auth)) return null;
                    if ((path === "system-tasks" || path === "saved-notifications") && isProd) return null;
                    return (
                      <Link to={`/${params.org}/${path}`} key={name} className={styles.link}>
                        <ListItem button className={styles.nested}>
                          <ListItemIcon>
                            <Icon path={icon} className={styles.icon} size={1} color="white" />
                          </ListItemIcon>
                          <ListItemText className={styles.text}>{name}</ListItemText>
                        </ListItem>
                      </Link>
                    );
                  })}
                </Collapse>
              </>
            )}
            {isAuthed(user.user, "ventrek facilitator") && (
              <Link to="/" className={styles.link}>
                <ListItem button>
                  <ListItemIcon>
                    <StorageIcon className={styles.icon} fontSize="large" />
                  </ListItemIcon>
                  <ListItemText className={styles.text}>Change Organization</ListItemText>
                </ListItem>
              </Link>
            )}

            <Link to="/signout" className={styles.link}>
              <ListItem button>
                <ListItemIcon>
                  <ExitToAppIcon className={styles.icon} fontSize="large" />
                </ListItemIcon>
                <ListItemText className={styles.text}>Sign out</ListItemText>
              </ListItem>
            </Link>
          </List>
        </div>
        <Version />
      </Drawer>
    </div>
  );
};

export default Sidebar;

const GET_PLANS = gql`
  query Sidebar_GetPlans($id: ID!) {
    plans(organization: $id, closed: false, category: "1 year") {
      id
      departmentName
      sharedPlanId
      year
    }
  }
`;
