import _ from "lodash";

export const serialize = (nodes) => {
  return JSON.stringify(nodes);
};

export const deserialize = (string) => {
  try {
    const result = JSON.parse(string);
    if (_.get(result, "0.type") === undefined) {
      throw Error("not a valid JSON object");
    }
    return result;
  } catch (e) {
    // serialize old format
    const result = [
      {
        type: "paragraph",
        children: [{ text: string }],
      },
    ];
    return result;
  }
};
