import React, { useState } from "react";
import _ from "lodash";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import styles from "./AddOrgDialog.module.scss";
import useForm from "../../hooks/useForm";
import {
  Button,
  TextField,
  InputAdornment,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  useMediaQuery,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import Loading from "../Loading/Loading";

const initForm = {
  name: null,
  fiscalYear: new Date(),
  core: {
    purpose: [],
    value: [],
    process: [],
    market: [],
    offering: [],
    valueProposition: [],
    differentiation: [],
    competency: [],
    plan: null,
  },
};

const initErrorForm = {
  name: ["required"],
};

const AddOrgDialog = ({ dialog, setDialog, requestFetch, snack }) => {
  const [createOrg, { loading }] = useMutation(CREATE_ORG);
  const [coreCounter, setCoreCounter] = useState({
    purpose: 1,
    value: 1,
    process: 1,
    market: 1,
    offering: 1,
    valueProposition: 1,
    differentiation: 1,
    competency: 1,
  });

  const { form, formErrors, handleChange, handleChangeDate, resetForm, validateForm } = useForm({
    initForm,
    initErrorForm,
  });
  const fs = useMediaQuery("(max-width: 600px)");

  const handleDeleteEntry = (name, value) => () => {
    const clonedForm = _.cloneDeep(form);
    const index = clonedForm.core[name].indexOf(value);
    if (index > -1) {
      clonedForm.core[name].splice(index, 1);
    }

    resetForm(clonedForm);
    if (coreCounter[name] > 1) {
      setCoreCounter({ ...coreCounter, [name]: coreCounter[name] - 1 });
    }
  };

  const handleClose = () => {
    setDialog({ ...dialog, addOrgDialog: false });
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    const { name, fiscalYear, core } = form;
    const ok = await createOrg({
      variables: {
        name,
        fiscalYear,
        core,
      },
    });

    if (ok) {
      snack(`Created "${name}" organization`);
      resetForm();
      requestFetch();
      handleClose();
    }
  };

  return (
    <Dialog
      open={dialog.addOrgDialog}
      onClose={(event, reason) => {
        if (reason !== 'backdropClick') {
          handleClose()
        }
      }} 
      fullScreen={fs}
      disableBackdropClick
      fullWidth
    >
      <DialogTitle>
        <div className={styles.title}>
          Create New Organization
          <div>
            <IconButton onClick={handleClose} size="small">
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          label="Organization Name"
          name="name"
          fullWidth
          margin="normal"
          variant="outlined"
          value={form.name || ""}
          onChange={handleChange}
          helperText={formErrors.name}
          error={Boolean(formErrors.name)}
        />
        <DatePicker
          fullWidth
          autoOk
          variant="inline"
          inputVariant="outlined"
          format="dd/MM/yyyy"
          margin="normal"
          label="Fiscal Year End"
          value={form.fiscalYear || ""}
          onChange={handleChangeDate("fiscalYear")}
        />

        {Array.from(Array(coreCounter.purpose), (_, i) => (
          <TextField
            key={i}
            label={`Core Purpose #${i + 1}`}
            name={`core.purpose[${i}]`}
            fullWidth
            multiline
            margin="normal"
            variant="outlined"
            value={form.core.purpose[i] || ""}
            onChange={handleChange}
            helperText={i === coreCounter.purpose - 1 ? "Why we exist" : null}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleDeleteEntry("purpose", form.core.purpose[i])}>
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ))}
        <IconButton onClick={() => setCoreCounter({ ...coreCounter, purpose: coreCounter.purpose + 1 })}>
          <AddIcon />
        </IconButton>

        {Array.from(Array(coreCounter.value), (_, i) => (
          <TextField
            key={i}
            label={`Core Value #${i + 1}`}
            name={`core.value[${i}]`}
            fullWidth
            multiline
            margin="normal"
            variant="outlined"
            value={form.core.value[i] || ""}
            onChange={handleChange}
            helperText={i === coreCounter.value - 1 ? "What we stand for" : null}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleDeleteEntry("value", form.core.value[i])}>
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ))}
        <IconButton onClick={() => setCoreCounter({ ...coreCounter, value: coreCounter.value + 1 })}>
          <AddIcon />
        </IconButton>

        {Array.from(Array(coreCounter.competency), (_, i) => (
          <TextField
            key={i}
            label={`Core Competency #${i + 1}`}
            name={`core.competency[${i}]`}
            fullWidth
            multiline
            margin="normal"
            variant="outlined"
            value={form.core.competency[i] || ""}
            onChange={handleChange}
            helperText={i === coreCounter.competency - 1 ? "What we do best" : null}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleDeleteEntry("competency", form.core.competency[i])}>
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ))}
        <IconButton
          onClick={() =>
            setCoreCounter({
              ...coreCounter,
              competency: coreCounter.competency + 1,
            })
          }
        >
          <AddIcon />
        </IconButton>

        {Array.from(Array(coreCounter.market), (_, i) => (
          <TextField
            key={i}
            label={`Core Market #${i + 1}`}
            name={`core.market[${i}]`}
            fullWidth
            multiline
            margin="normal"
            variant="outlined"
            value={form.core.market[i] || ""}
            onChange={handleChange}
            helperText={i === coreCounter.market - 1 ? "Who we serve" : null}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleDeleteEntry("market", form.core.market[i])}>
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ))}
        <IconButton onClick={() => setCoreCounter({ ...coreCounter, market: coreCounter.market + 1 })}>
          <AddIcon />
        </IconButton>

        {Array.from(Array(coreCounter.offering), (_, i) => (
          <TextField
            key={i}
            label={`Core Offering #${i + 1}`}
            name={`core.offering[${i}]`}
            fullWidth
            multiline
            margin="normal"
            variant="outlined"
            value={form.core.offering[i] || ""}
            onChange={handleChange}
            helperText={i === coreCounter.offering - 1 ? "What we offer" : null}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleDeleteEntry("offering", form.core.offering[i])}>
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ))}
        <IconButton
          onClick={() =>
            setCoreCounter({
              ...coreCounter,
              offering: coreCounter.offering + 1,
            })
          }
        >
          <AddIcon />
        </IconButton>

        {Array.from(Array(coreCounter.valueProposition), (_, i) => (
          <TextField
            key={i}
            label={`Core Value Proposition #${i + 1}`}
            name={`core.valueProposition[${i}]`}
            fullWidth
            multiline
            margin="normal"
            variant="outlined"
            value={form.core.valueProposition[i] || ""}
            onChange={handleChange}
            helperText={i === coreCounter.valueProposition - 1 ? "What our customers get" : null}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleDeleteEntry("valueProposition", form.core.valueProposition[i])}>
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ))}
        <IconButton
          onClick={() =>
            setCoreCounter({
              ...coreCounter,
              valueProposition: coreCounter.valueProposition + 1,
            })
          }
        >
          <AddIcon />
        </IconButton>

        {Array.from(Array(coreCounter.differentiation), (_, i) => (
          <TextField
            key={i}
            label={`Core Differentiators #${i + 1}`}
            name={`core.differentiation[${i}]`}
            fullWidth
            multiline
            margin="normal"
            variant="outlined"
            value={form.core.differentiation[i] || ""}
            onChange={handleChange}
            helperText={i === coreCounter.differentiation - 1 ? "What makes us unique" : null}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleDeleteEntry("differentiation", form.core.differentiation[i])}>
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ))}
        <IconButton
          onClick={() =>
            setCoreCounter({
              ...coreCounter,
              differentiation: coreCounter.differentiation + 1,
            })
          }
        >
          <AddIcon />
        </IconButton>

        {Array.from(Array(coreCounter.process), (_, i) => (
          <TextField
            key={i}
            label={`Core Process #${i + 1}`}
            name={`core.process[${i}]`}
            fullWidth
            multiline
            margin="normal"
            variant="outlined"
            value={form.core.process[i] || ""}
            onChange={handleChange}
            helperText={i === coreCounter.process - 1 ? "How we serve our customers" : null}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleDeleteEntry("process", form.core.process[i])}>
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        ))}
        <IconButton onClick={() => setCoreCounter({ ...coreCounter, process: coreCounter.process + 1 })}>
          <AddIcon />
        </IconButton>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained" disabled={loading} className={styles.button}>
          {loading ? <Loading size={24} color="#fff" /> : "Create Organization"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddOrgDialog;

const CREATE_ORG = gql`
  mutation($name: String!, $fiscalYear: String!, $core: CoreInput) {
    createOrganization(name: $name, fiscalYear: $fiscalYear, core: $core)
  }
`;
