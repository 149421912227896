import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import imageCompression from "browser-image-compression";
import { v4 } from "uuid";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import styles from "./TheCore.module.scss";
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { FetchContext } from "../../context/fetchContext";
import { SnackbarContext } from "../../context/snackbarContext";
import { UserContext } from "../../context/userContext";
import { DialogContext } from "../../context/dialogContext";
import { isAuthed } from "../../utils/authorization";
import firebase from "../../utils/firebase";
import {
  Container,
  Grid,
  Card,
  List,
  ListItem,
  ListItemText,
  Typography,
  Avatar,
  CardContent,
  Divider,
  Hidden,
  Button,
  MenuItem,
  Dialog,
  DialogContent,
  DialogActions,
  DialogContentText,
  DialogTitle,
  Switch,
  FormControlLabel,
} from "@material-ui/core";
import Menu from "../../components/Menu/Menu";
import Icon from "@mdi/react";
import {
  mdiCalendar,
  mdiCalendarAlert,
  mdiCalendarStar,
  mdiHeart,
  mdiPillar,
  mdiSitemap,
  mdiAccountGroup,
  mdiGift,
  mdiChartAreasplineVariant,
  mdiStar,
  mdiArmFlex,
} from "@mdi/js";
import Loading from "../../components/Loading/Loading";
import NotesButton from "../../components/Notes/NotesButton";
import AvatarEditDialog from "../../components/AvatarEditDialog/AvatarEditDialog";
import Projects from "../../components/Projects/Projects";
import EditPlanDialog from "./EditPlanDialog";
import AddProjectDialog from "./AddProjectDialog";
import AddCoreDialog from "./AddCoreDialog";
import EditDialog from "./EditDialog";

import { fiscalYearStart, fullDate, bhagTimeRemaining, formatAs } from "../../utils/dates";
import PlanPill from "../../components/PlanPill/PlanPill";
import { addDays } from "date-fns";

const spacing = 8;
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: spacing * 2,
  margin: spacing,
  minWidth: 250,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  display: "flex",
  padding: spacing,
  overflow: "auto",
  background: "rgba(0, 0, 0, 0.12)",
  border: "1px solid rgba(0, 0, 0, 0.12)",
});

const TheCore = ({ params }) => {
  const { fetch, requestFetch } = useContext(FetchContext);
  const { snack } = useContext(SnackbarContext);
  const { user } = useContext(UserContext);
  const { dialog: contextDialog, setDialog: setContextDialog } = useContext(DialogContext);
  const [dialog, setDialog] = useState({ org: null, core: null, bhag: null });
  const [initForm, setInitForm] = useState({});
  const [addProject, setAddProject] = useState(false);
  const [addCore, setAddCore] = useState(false);
  const [coreData, setCoreData] = useState({});
  const [showSave, setShowSave] = useState([]);
  const [avatarEditDialog, setAvatarEditDialog] = useState(null);
  const [editPlanDialog, setEditPlanDialog] = useState({
    open: false,
    departmentName: null,
    oneYear: {
      id: null,
      theme: "",
      useDepartmentTheme: false,
    },
    threeYear: {
      id: null,
      theme: "",
      useDepartmentTheme: false,
    },
    color: undefined,
    shortName: undefined,
  });
  const [confirmDelete, setConfirmDelete] = useState({ open: false, callback: null, text: "" });
  const [planOrderingEnabled, setPlanOrderingEnabled] = useState(false);
  const [mergedPlans, setMergedPlans] = useState([]);

  const { loading, data, refetch } = useQuery(GET_ORGANIZATION, {
    variables: { id: params.org, plan: user.departmentFilter.id },
  });
  const { data: closedCorpPlans } = useQuery(GET_CLOSED_CORP_PLANS, {
    variables: { id: params.org },
  });

  const [updateProfilePicture] = useMutation(UPDATE_PROFILE_PIC);
  const [updateCore] = useMutation(UPDATE_CORE);
  const [deletePlan] = useMutation(DELETE_PLAN);
  const [reorderPlans] = useMutation(REORDER_PLANS);

  const handleAddProject = (open) => () => {
    setAddProject(open);
  };

  const handleAddCore = (open) => () => {
    setAddCore(open);
  };

  const handleEditCore = (key, values) => () => {
    setInitForm({ values });
    setDialog({ ...dialog, core: { key, values } });
  };

  const handleEditOrg = () => {
    const { name, fiscalYear, viewPreferences } = data.organization;
    setInitForm({
      name,
      fiscalYear: parseInt(fiscalYear),
      viewPreferences: viewPreferences.map(({ name, position, show }) => ({ name, position, show })),
    });
    setDialog({ ...dialog, org: true });
  };

  const handleEditBhag = () => {
    setInitForm({ goal: bhag.goal, targetDate: parseInt(bhag.targetDate) });
    setDialog({ ...dialog, bhag: true });
  };

  const handleEditPlanDialog = (departmentName, oneYear, threeYear, color, shortName) => () => {
    if (departmentName) {
      setEditPlanDialog({
        open: true,
        departmentName,
        oneYear: _.pick(oneYear, ["id", "useDepartmentTheme", "theme"]),
        threeYear: _.pick(threeYear, ["id", "useDepartmentTheme", "theme"]),
        color,
        shortName,
      });
    } else {
      setEditPlanDialog((prev) => ({ ...prev, open: false }));
    }

    requestFetch();
  };

  const handleOpenPlanDialogOneYear = () => {
    setContextDialog({ ...contextDialog, addPlanDialog: true });
  };

  const handleEditClose = () => {
    setDialog({ org: null, core: null, bhag: null });
  };

  const handleDragEndCore = ({ draggableId, destination }) => {
    if (!destination) return;

    const { droppableId } = destination;
    if (!showSave.includes(droppableId)) setShowSave([...showSave, droppableId]);

    const clonedData = _.cloneDeep(coreData);
    const val = clonedData[droppableId].splice(draggableId, 1)[0];
    clonedData[droppableId].splice(destination.index, 0, val);

    setCoreData(clonedData);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = _.cloneDeep(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const handleDragEndPlan = async ({ draggableId, destination, source }) => {
    if (!destination) return;

    setMergedPlans(reorder(mergedPlans, source.index, destination.index));

    const ok = await reorderPlans({
      variables: {
        sharedPlanId: draggableId,
        destinationIndex: _.get(destination, "index", 0),
      },
    });

    if (ok.data.reorderPlans) {
      snack("Updated department order");
      requestFetch();
    }
  };

  const handleSaveCore = (name) => async () => {
    const values = coreData[name];
    const ok = await updateCore({
      variables: {
        id: params.org,
        name,
        values,
      },
    });

    if (ok.data.updateCore) {
      snack("Updated core details");
      requestFetch();

      const clonedSave = _.cloneDeep(showSave);
      const index = clonedSave.indexOf(name);
      clonedSave.splice(index, 1);
      setShowSave(clonedSave);
    }
  };

  const handleAvatarEditDialog = (open) => () => {
    setAvatarEditDialog(open);
  };

  const handleUploadImage = async (e) => {
    if (!_.isEmpty(e.target.files) && e.target.validity.valid) {
      const file = e.target.files[0];
      setAvatarEditDialog(file);
    }
  };

  const handleSaveImage = (ref) => () => {
    snack("Uploading company picture...");

    if (profilePicture) {
      deleteImage();
    }

    const canvas = ref.current.getImage();
    canvas.toBlob(async (blob) => {
      const filename = v4();
      const storageRef = firebase.storage().ref();
      const fileRef = storageRef.child(`organization-images/${filename}`);

      const compressionOptions = { maxSizeMB: 1 };
      const compressedFile = await imageCompression(blob, compressionOptions);

      fileRef.put(compressedFile).then(async () => {
        const url = await fileRef.getDownloadURL();
        const ok = await updateProfilePicture({ variables: { id: params.org, profilePicture: url } });

        if (ok.data.updateOrganization) {
          snack("Updated company picture");
          requestFetch();
          setAvatarEditDialog(null);
        }
      });
    });
  };

  const handleDeleteImage = async () => {
    snack(`Deleting image...`);
    deleteImage();

    const ok = await updateProfilePicture({ variables: { id: params.org, profilePicture: "" } });

    if (ok.data.updateOrganization) {
      snack("Deleted company picture");
      requestFetch();
    }
  };

  const deleteImage = () => {
    const storageRef = firebase.storage().refFromURL(profilePicture);
    storageRef.delete();
  };

  const handleDeletePlan = (id) => async () => {
    snack("Deleting department...");
    const ok = await deletePlan({ variables: { id } });

    if (ok.data.deletePlan) {
      snack("Department deleted");
      requestFetch();
    }
  };

  const handleConfirmDelete =
    (open, callback = null, text = "") =>
      () => {
        setConfirmDelete({ open, callback, text });
      };

  const clearPreviousImageUpload = (e) => {
    // Need to clear the value of the input because the onChange doesn't fire upon attempting to select the same image file after an image upload cancellation
    e.target.value = null;
  };

  useEffect(() => {
    refetch();
  }, [fetch, user.departmentFilter]);

  useEffect(() => {
    if (data) {
      const orgCore = _.get(data, "organization.core");
      if (!_.isNil(orgCore)) {
        setCoreData(orgCore);
      } else {
        setCoreData({});
      }

      const plansOrder = _.get(data, "organization.plansOrder", []);
      const orgPlans = _.get(data, "plans", []);
      const groupedPlans = _.groupBy(orgPlans, "sharedPlanId");

      let mergedPlansTemp = [];
      for (const spid in groupedPlans) {
        const groupArr = groupedPlans[spid];
        mergedPlansTemp.push({
          number: _.indexOf(plansOrder, spid),
          departmentName: _.get(_.first(groupArr), "departmentName"),
          oneYear: _.find(groupArr, ["category", "1 year"]),
          threeYear: _.find(groupArr, ["category", "3 year"]),
          color: _.last(groupArr).color,
          shortName: _.last(groupArr).shortName,
        });
      }

      mergedPlansTemp = _.sortBy(mergedPlansTemp, ["number"]);
      setMergedPlans(mergedPlansTemp);
    }
  }, [data]);

  const mostRecentClosedCorpPlan = _.orderBy(_.get(closedCorpPlans, "corporatePlans", []), (plan) => parseInt(plan.year), ["desc"])[0];
  const minYear = mostRecentClosedCorpPlan ? addDays(parseInt(_.get(mostRecentClosedCorpPlan, "year")), 1) : undefined;

  if (loading) return <Loading />;

  if (!data.organization) return null; //TODO remove this check
  const { name, profilePicture, fiscalYear, bhag, viewPreferences, notes } = data.organization;
  const { plans, projects } = data;
  const currentYearPlans = _.filter(plans, (plan) => plan.year === fiscalYear && plan.category === '1 year')
  let coreItems = [];
  let showProjects = true;
  const sortedView = _.sortBy(viewPreferences, "position");

  sortedView.forEach(({ name, show }) => {
    if (name === "projects") {
      showProjects = show;
    }

    if (!show || _.isNil(coreData[name])) return;
    const coreNotes = notes[name];
    const values = coreData[name];
    const info = {
      purpose: [mdiHeart, "Why we exist", "deepOrange", "purpose"],
      value: [mdiPillar, "What we stand for", "red", "values"],
      process: [mdiSitemap, "How we serve our customers", "blue", "process"],
      market: [mdiAccountGroup, "Who we serve", "pink", "market"],
      offering: [mdiGift, "What we offer", "orange", "offering"],
      valueProposition: [mdiChartAreasplineVariant, "What our customers get", "green", "value proposition"],
      differentiation: [mdiStar, "What makes us unique", "cyan", "differentiators"],
      competency: [mdiArmFlex, "What we do best", "lightGreen", "competency"],
    };

    coreItems.push(
      <Grid item xs={12} key={name}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={2}>
            <Typography variant="h5" className={styles.label}>
              Core {_.startCase(info[name][3])}
            </Typography>
            <Typography variant="subtitle1" className={styles.label}>
              {info[name][1]}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <Card>
              <CardContent>
                <DragDropContext onDragEnd={handleDragEndCore}>
                  <Droppable droppableId={name}>
                    {(provided, snapshot) => {
                      return (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                          <List>
                            {!_.isEmpty(values) ? (
                              values.map((item, i) => (
                                <Draggable
                                  draggableId={i.toString()}
                                  index={i}
                                  key={i}
                                  isDragDisabled={!isAuthed(user.user, "department facilitator")}
                                >
                                  {(provided, snapshot) => {
                                    return (
                                      <ListItem
                                        key={i}
                                        divider
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        ref={provided.innerRef}
                                      >
                                        <ListItemText>{item}</ListItemText>
                                      </ListItem>
                                    );
                                  }}
                                </Draggable>
                              ))
                            ) : (
                              <>
                                <ListItem variant="body2" className={styles.body} align="center">
                                  <ListItemText>
                                    Nothing to show
                                    <br />
                                    {isAuthed(user.user, "company admin") && (
                                      <Button onClick={handleEditCore(name, values)} color="primary">
                                        Add a Core {_.startCase(name)}
                                      </Button>
                                    )}
                                  </ListItemText>
                                </ListItem>
                              </>
                            )}
                          </List>
                          {provided.placeholder}
                        </div>
                      );
                    }}
                  </Droppable>
                </DragDropContext>
                {showSave.includes(name) && (
                  <div className={styles.editButton}>
                    <Button color="primary" variant="contained" onClick={handleSaveCore(name)}>
                      Save
                    </Button>
                  </div>
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={1}>
            <div className={styles.flexEnd}>
              <NotesButton id={params.org} core={name} model="organization" size={1.25} doc={{ notes: coreNotes }} />
              {isAuthed(user.user, "company admin") && (
                <Button variant="outlined" onClick={handleEditCore(name, values)}>
                  Edit
                </Button>
              )}
            </div>
          </Grid>
          <Divider className={styles.divider} />
        </Grid>
      </Grid>
    );
  });

  return (
    <>
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Hidden xsDown>
            <Grid item xs={12}>
              <Typography variant="h6" className={styles.label}>
                The Core
              </Typography>
            </Grid>
          </Hidden>

          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={2}>
                <Typography variant="h5" className={styles.label}>
                  Organization
                </Typography>
              </Grid>
              <Grid item xs={12} sm={10}>
                <div className={styles.avatarWrapper}>
                  <Avatar variant="square" className={styles.avatar} src={profilePicture}>
                    {name[0]}
                  </Avatar>
                  {isAuthed(user.user, "company admin") && (
                    <Menu className={styles.editAvatar} button="Edit">
                      <input
                        name="file"
                        onChange={handleUploadImage}
                        onClick={clearPreviousImageUpload}
                        accept="image/*"
                        id="file-upload"
                        type="file"
                        className={styles.input}
                      />
                      <label htmlFor="file-upload">
                        <MenuItem component="span">Upload New</MenuItem>
                      </label>
                      {profilePicture && (
                        <MenuItem className={styles.delete} onClick={handleDeleteImage}>
                          Delete
                        </MenuItem>
                      )}
                    </Menu>
                  )}
                </div>
                <Typography variant="h6" className={styles.label}>
                  Company Name
                </Typography>
                <div className={styles.flexParagraph}>
                  <Typography variant="h5">{name}</Typography>
                  {isAuthed(user.user, "company admin") && (
                    <Button onClick={handleEditOrg} variant="outlined" className={styles.editButton}>
                      Edit
                    </Button>
                  )}
                </div>

                <Typography variant="h6" className={styles.label}>
                  Current Fiscal Year
                </Typography>
                <div className={styles.flexParagraph}>
                  <Icon path={mdiCalendar} size={1.5} color="rgba(0, 0, 0, 0.65)" className={styles.icon} />
                  <Typography variant="h5">
                    {fullDate(fiscalYearStart(fiscalYear))} - {fullDate(fiscalYear)}
                  </Typography>
                </div>
                <Divider className={styles.divider} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={2}>
                <Typography variant="h5" className={styles.label}>
                  BHAG<sup>&#174;</sup>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={10}>
                <Typography variant="h6" className={styles.label}>
                  Big Hairy Audacious Goal
                </Typography>
                <div className={styles.flexParagraph}>
                  <Typography variant="h5" gutterBottom>
                    {bhag.goal}
                  </Typography>
                  {bhag.goal ? (
                    <>
                      <NotesButton id={params.org} core="bhag" model="organization" size={1.25} doc={{ notes: notes["bhag"] }} />
                      {isAuthed(user.user, "company admin") && (
                        <Button onClick={handleEditBhag} variant="outlined" className={styles.editButton}>
                          Edit
                        </Button>
                      )}
                    </>
                  ) : (
                    <Button onClick={handleEditBhag} color="primary" disabled={!isAuthed(user.user, "company admin")}>
                      Create BHAG <sup>&#174;</sup>
                    </Button>
                  )}
                </div>

                <Typography variant="h6" className={styles.label}>
                  Target Date
                </Typography>
                <div className={styles.flexParagraph}>
                  <Icon path={mdiCalendarStar} size={1.5} color="rgba(0, 0, 0, 0.65)" className={styles.icon} />
                  <Typography variant="h5">{formatAs(bhag.targetDate, "MMM d, y")}</Typography>
                </div>

                <Typography variant="h6" className={styles.label}>
                  Time Remaining
                </Typography>
                <div className={styles.flexParagraph}>
                  <Icon path={mdiCalendarAlert} size={1.5} color="rgba(0, 0, 0, 0.65)" className={styles.icon} />
                  <Typography variant="h5">{bhagTimeRemaining(bhag.targetDate)}</Typography>
                </div>
                <Divider className={styles.divider} />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={2}>
                <Typography variant="h5" className={styles.label}>
                  Departments
                </Typography>
              </Grid>

              <Grid item xs={12} sm={9}>
                {planOrderingEnabled ? (
                  <DragDropContext onDragEnd={handleDragEndPlan}>
                    <Droppable droppableId="droppablePlan" direction="horizontal">
                      {(provided, snapshot) => (
                        <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)} {...provided.droppableProps}>
                          {mergedPlans &&
                            mergedPlans.map(({ departmentName, oneYear: { sharedPlanId }, color, shortName }, index) => (
                              <Draggable key={sharedPlanId} draggableId={sharedPlanId} index={index}>
                                {(provided, snapshot) => (
                                  <Card
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                  >
                                    <CardContent className={styles.flexBetween}>
                                      <Typography variant="h5">{departmentName}</Typography>
                                      <PlanPill plan={{ departmentName, color, shortName }} />
                                    </CardContent>
                                  </Card>
                                )}
                              </Draggable>
                            ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                ) : (
                  <Grid container spacing={3}>
                    {mergedPlans &&
                      mergedPlans.map(({ departmentName, oneYear, threeYear, color, shortName }, idx) => {
                        return (
                          <Grid item sm={6} md={4} key={idx}>
                            <Card>
                              <CardContent className={styles.flexBetween}>
                                <Typography variant="h5">{departmentName}</Typography>
                                <div className={styles.flexBetween}>
                                  <PlanPill plan={{ departmentName, color, shortName }} />
                                  {isAuthed(user.user, "department facilitator") && (
                                    <Menu>
                                      <MenuItem onClick={handleEditPlanDialog(departmentName, oneYear, threeYear, color, shortName)}>
                                        Edit
                                      </MenuItem>
                                      {departmentName !== "Corporate" && (
                                        <MenuItem
                                          className={styles.delete}
                                          onClick={handleConfirmDelete(
                                            true,
                                            handleDeletePlan(oneYear.id),
                                            `Are you sure you want to delete ${departmentName}? Deleting this Department will reassign all users, rocks, and objectives associated with it to the Corporate department.`
                                          )}
                                        >
                                          Delete
                                        </MenuItem>
                                      )}
                                    </Menu>
                                  )}
                                </div>
                              </CardContent>
                            </Card>
                          </Grid>
                        );
                      })}
                  </Grid>
                )}
              </Grid>

              <Grid item xs={12} sm={1}>
                <div className={styles.flexEnd} style={{ flexDirection: "column", gap: 8 }}>
                  <Button variant="outlined" onClick={handleOpenPlanDialogOneYear} disabled={!isAuthed(user.user, "company admin")}>
                    New Department
                  </Button>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={planOrderingEnabled}
                        onChange={(e) => {
                          setPlanOrderingEnabled(e.target.checked);
                        }}
                      />
                    }
                    label="ORDER MODE"
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>

          {showProjects && (
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={2}>
                  <Typography variant="h5" className={styles.label}>
                    Projects
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={9}>
                  <Projects projects={projects} handleConfirmDelete={handleConfirmDelete} />
                </Grid>
                <Grid item xs={12} sm={1}>
                  <div className={styles.flexEnd}>
                    <Button variant="outlined" onClick={handleAddProject(true)} disabled={!isAuthed(user.user, "company admin")}>
                      New Project
                    </Button>
                  </div>
                </Grid>
              </Grid>
            </Grid>
          )}

          {coreItems}
          <Grid xs={12} item>
            <Typography align="center">
              <Button variant="contained" color="primary" onClick={handleAddCore(true)} disabled={!isAuthed(user.user, "company admin")}>
                New Department Core
              </Button>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Dialog
        open={confirmDelete.open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleConfirmDelete(false)();
          }
        }}
      >
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText>{confirmDelete.text}</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleConfirmDelete(false)}>Dismiss</Button>
          <Button
            onClick={() => {
              confirmDelete.callback();
              handleConfirmDelete(false)();
            }}
            className={styles.delete}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <AvatarEditDialog
        open={Boolean(avatarEditDialog)}
        handleClose={handleAvatarEditDialog(false)}
        handleSave={handleSaveImage}
        src={avatarEditDialog}
      />
      <AddProjectDialog id={params.org} open={addProject} handleClose={handleAddProject(false)} plans={currentYearPlans} />
      <AddCoreDialog id={params.org} open={addCore} handleClose={handleAddCore(false)} plans={currentYearPlans} />

      <EditPlanDialog open={editPlanDialog.open} handleClose={handleEditPlanDialog()} initForm={editPlanDialog} />
      <EditDialog
        id={params.org}
        open={dialog}
        handleClose={handleEditClose}
        initForm={initForm}
        name={_.get(dialog, "core.key", null)}
        plan={user.departmentFilter.id}
        minYear={minYear}
      />
    </>
  );
};

export default TheCore;

const GET_ORGANIZATION = gql`
  query TheCore_GetOrg($id: ID!, $plan: ID) {
    organization(id: $id, plan: $plan) {
      id
      name
      fiscalYear
      profilePicture
      plansOrder
      core {
        purpose
        value
        competency
        market
        offering
        valueProposition
        differentiation
        process
      }
      createdAt
      bhag {
        goal
        targetDate
      }
      viewPreferences {
        name
        position
        show
      }
      notes {
        bhag {
          id
          date
        }
        purpose {
          id
          date
        }
        value {
          id
          date
        }
        competency {
          id
          date
        }
        market {
          id
          date
        }
        offering {
          id
          date
        }
        valueProposition {
          id
          date
        }
        process {
          id
          date
        }
        differentiation {
          id
          date
        }
      }
    }

    plans(organization: $id, closed: false) {
      id
      departmentName
      theme
      year
      useDepartmentTheme
      sharedPlanId
      category
      color
      shortName
      targetDate
    }

    projects(organization: $id) {
      id
      value
      plan {
        id
        departmentName
      }
      notes {
        id
        date
      }
    }
  }
`;

const UPDATE_CORE = gql`
  mutation TheCore_UpdateCore($id: ID!, $values: [String], $name: String) {
    updateCore(id: $id, values: $values, name: $name)
  }
`;

const UPDATE_PROFILE_PIC = gql`
  mutation TheCore_UpdateProfilePic($id: ID!, $profilePicture: String!) {
    updateOrganization(id: $id, profilePicture: $profilePicture)
  }
`;

const DELETE_PLAN = gql`
  mutation TheCore_DeletePlan($id: ID!) {
    deletePlan(id: $id)
  }
`;

const REORDER_PLANS = gql`
  mutation TheCore_ReorderPlans($sharedPlanId: ID!, $destinationIndex: Int!) {
    reorderPlans(sharedPlanId: $sharedPlanId, destinationIndex: $destinationIndex)
  }
`;

const GET_CLOSED_CORP_PLANS = gql`
  query GetCorpPlans($id: ID!) {
    corporatePlans: plans(organization: $id, departmentName: "Corporate", category: "1 year", closed: true) {
      id
      year
      closed
    }
  }
`;
