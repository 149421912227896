import React, { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import styles from "./VariableDialog.module.scss";
import _ from "lodash";
import useForm from "../../hooks/useForm";

const initErrorForm = {
  varType: ["required"],
};

const VariableDialog = ({
  open,
  handleClose,
  handleChangeVariable,
  formulaScope,
  list = [],
  createMode,
  listName = "Reference",
  currentId,
}) => {
  const { form, formErrors, handleChange, resetForm, validateForm, valueAsFormattedNum } = useForm({
    initForm: formulaScope,
    initErrorForm,
  });

  // remove weekly target that uses formula (since those ones do not have weekly values)
  const filteredList = list.filter((item) => _.get(item, "enableFormula") !== true && currentId !== item.id);

  const handleSubmit = () => {
    if (!validateForm()) return;
    handleChangeVariable(form);
    handleClose();
  };

  useEffect(() => {
    resetForm(formulaScope);
  }, [formulaScope]);

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth onExited={() => resetForm({ varType: "number", value: null })}>
      <DialogTitle>{createMode ? "Create" : "Edit"} Variable</DialogTitle>
      <div className={styles.toggleButtons}>
        <ToggleButtonGroup
          exclusive
          value={form.varType}
          onChange={(e, value) => {
            resetForm({ varType: value, value: null });
          }}
        >
          <ToggleButton value="reference">{listName}</ToggleButton>
          <ToggleButton value="number">Number</ToggleButton>
        </ToggleButtonGroup>
      </div>
      <DialogContent>
        {form.varType === "number" && (
          <TextField
            label="value"
            name="value"
            margin="dense"
            variant="outlined"
            value={valueAsFormattedNum(form.value)}
            onChange={handleChange}
            error={Boolean(formErrors.value)}
            fullWidth
          />
        )}
        {form.varType === "reference" && (
          <FormControl variant="outlined" margin="dense" fullWidth>
            <InputLabel>value</InputLabel>
            <Select name="value" value={form.value} onChange={handleChange} label="value">
              {!_.isEmpty(filteredList) &&
                filteredList.map(({ id, value }) => (
                  <MenuItem key={id} value={id}>
                    {value}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary" variant="contained" className={styles.button}>
          {createMode ? "Create" : "Update"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VariableDialog;
