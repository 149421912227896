import React, { useContext } from "react";
import handleViewport from "react-in-viewport";
import styles from "./Metrics.module.scss";
import _ from "lodash";
import { mdiAlert } from "@mdi/js";
import { isAuthed } from "../../utils/authorization";
import useInViewport from "../../hooks/useInViewport";
import { parseMetricValueUsingFormula, getQuarterMetricTotal } from "../../utils/misc";

import { UserContext } from "../../context/userContext";

import { TableRow, TableCell, MenuItem, Typography, Tooltip } from "@material-ui/core";
import { red } from "@material-ui/core/colors";
import Icon from "@mdi/react";

import UserAvatars from "../UserAvatars/UserAvatars";
import Menu from "../Menu/Menu";
import Status from "../Status/Status";
import NotesButton from "../Notes/NotesButton";
import Skeleton from "./Skeleton";
import PlanPill from "../PlanPill/PlanPill";

const Metric = ({
  innerRef,
  provided,
  inViewport,
  forwardedRef,
  currentQuarter,
  category,
  handleAddIssueDialog,
  handleOpenAddMetricDialog,
  canEdit,
  metric,
  afterDnd,
  count,
  referenceMap,
  metrics,
  yearOne,
  plansOrder,
  corpForSelectedYear,
  handleEditDialog,
  handleUpdateStatus,
  handleConfirmOpen,
}) => {
  const {
    id,
    value,
    comparator,
    measurables,
    unit,
    users,
    status,
    departmentName,
    plan,
    cumulative,
    calculateTotal,
    enableFormula,
    formula,
    formulaScope,
    number,
    objectives,
  } = metric;
  const { user } = useContext(UserContext);

  const hasRendered = useInViewport(inViewport);

  const handleComparator = (comparatorStr, leftOperand, rightOperand) => {
    const comparisonOperatorsHash = {
      lt: function (a, b) {
        return a < b;
      },
      gt: function (a, b) {
        return a > b;
      },
      gte: function (a, b) {
        return a >= b;
      },
      lte: function (a, b) {
        return a <= b;
      },
      eq: function (a, b) {
        return a == b;
      },
    };

    const comparisonOperator = comparisonOperatorsHash[comparatorStr];
    return comparisonOperator(leftOperand, rightOperand);
  };

  const getUnit = (value) => {
    if (!value) return "—";
    if (!unit) return _.round(Number(value), 2).toLocaleString();
    if (unit === "$") return `${unit}${_.round(value, 2).toLocaleString()}`;
    return `${_.round(Number(value), 2).toLocaleString()}${unit}`;
  };

  const reviewedByPeriodNumber = _.get(plan, "periodData", []).reduce((a, b) => {
    a[b.periodNumber] = b.reviewed;
    return a;
  }, {});

  if (!hasRendered && !afterDnd)
    return (
      <TableRow
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={(el) => {
          forwardedRef.current = el;
          innerRef(el);
        }}
        className={styles.skeletonRow}
      >
        <Skeleton long />
      </TableRow>
    );

  // let ytd = 0; //[Acutal, YTD Cumulative, Projected]
  // if (category === "1 year" && measurables) {
  //   measurables.forEach(([year, projected, actual], idx) => {
  //     const index = parseInt(year.value[1]);

  //     if (cumulative && !enableFormula) {
  //       if (index === currentQuarter) {
  //         ytd = _.toNumber(projected.value);
  //       }
  //     } else if (index <= currentQuarter) {
  //       if (!enableFormula) {
  //         ytd += _.toNumber(projected.value);
  //       } else {
  //         ytd += parseValueUsingFormula({ formula, formulaScope, index: idx, valueIndex: 1, referenceMap });
  //       }
  //     }
  //   });
  // }

  return (
    <TableRow
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      ref={(el) => {
        forwardedRef.current = el;
        innerRef(el);
      }}
      style={{ ...provided.draggableProps.style }}
      className={styles.tableRow}
    >
      <TableCell className={styles.firstCell}>
        <div className={styles.flex}>
          <div className={styles.flex}>
            {!_.isNil(plan?.departmentName) ? <PlanPill plan={plan} /> : <PlanPill plan={null} />}
            {number}. {value}
          </div>
          <NotesButton
            id={id}
            model="metric"
            value={value.trim()}
            user={users[0] ? users[0].id : null}
            doc={metric}
            tabs={["notes", "issues", "todos"]}
            canEditTodo={canEdit}
            planId={_.get(corpForSelectedYear, "id")}
          />
          {canEdit && (
            <Menu>
              {/* <MenuItem onClick={handleAddIssueDialog(id, "Metric", value.trim(), users[0] ? users[0].id : null)}>Add Issue</MenuItem> */}
              <MenuItem onClick={handleEditDialog(true, metric)}>Edit</MenuItem>
              <MenuItem onClick={handleConfirmOpen(true, metric)} className={styles.delete}>
                Delete
              </MenuItem>
            </Menu>
          )}
        </div>
      </TableCell>
      {measurables &&
        measurables.map(([year, projected, actual], idx) => {
          let isPast = false;
          let isCurrent = false;
          let isClosed = false;
          if (category === "1 year") {
            const index = parseInt(year.value[1]);
            isClosed = _.get(reviewedByPeriodNumber, index, false);
            if (index < currentQuarter) {
              isPast = true;
            } else if (index === currentQuarter) {
              isCurrent = true;
            }
          }

          const overdue = (isPast || isClosed) && !Boolean(actual.value) && !enableFormula;
          const isTotal = year === "Total";

          const reference = _.get(actual, "reference");
          const linkedActual = !_.isNil(reference)
            ? getQuarterMetricTotal(_.get(reference, "measurables", []), _.get(reference, "calculateTotal", false))
            : undefined;

          let targetMet;
          if (!enableFormula) {
            const actualVal = _.isNil(linkedActual) ? actual.value : linkedActual;
            if (!_.isEmpty(actualVal) && !_.isEmpty(projected.value)) {
              if (handleComparator(comparator, parseFloat(actualVal), parseFloat(projected.value))) {
                targetMet = true;
              } else {
                targetMet = false;
              }
            }
          }

          let formulaProjected;
          let formulaActual;
          if (enableFormula) {
            formulaProjected = parseMetricValueUsingFormula({ formula, formulaScope, index: idx, valueIndex: 1, referenceMap });
            formulaActual = parseMetricValueUsingFormula({ formula, formulaScope, index: idx, valueIndex: 2, referenceMap });
            if (!isNaN(formulaActual) && !isNaN(formulaProjected)) {
              if (handleComparator(comparator, formulaActual, formulaProjected)) {
                targetMet = true;
              } else {
                targetMet = false;
              }
            }
          }

          const notesForColumn = year.notes || [];
          const notesPath = `measurables.${idx}.0`; // store it under name of the column
          const additionalProps = {
            path: notesPath,
          };

          return (
            <TableCell key={idx} align="center" className={isCurrent ? styles.current : isPast ? styles.past : undefined}>
              <div className={styles.flexCenter}>
                {overdue && (
                  <Tooltip title={`Information needs to be entered.${isClosed ? " Current Quarter is closed" : ""}`}>
                    <Icon path={mdiAlert} color={red[400]} size={1} className={styles.overdue} />
                  </Tooltip>
                )}
                {!enableFormula && (
                  <Typography variant="inherit" noWrap>
                    <span>{getUnit(projected.value)} |</span>{" "}
                    <span className={targetMet === true ? styles.green : targetMet === false ? styles.red : undefined}>
                      {_.isNil(linkedActual) ? getUnit(actual.value) : getUnit(linkedActual)}
                    </span>
                  </Typography>
                )}
                {enableFormula && (
                  <Typography variant="inherit" noWrap>
                    <span>{getUnit(formulaProjected)} |</span>{" "}
                    <span className={targetMet === true ? styles.green : targetMet === false ? styles.red : undefined}>
                      {getUnit(formulaActual)}
                    </span>
                  </Typography>
                )}
                <NotesButton
                  id={id}
                  model="metric"
                  value={value}
                  user={user.id}
                  doc={{ notes: notesForColumn }}
                  tabs={["notes"]}
                  additionalProps={additionalProps}
                />
              </div>
            </TableCell>
          );
        })}

      {/* {category === "my" && <TableCell colSpan="2" />}  //unsure what this was for? */}
      <TableCell>
        <div className={styles.flexCenter}>
          <Status status={status} />
          {canEdit && (
            <Menu icon="arrow">
              <MenuItem onClick={handleUpdateStatus("on track", metric)} disabled={status === "on track"}>
                Mark as on track
              </MenuItem>
              <MenuItem onClick={handleUpdateStatus("off track", metric)} disabled={status === "off track"}>
                Mark as off track
              </MenuItem>
            </Menu>
          )}
        </div>
      </TableCell>
      <TableCell>
        <UserAvatars users={users} />
      </TableCell>
    </TableRow>
  );
};

export default handleViewport(Metric);
