import { gql } from "@apollo/client";

// for one year and three year page
export const USER_FIELDS = gql`
  fragment UserFields on User {
    id
    profilePicture
    name {
      first
      last
    }
  }
`;

export const SUCCESS_CRITERIA_FIELDS = gql`
  fragment SuccessCriteriaFields on SuccessCriteria {
    id
    value
    done
    targetDate
  }
`;

export const ROCK_FIELDS = gql`
  ${SUCCESS_CRITERIA_FIELDS}
  ${USER_FIELDS}
  fragment RockFields on Rock {
    id
    value
    index
    status
    hide
    number
    successCriterias {
      ...SuccessCriteriaFields
    }
    users {
      ...UserFields
    }
    plan {
      id
      departmentName
      color
      shortName
      sharedPlanId
      plan {
        id
        year
      }
    }
    notes {
      id
      date
    }
    todos {
      id: _id
      createdAt
    }
  }
`;

export const OBJECTIVE_FIELDS = gql`
  ${USER_FIELDS}
  ${ROCK_FIELDS}
  fragment ObjectiveFields on Objective {
    id
    value
    description
    number
    status
    category
    users {
      ...UserFields
    }
    rocks {
      ...RockFields
    }
    objectives
    metrics
    todos {
      id: _id
      createdAt
    }
    notes {
      id
      date
    }
    plan {
      id
      color
      shortName
      departmentName
      sharedPlanId
      periodData {
        periodNumber
        reviewed
        periodGrade {
          topHit
          topMiss
          grade
        }
      }
    }
  }
`;

export const METRIC_FIELDS = gql`
  ${USER_FIELDS}
  fragment MetricFields on Metric {
    id
    value
    number
    comparator
    category
    measurables {
      value
      notes {
        id
      }
      reference {
        id
        calculateTotal
        measurables {
          value
        }
      }
    }
    unit
    cumulative
    calculateTotal
    enableFormula
    formula
    formulaScope {
      varType
      value
    }
    users {
      ...UserFields
    }
    status
    todos {
      id: _id
      createdAt
    }
    objectives
    notes {
      id
      date
    }
    plan {
      id
      color
      shortName
      departmentName
      sharedPlanId
      plan {
        id
      }
      periodData {
        periodNumber
        reviewed
        periodGrade {
          topHit
          topMiss
          grade
        }
      }
    }
  }
`;

export const WEEKLY_TARGET_FIELDS = gql`
  ${USER_FIELDS}
  fragment WeeklyTargetFields on WeeklyTarget {
    id: _id
    value
    target
    targetMin
    targetMax
    enableFormula
    formula
    formulaScope {
      varType
      value
    }
    unit
    accuracy
    number
    measurables {
      week
      value
      notes {
        id
      }
    }
    plans {
      id
      departmentName
      closed
      shortName
      color
      sharedPlanId
      year
      plan {
        id
        year
      }
    }
    user {
      ...UserFields
    }
    notes {
      id
      date
    }
    todos {
      id: _id
      createdAt
    }
  }
`;

export const TODO_FIELDS = gql`
  ${USER_FIELDS}
  fragment TodoFields on Todo {
    id: _id
    value
    done
    priority
    referenceId
    referenceModel
    createdAt
    plan {
      id
      departmentName
      shortName
      color
      year
      plan {
        id
        year
      }
    }
    user {
      ...UserFields
    }
    notes {
      id
      date
    }
    todos {
      id: _id
      createdAt
    }
  }
`;

export const CHART_FIELDS = gql`
  fragment ChartFields on Chart {
    id: _id
    isCompanyChart
    isFavorite
    createdBy
    updatedBy
    createdAt
    updatedAt
    name
    status
    organization
    plan {
      id
      departmentName
      color
      shortName
    }
    cards {
      cardId
      parentNode
      childNodes
      assistantNodes
      user {
        id
        name {
          first
          last
        }
      }
      name
      title
      roles
      responsibilities
    }
  }
`;
