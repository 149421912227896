import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { ApolloClient, createHttpLink, split, InMemoryCache, ApolloProvider, from } from "@apollo/client";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import "typeface-montserrat";

import App from "./App";
import { UserContextProvider } from "./context/userContext";
import { DialogContextProvider } from "./context/dialogContext";
import { FetchContextProvider } from "./context/fetchContext";
import { NotesContextProvider } from "./context/notesContext";
import { LoadingContextProvider } from "./context/loadingContext";

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_SERVER_URI,
  credentials: "same-origin",
});

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_SUBSCRIPTION_URI,
  options: {
    reconnect: true,
    connectionParams: { authToken: localStorage.getItem("token") },
  },
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message, locations, path }) => console.log(`[GraphQL error]: Message: ${message}`, { locations, path }));

  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === "OperationDefinition" && definition.operation === "subscription";
  },
  from([errorLink, wsLink]),
  from([authLink, errorLink, httpLink])
);

// Connect to the Apollo Server back end
const client = new ApolloClient({
  connectToDevTools: true, //uncomment when you want to work with apollo dev tools
  link: splitLink,
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          moreTodos: {
            keyArgs: ["organization", "sharedPlanId", "category", "searchTerm"],
            merge(existing = { todos: [] }, incoming) {
              return {
                todos: [...existing.todos, ...incoming.todos],
                nextCursor: incoming.nextCursor,
              };
            },
          },
        },
      },
    },
  }),
});

render(
  <Router>
    <ApolloProvider client={client}>
      <UserContextProvider>
        <DialogContextProvider>
          <FetchContextProvider>
            <LoadingContextProvider>
              <NotesContextProvider>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <App />
                </MuiPickersUtilsProvider>
              </NotesContextProvider>
            </LoadingContextProvider>
          </FetchContextProvider>
        </DialogContextProvider>
      </UserContextProvider>
    </ApolloProvider>
  </Router>,
  document.getElementById("root")
);
