import React from "react";
import styles from "./ProgressBar.module.scss";
import _ from "lodash";
import { Tooltip, Typography } from "@material-ui/core";

const ProgressBarRocks = ({ rocks, tooltipTitle, alt }) => {
  let percentage = 0.0,
    done = 0,
    total = 0;

  if (rocks) {
    done = rocks.length;
    total = rocks.length;
    rocks.forEach(({ status, successCriterias }) => {
      let isDone = status === "complete";

      if (status !== "complete" && !_.isEmpty(successCriterias)) {
        isDone = _.every(successCriterias, (s) => s.done);
      }

      if (!isDone) {
        done -= 1;
      }
    });

    percentage = (done / total) * 100;
  }

  return (
    <Tooltip title={tooltipTitle || ""} placement="top">
      <div className={alt ? styles.containerRocksAlt : styles.containerRocks}>
        <Typography variant="body2" className={styles.text}>
          {done} / {total}
        </Typography>
        <div className={alt ? styles.barRocksAlt : styles.barRocks} style={{ width: `${percentage || 0}%` }} />
      </div>
    </Tooltip>
  );
};

export default ProgressBarRocks;
