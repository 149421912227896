import React, { useState, useContext, useEffect, useRef } from "react";
import handleViewport from "react-in-viewport";
import { isSameWeek, isBefore, format, add, parse, startOfDay } from "date-fns";
import { evaluate } from "mathjs";
import styles from "./WeeklyTargets.module.scss";
import _ from "lodash";
import { TableRow, TableCell, MenuItem, TextField, Typography, IconButton } from "@material-ui/core";
import Icon from "@mdi/react";
import {
  mdiCodeGreaterThanOrEqual,
  mdiCodeLessThan,
  mdiEqualBox,
  mdiDockWindow,
  mdiCodeLessThanOrEqual,
  mdiCodeGreaterThan,
  mdiCodeBrackets,
} from "@mdi/js";
import useInViewport from "../../hooks/useInViewport";
import useForm from "../../hooks/useForm";
import UserAvatars from "../UserAvatars/UserAvatars";
import NotesButton from "../Notes/NotesButton";
import Menu from "../Menu/Menu";
import Skeleton from "./Skeleton";
import PlanPill from "../PlanPill/PlanPill";
import NumberFormatCustom from "./NumberFormat";
import { parseWTValueUsingFormula } from "../../utils/misc";
import { useInViewport as useInView } from "react-in-viewport";

const WeeklyTarget = ({
  inViewport,
  forwardedRef,
  weeks,
  today,
  handleAddTodoOrIssue,
  canEdit,
  handleEnlarge,
  handleAddToSavePending,
  saving,
  weeklyTarget,
  referenceMap,
  handleEditDialog,
  planId,
  updateWeeklyTarget,
  handleConfirmOpen,
}) => {
  const {
    id,
    value,
    enableFormula,
    formula,
    formulaScope,
    number,
    accuracy,
    target,
    targetMin,
    targetMax,
    unit,
    measurables,
    user,
    plans,
  } = weeklyTarget;

  const { removeFormattedNum, valueAsFormattedNum } = useForm({});
  const [values, setValues] = useState(
    measurables.reduce((obj, val, idx, array) => {
      const formattedWeek = format(add(new Date(val.week), { hours: 12 }), "yyyyMMdd");
      const formattedNotes = (val.notes || []).map((note) => _.pick(note, ["id"]));
      obj[formattedWeek] = { value: val.value, notes: formattedNotes, index: idx };
      return obj;
    }, {})
  );

  const hasRendered = useInViewport(inViewport);

  const handleChange = (e) => {
    // const regex = /^[+-]?([0-9]*([.][0-9]*)?|[.][0-9]+)$/;
    const { name, value } = e.target;
    // const val = removeFormattedNum(value);

    // if (val && !regex.test(val)) return;
    const newValues = { ...values, [name]: { ...values[name], value } };
    setValues(newValues);

    const measureableInput = Object.keys(newValues).map((key) => {
      return { week: parse(key, "yyyyMMdd", startOfDay(new Date())), value: _.toNumber(newValues[key].value), notes: newValues[key].notes };
    });

    handleAddToSavePending(id, () => {
      return updateWeeklyTarget({ variables: { id, measurables: measureableInput } });
    });
  };

  // const handleUpdate = async () => {
  //   const measureableInput = Object.keys(values).map((key) => {
  //     return { week: parse(key, "yyyyMMdd", startOfDay(new Date())), value: _.toNumber(values[key].value), notes: values[key].notes };
  //   });

  //   handleAddToSavePending(id, () => {
  //     return updateWeeklyTarget({ variables: { id, measurables: measureableInput } });
  //   });
  // };

  const getUnit = () => {
    if (_.isNil(target) && _.isNil(targetMax) && _.isNil(targetMin)) return null;
    if (unit === "$") {
      if (accuracy !== "btw") {
        return `${unit}${Number(target).toLocaleString()}`;
      } else {
        return `${unit}${Number(targetMin).toLocaleString()} - ${Number(targetMax).toLocaleString()}`;
      }
    }
    if (accuracy !== "btw") {
      return `${Number(target).toLocaleString()}${unit ? ` ${unit}` : ""}`;
    } else {
      return `${Number(targetMin).toLocaleString()} - ${Number(targetMax).toLocaleString()}${unit ? ` ${unit}` : ""}`;
    }
  };

  // Update values when drag & dropped
  useEffect(() => {
    setValues(
      measurables.reduce((obj, val, idx) => {
        const formattedNotes = (val.notes || []).map((note) => note.id);
        obj[format(add(new Date(val.week), { hours: 12 }), "yyyyMMdd")] = { value: val.value, notes: formattedNotes, index: idx };
        return obj;
      }, {})
    );
  }, [measurables]);

  if (!hasRendered) {
    return (
      <TableRow ref={forwardedRef} className={styles.skeletonRow}>
        <Skeleton />
      </TableRow>
    );
  }

  const openPlans = plans.filter((plan) => !plan.closed);
  const uniqOpenPlans = _.uniqBy(openPlans, (plan) => plan.sharedPlanId);

  let totalWeekValue = 0;
  let countedWeeks = 0;

  return (
    <TableRow ref={forwardedRef} className={styles.row}>
      <TableCell className={styles.fixedFirstCell}>
        <div className={styles.flex}>
          {handleEnlarge && (
            <IconButton onClick={handleEnlarge(id)} size="small">
              <Icon path={mdiDockWindow} size={0.75} color="rgba(0, 0, 0, 0.54)" />
            </IconButton>
          )}
          <span className={styles.flex}>
            {uniqOpenPlans.map((plan, idx) => {
              return <PlanPill plan={plan} key={idx} />;
            })}
            {_.isEmpty(uniqOpenPlans) && <PlanPill plan={null} />}
            {value} {unit && ` (${unit})`}
          </span>
          <NotesButton
            id={id}
            model="weeklyTarget"
            value={value}
            user={user.id}
            doc={weeklyTarget}
            tabs={["notes", "issues", "todos"]}
            canEditTodo={canEdit}
            planId={planId}
          />
          {canEdit && (
            <Menu>
              {/* <MenuItem onClick={handleAddTodoOrIssue("todo", "WeeklyTarget", id)}>Add Todo</MenuItem>
                <MenuItem onClick={handleAddTodoOrIssue("issue", "WeeklyTarget", id, value, user.id)}>Add Issue</MenuItem> */}
              <MenuItem onClick={handleEditDialog(true, weeklyTarget)}>Edit</MenuItem>
              <MenuItem onClick={handleConfirmOpen(true, weeklyTarget)} className={styles.delete}>
                Delete
              </MenuItem>
            </Menu>
          )}
        </div>
      </TableCell>
      <TableCell className={styles.fixed}>
        <div className={styles.flex}>
          <Icon path={accuracyIcon[accuracy]} size={0.75} color="rgba(0, 0, 0, 0.54)" className={styles.iconAccuracy} />
          <Typography noWrap variant="inherit">
            {getUnit()}
          </Typography>
        </div>
      </TableCell>

      <TableCell className={styles.fixed}>
        <UserAvatars users={[user]} />
      </TableCell>

      {weeks.map((week, i, array) => {
        const formattedWeek = format(add(new Date(week), { hours: 12 }), "yyyyMMdd");
        const isPast = isBefore(week, today);
        const isCurrent = isSameWeek(week, today);
        if (!isCurrent && !isPast) return null;
        const valueForWeek = enableFormula
          ? parseWTValueUsingFormula({ formula, formulaScope, formattedWeek, referenceMap })
          : _.get(values, `${formattedWeek}.value`);
        const notesIndex = _.get(values, `${formattedWeek}.index`);
        const notesPath = !_.isNil(notesIndex) ? `measurables.${notesIndex}` : `measurables.${measurables.length}`;
        const notesFortheWeek = _.get(values, `${formattedWeek}.notes`, []);
        const additionalProps = {
          week: parse(formattedWeek, "yyyyMMdd", startOfDay(new Date())),
          path: notesPath,
          new: !_.isNil(notesIndex) ? false : true,
        };
        const valueForWeekNum = parseFloat(valueForWeek);
        if (!isNaN(valueForWeekNum)) {
          totalWeekValue += valueForWeekNum;
          countedWeeks += 1;
        }
        // const displayValue = valueAsFormattedNum(valueForWeek);
        const aboveTarget = checkIfAboveTarget(accuracy, valueForWeekNum, target, targetMax, targetMin);

        const isLastWeek = i === array.length - 1;
        let weeksAverage;
        let averageAboveTarget = false;
        let averageDisplayValue;
        if (isLastWeek) {
          weeksAverage = totalWeekValue / countedWeeks;
          averageAboveTarget = checkIfAboveTarget(accuracy, weeksAverage, target, targetMax, targetMin);
          averageDisplayValue = valueAsFormattedNum(_.round(weeksAverage, 2));
        }

        return (
          <React.Fragment key={`${id}-${formattedWeek}`}>
            <TableCell className={isCurrent ? styles.cellCurrent : isPast ? styles.cellPast : undefined}>
              <div className={styles.metricToggle}>
                <VirtualizedField
                  value={value}
                  valueForWeek={valueAsFormattedNum(valueForWeek)}
                  handleChange={handleChange}
                  formattedWeek={formattedWeek}
                  aboveTarget={aboveTarget}
                  id={id}
                  // handleUpdate={handleUpdate}
                  saving={saving}
                  enableFormula={enableFormula}
                  unit={unit}
                  user={user}
                  notesFortheWeek={notesFortheWeek}
                  additionalProps={additionalProps}
                />
              </div>
            </TableCell>
            {isLastWeek && (
              <TableCell key={`${id}-average`} className={averageAboveTarget ? styles.above : styles.below}>
                <div className={styles.metricToggle}>
                  <Typography align="right" variant="body1" component="label" htmlFor={`${id}-${week}`} className={styles.averageText}>
                    {unit === "$" && averageDisplayValue ? "$" : ""}
                    {averageDisplayValue}
                  </Typography>
                </div>
              </TableCell>
            )}
          </React.Fragment>
        );
      })}
    </TableRow>
  );
};

export default handleViewport(WeeklyTarget);

const accuracyIcon = {
  lt: mdiCodeLessThan,
  lte: mdiCodeLessThanOrEqual,
  gt: mdiCodeGreaterThan,
  gte: mdiCodeGreaterThanOrEqual,
  eq: mdiEqualBox,
  btw: mdiCodeBrackets,
};

const checkIfAboveTarget = (accuracy, valueForWeekNum, target, targetMax, targetMin) => {
  let aboveTarget = false;
  switch (accuracy) {
    case "lt":
      aboveTarget = valueForWeekNum < parseFloat(target);
      break;
    case "eq":
      aboveTarget = valueForWeekNum === parseFloat(target);
      break;
    case "gt":
      aboveTarget = valueForWeekNum > parseFloat(target);
      break;
    case "gte":
      aboveTarget = valueForWeekNum >= parseFloat(target);
      break;
    case "lte":
      aboveTarget = valueForWeekNum <= parseFloat(target);
      break;
    case "btw":
      aboveTarget = valueForWeekNum <= parseFloat(targetMax) && valueForWeekNum >= parseFloat(targetMin);
      break;
    default:
      break;
  }
  return aboveTarget;
};

function VirtualizedField({
  value,
  valueForWeek,
  handleChange,
  formattedWeek,
  aboveTarget,
  id,
  // handleUpdate,
  saving,
  enableFormula,
  unit,
  user,
  notesFortheWeek,
  additionalProps,
}) {
  const ref = useRef();
  const { inViewport } = useInView(ref, undefined, undefined, { onEnterViewPort: () => {}, onLeaveViewPort: () => {} });

  return (
    <div ref={ref}>
      {inViewport ? (
        <>
          <TextField
            value={valueForWeek}
            onChange={handleChange}
            name={formattedWeek.toString()}
            className={aboveTarget ? styles.valueAbove : styles.valueBelow}
            id={`${id}-${formattedWeek}`}
            // onBlur={handleUpdate}
            disabled={saving || enableFormula}
            autoComplete="off"
            InputProps={{
              inputComponent: NumberFormatCustom,
              disableUnderline: enableFormula === true,
            }}
            inputProps={{ prefix: unit === "$" ? "$" : "" }}
          />
          <div className={styles.weeklyNotesBtn}>
            <NotesButton
              id={id}
              model="weeklyTarget"
              value={value}
              user={user.id}
              doc={{ notes: notesFortheWeek }}
              tabs={["notes"]}
              additionalProps={additionalProps}
            />
          </div>
        </>
      ) : (
        <div />
      )}
    </div>
  );
}
