// Icons
import {
  mdiDatabase,
  mdiNumeric3Box,
  mdiNumeric1Box,
  mdiChartBar,
  mdiCircleSlice1,
  mdiCircleSlice2,
  mdiCircleSlice8,
  mdiLayers,
  mdiAccountPlus,
  mdiCheckboxMarked,
  mdiAlertDecagram,
  mdiCardBulleted,
  mdiRhombus,
  mdiBug,
  mdiBell,
  mdiCogClockwise,
  mdiSitemap,
} from "@mdi/js";

// Pages
import {
  AnnualReview,
  Dashboard,
  OneYear,
  QuarterlyReview,
  QuarterRocks,
  Review,
  Reports,
  Scorecard,
  TheCore,
  ThreeYear,
  Todos,
  Settings,
  Users,
  WeeklyReview,
} from "./views";

import { getListOfAuth } from "./utils/authorization";
import Bugs from "./views/Bugs/Bugs";
import SavedNotifications from "./views/SavedNotifications/SavedNotifications";
import SystemTasksTable from "./components/SystemTasksTable/SystemTasksTable";
import AccountabilityChartList from "./views/AccountabilityChartList/AccountabilityChartList";
import AccountabilityChart from "./components/AccountabilityChart/AccountabilityChart";

export const routes = [
  {
    path: "review/weekly/:meeting_id",
    name: "Weekly Review",
    component: Review,
  },
  {
    path: "scorecard",
    name: "Scorecard",
    icon: mdiCardBulleted,
    component: Scorecard,
    layout: getListOfAuth(123456),
    type: "execution tools",
  },
  {
    path: "quarter-rocks",
    name: "Quarter Rocks",
    icon: mdiRhombus,
    component: QuarterRocks,
    layout: getListOfAuth(123456),
    type: "execution tools",
  },
  {
    path: "todos",
    name: "To Dos",
    icon: mdiCheckboxMarked,
    component: Todos,
    layout: getListOfAuth(123456),
    type: "execution tools",
  },
  {
    path: "issues",
    name: "Issues",
    icon: mdiAlertDecagram,
    component: Todos,
    layout: getListOfAuth(123456),
    type: "execution tools",
    props: { issues: true },
  },
  {
    path: "accountability-chart",
    name: "R&R Chart",
    icon: mdiSitemap,
    component: AccountabilityChartList,
    layout: getListOfAuth(123456),
    type: "execution tools",
  },
  {
    path: "chart/:chartId",
    name: "Chart Page",
    component: AccountabilityChart,
  },
  {
    path: "review/weekly",
    name: "Weekly HEM",
    icon: mdiCircleSlice1,
    component: WeeklyReview,
    layout: getListOfAuth(123456),
    type: "hidden",
  },
  {
    path: "the-core",
    name: "The Core",
    icon: mdiDatabase,
    component: TheCore,
    layout: getListOfAuth(123456),
    type: "strategic roadmap",
  },
  {
    path: "3-year",
    name: "3 Year Plan",
    icon: mdiNumeric3Box,
    component: ThreeYear,
    layout: getListOfAuth(123456),
    type: "strategic roadmap",
  },
  {
    path: "1-year",
    name: "1 Year Plan",
    icon: mdiNumeric1Box,
    component: OneYear,
    layout: getListOfAuth(123456),
    type: "strategic roadmap",
  },
  {
    path: "users",
    name: "Manage Users",
    icon: mdiAccountPlus,
    component: Users,
    layout: getListOfAuth(1234),
    type: "tertiary",
  },
  {
    path: "saved-notifications",
    name: "Notifications",
    icon: mdiBell,
    component: SavedNotifications,
    layout: getListOfAuth(1234),
    type: "system",
  },
  {
    path: "system-tasks",
    name: "System Tasks",
    icon: mdiCogClockwise,
    component: SystemTasksTable,
    layout: getListOfAuth(1234),
    type: "system",
  },
  {
    path: "bugs",
    name: "Reported Bugs",
    icon: mdiBug,
    component: Bugs,
    layout: getListOfAuth(1234),
    type: "system",
  },
  {
    path: "settings",
    name: "Settings",
    component: Settings,
    layout: getListOfAuth(123456),
    type: "hidden",
  },

  {
    path: "reports",
    name: "Reports",
    icon: mdiChartBar,
    component: Reports,
    layout: getListOfAuth(12345),
    type: "primary",
  },
  // {
  //   path: "review/annual",
  //   name: "Annual Strategic Crafting",
  //   icon: mdiCircleSlice8,
  //   component: AnnualReview,
  //   layout: getListOfAuth(12),
  //   type: "primary",
  // },
  // {
  //   path: "review/quarterly",
  //   name: "Quarterly Monitor",
  //   icon: mdiCircleSlice2,
  //   component: QuarterlyReview,
  //   layout: getListOfAuth(12),
  //   type: "primary",
  // },
  {
    // Keep last otherwise other routes will never match
    path: "",
    name: "Dashboard",
    icon: mdiLayers,
    component: Dashboard,
    layout: getListOfAuth(123456),
    type: "primary",
  },
];
