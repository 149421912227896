import { useState, useContext } from "react";
import { v4 } from "uuid";
import imageCompression from "browser-image-compression";
import _ from "lodash";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { getNotes, getNestedNotes, getSubArrayFieldNotes } from "../../utils/graphql";
import firebase from "../../utils/firebase";
import { deserialize, serialize } from "../RichText/functions";
import { FetchContext } from "../../context/fetchContext";

function useNotes({ user, snack, notes, model, setRefetchId, openInEditMode, onCreateNote }) {
  const [data, setData] = useState([]);
  const { requestFetch } = useContext(FetchContext);
  const [editMode, setEditMode] = useState({ open: false, text: deserialize(""), file: null, id: null, filename: null });
  const storageRef = firebase.storage().ref();
  const [createNote] = useMutation(CREATE_NOTE);
  const [updateNote] = useMutation(UPDATE_NOTE);
  const [deleteNote] = useMutation(DELETE_NOTE);

  const getData = async (id, model, core, additionalProps) => {
    setData([]);
    if (core) {
      const res = await getNestedNotes(id, model, core);
      const resData = _.get(res, `data.${model}`);
      if (resData) {
        setData(resData.notes[core]);
      }
    } else if (id) {
      // Get notes for objectives, metrics, rocks, success criteria, todos, and issues
      let modelName = model;
      if (model === "issue") {
        modelName = "todo";
      }
      let res;
      let resData;
      if (_.isNil(additionalProps.path)) {
        res = await getNotes(id, modelName, additionalProps);
        resData = _.get(res, `data.${modelName}`);
      } else {
        const subArrayField = additionalProps.path.split(".")[0];
        res = await getSubArrayFieldNotes(id, modelName, subArrayField);
        resData = _.get(res, `data.${modelName}.${additionalProps.path}`);
      }

      if (_.get(resData, "notes")) {
        setData(resData.notes);
      }
    }
  };

  const handleClose = () => {
    notes(null, model, null, undefined, null, null, {});
    setEditMode({ open: false, text: deserialize(""), file: null, id: null, filename: null });
  };

  const handleExitDialog = () => {
    notes(null, null, null, undefined, null, null, {});
  };

  const handleEditMode = (open, note) => () => {
    if (!note) {
      // create new notes
      setEditMode({ open, text: deserialize(""), file: null, id: null, filename: null });
    } else {
      // updating existing notes
      const { id, text, filename } = note;
      setEditMode({ open, text: deserialize(text), file: null, id, filename });
    }
  };

  const handleChange = (e) => {
    const { name } = e.target;
    if (name === "text") {
      setEditMode({ ...editMode, text: e.target.value });
    } else if (name === "file") {
      if (!_.isEmpty(e.target.files) && e.target.validity.valid) {
        setEditMode({ ...editMode, file: e.target.files[0] });
      }
    }
  };

  const handleCreate = (id, model, core, additionalProps) => async () => {
    const { text, file } = editMode;
    let fileToUpload = file;
    if (fileToUpload) {
      snack("Uploading files...");
      const name = fileToUpload.name.split(".").slice(0, -1);
      const extension = fileToUpload.name.split(".").pop();
      const filename = `${name}-${v4().split("-")[0]}.${extension}`; //Append a random string to the image to prevent name collisions
      const fileRef = storageRef.child(`notes/${filename}`);
      const type = file.type.includes("image") ? "img" : "doc";
      if (type === "img") {
        const compressionOptions = { maxSizeMB: 1 };
        fileToUpload = await imageCompression(file, compressionOptions);
      }

      fileRef.put(fileToUpload).then(async () => {
        const url = await fileRef.getDownloadURL();
        handleCreateNote({ id, model, core, additionalProps, text, filename, type, url });
      });
    } else {
      handleCreateNote({ id, model, core, additionalProps, text, type: "doc" });
    }
  };

  const handleUpdate =
    ({ id, model, core, additionalProps }) =>
    async () => {
      const { text, file, filename } = editMode;
      let fileToUpload = file;
      // if notes already have image while new file is uploaded, remove original image
      if (!_.isNil(filename) && fileToUpload) {
        const fileRef = storageRef.child(`notes/${filename}`);
        fileRef.delete();
      }

      if (fileToUpload) {
        snack("Uploading files...");
        const name = fileToUpload.name.split(".").slice(0, -1);
        const extension = fileToUpload.name.split(".").pop();
        const newFilename = `${name}-${v4().split("-")[0]}.${extension}`; //Append a random string to the image to prevent name collisions
        const fileRef = storageRef.child(`notes/${newFilename}`);
        const type = file.type.includes("image") ? "img" : "doc";
        if (type === "img") {
          const compressionOptions = { maxSizeMB: 1 };
          fileToUpload = await imageCompression(file, compressionOptions);
        }

        fileRef.put(fileToUpload).then(async () => {
          const url = await fileRef.getDownloadURL();
          const updateArgs = { text: serialize(text), filename: newFilename, type, url };
          handleUpdateNote({ id, model, core, additionalProps, updateArgs });
        });
      } else {
        const updateArgs = { text: serialize(text) };
        handleUpdateNote({ id, model, core, additionalProps, updateArgs });
      }
    };

  const handleCreateNote = async ({ id, model, core, additionalProps, text, type, filename = null, url = null }) => {
    const res = await createNote({
      variables: {
        referenceId: id,
        referenceModel: model,
        additionalProps,
        user: user.user.id,
        text: serialize(text),
        url,
        filename,
        type,
        core,
      },
    });
    if (res.data.createNote) {
      onCreateNote();
      snack(`Created ${filename || "note"}`);
      getData(id, model, core, additionalProps);
      requestFetch();
      // setRefetchId(core || id);
    }

    setEditMode({ open: false, text: deserialize(""), file: null, id: null, filename: null });
  };

  const handleUpdateNote = async ({ id, model, core, additionalProps, updateArgs }) => {
    const res = await updateNote({
      variables: { id: editMode.id, ...updateArgs },
    });
    if (res.data.updateNote) {
      snack("Updated Note");
      getData(id, model, core, additionalProps);
      requestFetch();
      // setRefetchId(core || id);
    }
    setEditMode({ open: false, text: deserialize(""), file: null, id: null, filename: null });
  };

  const handleDelete =
    ({ id, referenceId, model, filename, core, additionalProps }) =>
    async () => {
      if (filename !== null) {
        snack(`Deleting file...`);
        const fileRef = storageRef.child(`notes/${filename}`);

        fileRef.delete();
      }

      const res = await deleteNote({ variables: { id, referenceId, referenceModel: model, core, additionalProps } });
      if (res.data.deleteNote) {
        snack(`Deleted ${filename || "note"}`);
        getData(referenceId, model, core, additionalProps);
        requestFetch();
        // setRefetchId(core || referenceId);
      }
    };

  const handleRemoveImage = async (id, model, core, filename, noteId, additionalProps) => {
    if (filename !== null) {
      snack(`Deleting file...`);
      const fileRef = storageRef.child(`notes/${filename}`);

      fileRef.delete();

      const updateArgs = { id: noteId, type: "doc", filename: null, url: null };
      const res = await updateNote({
        variables: { id: noteId, ...updateArgs },
      });
      if (res.data.updateNote) {
        snack("Removed uploaded file");
        getData(id, model, core, additionalProps);
      }
      setEditMode({ open: false, text: deserialize(""), file: null, id: null, filename: null });
    }
  };

  return {
    data,
    editMode,
    getData,
    handleClose,
    handleExitDialog,
    handleEditMode,
    handleChange,
    handleCreate,
    handleUpdate,
    handleDelete,
    handleRemoveImage,
  };
}

export default useNotes;

const CREATE_NOTE = gql`
  mutation (
    $referenceId: ID
    $referenceModel: String
    $user: ID!
    $text: String
    $url: String
    $filename: String
    $type: String!
    $core: String
    $additionalProps: AdditionalProps
  ) {
    createNote(
      referenceId: $referenceId
      referenceModel: $referenceModel
      user: $user
      text: $text
      url: $url
      filename: $filename
      type: $type
      core: $core
      additionalProps: $additionalProps
    )
  }
`;

const UPDATE_NOTE = gql`
  mutation ($id: ID!, $text: String, $url: String, $filename: String, $type: String) {
    updateNote(id: $id, text: $text, url: $url, filename: $filename, type: $type)
  }
`;

const DELETE_NOTE = gql`
  mutation ($id: ID, $referenceId: ID, $referenceModel: String, $core: String, $additionalProps: AdditionalProps) {
    deleteNote(id: $id, referenceId: $referenceId, referenceModel: $referenceModel, core: $core, additionalProps: $additionalProps)
  }
`;
