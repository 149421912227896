import React, { useEffect, useContext, useState } from "react";
import styles from "./AddMetricDialog.module.scss";
import _ from "lodash";
import { useQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import useForm from "../../hooks/useForm";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Tooltip,
  useMediaQuery,
  Chip,
  FormControlLabel,
  Switch,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import CloseIcon from "@material-ui/icons/Close";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import HelpIcon from "@material-ui/icons/HelpOutlineRounded";
import { UserContext } from "../../context/userContext";
import { isAuthed } from "../../utils/authorization";
import SelectUsers from "../SelectUsers/SelectUsers";
import SelectDepartment from "../SelectDepartment/SelectDepartment";
import Loading from "../Loading/Loading";
import VariableDialog from "../VariableDialog/VariableDialog";
import SyntaxDialog from "../VariableDialog/SyntaxDialog";
import SelectObjectives from "../SelectObjectives/SelectObjectives";
import { GET_METRICS } from "../../utils/query";
import { METRIC_FIELDS } from "../../utils/fragments";

const initForm = {
  value: null,
  users: [],
  cumulative: false,
  calculateTotal: true,
  year: [
    [{ value: "Y1" }, { value: "" }, { value: "" }],
    [{ value: "Y2" }, { value: "" }, { value: "" }],
    [{ value: "Y3" }, { value: "" }, { value: "" }],
  ],
  quarter: [
    [{ value: "Q1" }, { value: "" }, { value: "" }],
    [{ value: "Q2" }, { value: "" }, { value: "" }],
    [{ value: "Q3" }, { value: "" }, { value: "" }],
    [{ value: "Q4" }, { value: "" }, { value: "" }],
    [{ value: "Total" }, { value: "" }, { value: "" }],
  ],
  unit: null,
  plan: null,
  comparator: "gte",
  enableFormula: false,
  formula: null,
  formulaScope: [],
  objectives: [],
};

const initErrorForm = {
  value: ["required"],
  formula: [
    {
      type: "custom",
      callback: (form, value) => (form.enableFormula === true && !(_.isNil(value) || _.isEmpty(value))) || form.enableFormula !== true,
      errorMessage: "This field is required",
    },
  ],
  ...initForm.year.reduce((accum, curr, currIdx) => {
    const numberValidation = [
      {
        type: "custom",
        callback: (form, value) => _.isEmpty(value) || /^-?(\d*\.?\d+|\d{1,3}(,\d{3})*(\.\d+)?)$/g.test(value),
        errorMessage: "Invalid number formatting",
      },
    ];

    accum[`year[${currIdx}][1].value`] = numberValidation;
    accum[`year[${currIdx}][2].value`] = numberValidation;

    return accum;
  }, {}),
  ...initForm.quarter.reduce((accum, curr, currIdx) => {
    const numberValidation = [
      {
        type: "custom",
        callback: (form, value) => _.isEmpty(value) || /^-?(\d*\.?\d+|\d{1,3}(,\d{3})*(\.\d+)?)$/g.test(value),
        errorMessage: "Invalid number formatting",
      },
    ];

    accum[`quarter[${currIdx}][1].value`] = numberValidation;
    accum[`quarter[${currIdx}][2].value`] = numberValidation;

    return accum;
  }, {}),
};

const AddMetricDialog = ({ dialog, setDialog, requestFetch, fetch, params, snack }) => {
  const { user } = useContext(UserContext);
  const category = _.get(dialog, "addMetricDialog.category", null);
  const oneYearCorpPlan = _.get(dialog, "addMetricDialog.planId"); // this will be 3 year corp plan if creating a 3 year metric

  const { sharedPlanId } = _.get(user, "departmentFilter");
  const {
    data,
    loading: queryLoading,
    refetch,
  } = useQuery(GET_USERS_AND_PLANS_METRICS, {
    variables: {
      organization: params.org,
      planCategory: { year: "3 year", quarter: "1 year" }[category],
      category,
      sharedPlanId,
      oneYearCorpPlan,
      closed: _.isNil(oneYearCorpPlan) ? false : null,
    },
  });

  const corpPlanId = _.get(dialog, "addMetricDialog.planId");
  const longestMetricMeasurable = _.get(data, "longestMetricMeasurable"); //for 3 year only

  const adjustedInitForm = formatInitialForm(initForm, longestMetricMeasurable, category);

  const variables = _.get(dialog, "addMetricDialog.variables");
  const [createMetric, { loading: mutationLoading }] = useMutation(CREATE_METRIC, {
    update(cache, { data: { createMetric } }) {
      try {
        if (_.isNil(variables)) {
          throw new Error("variable cannot be null or undefined");
        }
        const { metric, oneYearMetric } = createMetric;
        const variablesArr1 = [
          variables
        ];
        const departmentSharedPlanId = variables.sharedPlanId
        if (_.isNil(departmentSharedPlanId)) {
          variablesArr1.push({ organization: variables.organization, planId: variables.planId, sharedPlanId: _.get(metric, "plan.sharedPlanId") })
        } else {
          variablesArr1.push({ organization: variables.organization, planId: variables.planId, sharedPlanId: null })
        }
        for (const variables of variablesArr1) {
          const existingData = cache.readQuery({
            query: GET_METRICS,
            variables,
          });
          if (existingData) {
            cache.writeQuery({
              query: GET_METRICS,
              variables,
              data: {
                metrics: [metric, ...existingData.metrics],
              },
            });
          }
        }

        if (oneYearMetric) {
          // find corpPlan variable
          const corpPlanId = _.get(oneYearMetric, "plan.plan.id") ? _.get(oneYearMetric, "plan.plan.id") : _.get(oneYearMetric, "plan.id");
          const departmentSharedPlanId = _.get(oneYearMetric, "plan.sharedPlanId");

          const variablesArr2 = [
            { organization: variables.organization, planId: corpPlanId, sharedPlanId: null }, // All department query
            { organization: variables.organization, planId: corpPlanId, sharedPlanId: departmentSharedPlanId }, // Specific department query
          ];

          for (const oneYearGetMetricVariable of variablesArr2) {
            const existingOneYearMetricData = cache.readQuery({
              query: GET_METRICS,
              variables: oneYearGetMetricVariable,
            });
            // only write to cache if the one year query exist
            if (existingOneYearMetricData) {
              cache.writeQuery({
                query: GET_METRICS,
                variables: oneYearGetMetricVariable,
                data: {
                  metrics: [oneYearMetric, ...existingOneYearMetricData.metrics],
                },
              });
            }
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
  });
  const {
    form,
    formErrors,
    handleChange,
    handleChangeManual,
    handleChangeToggleButton,
    handleToggleCheckBox,
    handleChangeCallback,
    resetForm,
    validateForm,
    valueAsFormattedNum,
    removeFormattedNum,
  } = useForm({
    initForm: adjustedInitForm,
    initErrorForm,
  });

  const [variableDialogOpen, setVariableDialogOpen] = useState(false);
  const [formulaScopeIdx, setFormulaScopeIdx] = useState(null);

  // syntax dialog states
  const [syntaxDialogOpen, setSyntaxDialogOpen] = useState(false);

  const metrics = (_.get(data, "metrics") || []).filter((metric) => {
    // filter by corp plan selection
    if (corpPlanId) {
      const metricCorpPlanId = _.get(metric, "plan.plan.id", _.get(metric, "plan.id"));
      return metricCorpPlanId === corpPlanId;
    }
    return true;
  });
  const metricsById = _.keyBy(metrics, "id");

  const fs = useMediaQuery("(max-width: 600px)");

  const updateTotal = (clonedForm) => {
    const { calculateTotal, quarter } = clonedForm;
    if (calculateTotal) {
      const value = quarter.reduce((sum, [name, val]) => (name.value !== "Total" ? sum + Number(removeFormattedNum(val.value)) : sum), 0);
      quarter[4][1].value = value.toString();
      resetForm({ ...form, quarter });
    }
  };

  const handleComparator = (event, newComparator) => {
    // enforce value set
    if (newComparator !== null) {
      handleChangeManual({ name: "comparator", value: newComparator });
    }
  };

  const handleChangeAuto = () => {
    resetForm({ ...form, calculateTotal: !form.calculateTotal });
  };

  const handleClose = () => {
    resetForm();
    setDialog({ ...dialog, addMetricDialog: { open: false } });
  };

  const handleSubmit =
    ({ close, category }) =>
      async () => {
        if (!validateForm()) return;

        const { value, unit, users, plan, objectives, cumulative, calculateTotal, comparator, enableFormula, formula, formulaScope } = form;

        let measurables = [];
        if (category === "year") measurables = form.year;
        else if (category === "quarter") {
          const { quarter } = form;
          if (cumulative) {
            quarter[4][1].value = quarter[3][1].value.toString(); //Year total is the same as the 4th quarter total
          }
          measurables = quarter;
        }

        measurables.forEach((m) => {
          m[1].value = removeFormattedNum(m[1].value);
        });

        const res = await createMetric({
          variables: {
            organization: params.org,
            plan,
            objectives,
            category,
            value,
            users,
            comparator,
            measurables,
            unit,
            cumulative,
            calculateTotal,
            enableFormula,
            formula,
            formulaScope,
          },
        });

        if (res) {
          snack(`Created "${value}" metric`);
          // requestFetch();
          resetForm({ ...initForm, plan });
          if (close) {
            handleClose();
          }
        }
      };

  useEffect(() => {
    if (form.calculateTotal) {
      updateTotal(form);
    }
  }, [form.calculateTotal]);

  const departmentFilterSharedId = _.get(user, "departmentFilter.sharedPlanId");
  useEffect(() => {
    const plans = _.get(data, "plans", []);
    const planWithSameSharedPlanId = _.find(plans, (plan) => plan.sharedPlanId === departmentFilterSharedId);

    const plan = _.get(dialog, "addMetricDialog.planId");
    const users = _.get(dialog, "addMetricDialog.users");
    const unit = _.get(dialog, "addMetricDialog.unit");
    const value = _.get(dialog, "addMetricDialog.value");

    if (dialog.addMetricDialog.open && !queryLoading) {
      resetForm({
        ...adjustedInitForm,
        plan: departmentFilterSharedId ? _.get(planWithSameSharedPlanId, "id", null) : plan,
        ...(!_.isNil(users) && { users }),
        ...(!_.isNil(unit) && { unit }),
        ...(!_.isNil(value) && { value }),
      });
    } else {
      resetForm();
    }
  }, [dialog.addMetricDialog.open, departmentFilterSharedId, queryLoading]);

  useEffect(() => {
    if (dialog.addMetricDialog.open) {
      refetch();
    }
  }, [fetch, dialog.addMetricDialog.open]);

  if (queryLoading) return null;

  const numOfvariables = _.get(form, "formulaScope.length");

  return (
    <Dialog
      open={dialog.addMetricDialog.open}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      fullWidth
      fullScreen={fs}
    >
      <DialogTitle>
        <div className={styles.title}>
          Create New Metric
          <div>
            <IconButton onClick={handleClose} size="small">
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </div>
        </div>
      </DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          label="Metric Details"
          name="value"
          fullWidth
          margin="normal"
          variant="outlined"
          value={form.value || ""}
          onChange={handleChange}
          helperText={formErrors.value}
          error={Boolean(formErrors.value)}
        />
        <SelectDepartment
          plans={_.get(data, "plans")}
          name="plan"
          handleChange={handleChange}
          value={form.plan}
          helperText="Which department does this metric belong to?"
          disabled={!_.isNil(departmentFilterSharedId)}
        />
        {category === "year" && (
          <SelectObjectives
            name="objectives"
            objectives={_.get(data, "objectives", null)}
            handleChange={handleChange}
            values={form.objectives}
            category={"1 year"}
            multiple
            helperText="Which 3 year objectives are tied to this metric?"
            plansOrder={_.get(data, "organization.plansOrder")}
          />
        )}
        {category === "quarter" && (
          <>
            <ToggleButtonGroup
              exclusive
              value={form.cumulative}
              onChange={handleChangeToggleButton("cumulative")}
              className={styles.flexCenter}
            >
              <ToggleButton value={false}>
                <Tooltip title="Each entry is the total from that quarter">
                  <div>Add values</div>
                </Tooltip>
              </ToggleButton>
              <ToggleButton value={true}>
                <Tooltip title="Each entry includes the totals of previous quarters">
                  <div>Don't add values</div>
                </Tooltip>
              </ToggleButton>
            </ToggleButtonGroup>
          </>
        )}
        <SelectUsers
          name="users"
          users={_.get(data, "users")}
          handleChange={handleChange}
          values={form.users}
          helperText="Who is accountable for this metric?"
          plan={form.plan}
          allPlans={_.get(data, "plans")}
        />
        <div className={styles.modelButtons}>
          <ToggleButtonGroup exclusive value={form.comparator} onChange={handleComparator}>
            <ToggleButton value="lt">Less Than</ToggleButton>
            <ToggleButton value="lte">Less Than or Equal</ToggleButton>
            <ToggleButton value="eq">Equal To</ToggleButton>
            <ToggleButton value="gte">Greater Than or Equal</ToggleButton>
            <ToggleButton value="gt">Greater Than</ToggleButton>
          </ToggleButtonGroup>
        </div>
        <FormControlLabel
          control={<Switch size="small" name="enableFormula" checked={!!form.enableFormula} onChange={handleToggleCheckBox} />}
          label="Enable Formula"
          style={{ marginLeft: -7 }}
        />
        {form.enableFormula && (
          <div className={styles.formulaInput} style={{ marginBottom: 8 }}>
            <TextField
              placeholder="Formula (e.g. (v0 + 100)/v2)"
              name="formula"
              fullWidth
              margin="dense"
              variant="filled"
              value={form.formula}
              onChange={handleChange}
              helperText={formErrors.formula}
              error={Boolean(formErrors.formula)}
              InputProps={{
                endAdornment: (
                  <>
                    <Tooltip title="Add Variable">
                      <IconButton onClick={() => setVariableDialogOpen(true)}>
                        <AddCircleIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Help">
                      <IconButton onClick={() => setSyntaxDialogOpen(true)}>
                        <HelpIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                ),
              }}
            />
            <div>
              {form.formulaScope.map((scope, idx) => {
                const { varType, value: scopeValue } = scope;
                const displayName = varType === "number" ? scopeValue : _.get(metricsById, `${scopeValue}.value`, "");
                return (
                  <Chip
                    key={idx}
                    label={
                      <span>
                        <span style={{ fontWeight: "bold" }}>{`v${idx}: `}</span>
                        {`${displayName}`}
                      </span>
                    }
                    variant="outlined"
                    size="small"
                    style={{ marginRight: "4px" }}
                    onDelete={() => {
                      const newScope = form.formulaScope.filter((scope, index) => index !== idx);
                      handleChange({
                        target: {
                          name: "formulaScope",
                          value: newScope,
                        },
                      });
                    }}
                    onClick={() => {
                      setVariableDialogOpen(true);
                      setFormulaScopeIdx(idx);
                    }}
                  />
                );
              })}
            </div>
            <VariableDialog
              open={variableDialogOpen}
              handleClose={() => {
                setVariableDialogOpen(false);
                setFormulaScopeIdx(null);
              }}
              handleChangeVariable={(value) =>
                handleChange({
                  target: {
                    name: `formulaScope.${!_.isNil(formulaScopeIdx) ? formulaScopeIdx : numOfvariables}`,
                    value,
                  },
                })
              }
              formulaScope={_.get(form, `formulaScope.${!_.isNil(formulaScopeIdx) ? formulaScopeIdx : numOfvariables}`, {
                varType: "reference",
                value: null,
              })}
              list={metrics}
              listName={"Metric"}
              createMode={_.isNil(formulaScopeIdx)}
            />
            <SyntaxDialog open={syntaxDialogOpen} handleClose={() => setSyntaxDialogOpen(false)} />
          </div>
        )}
        {category === "year" && (
          <>
            {form.year.map((year, idx) => (
              <div className={styles.metricInput} key={idx}>
                <TextField
                  label={`${year[0].value.replace("Y", "Year ")} Projected Value`}
                  name={`year[${idx}][1].value`}
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={form.enableFormula ? "TBD" : form.year[idx][1].value}
                  onChange={handleChange}
                  disabled={form.enableFormula}
                  helperText={_.get(formErrors, `year[${idx}][1].value`)}
                  error={Boolean(_.get(formErrors, `year[${idx}][1].value`))}
                />
                <TextField
                  label="Unit"
                  name="unit"
                  margin="normal"
                  variant="outlined"
                  value={form.unit || ""}
                  onChange={handleChange}
                  className={styles.unit}
                />
              </div>
            ))}
          </>
        )}

        {category === "quarter" && (
          <>
            {form.quarter.map((quarter, idx) => {
              if (quarter[0].value === "Total" && form.cumulative) {
                return null;
              }
              return (
                <div className={styles.metricInput} key={idx}>
                  {quarter[0].value !== "Total" ? (
                    <TextField
                      label={`Quarter ${idx + 1} Projected Value`}
                      name={`quarter[${idx}][1].value`}
                      margin="normal"
                      fullWidth
                      variant="outlined"
                      value={form.enableFormula ? "TBD" : form.quarter[idx][1].value}
                      onChange={handleChangeCallback(updateTotal)}
                      disabled={form.enableFormula}
                      helperText={_.get(formErrors, `quarter[${idx}][1].value`)}
                      error={Boolean(_.get(formErrors, `quarter[${idx}][1].value`))}
                    />
                  ) : (
                    <TextField
                      label="Yearly Projected Value"
                      name={`quarter[${idx}][1].value`}
                      fullWidth
                      margin="normal"
                      variant="outlined"
                      value={form.enableFormula ? "TBD" : form.quarter[idx][1].value}
                      onChange={handleChange}
                      disabled={form.calculateTotal || form.enableFormula}
                      helperText={_.get(formErrors, `quarter[${idx}][1].value`)}
                      error={Boolean(_.get(formErrors, `quarter[${idx}][1].value`))}
                      InputProps={{
                        endAdornment: (
                          <Button
                            variant={form.calculateTotal ? "contained" : "outlined"}
                            onClick={handleChangeAuto}
                            color="primary"
                            disableElevation
                          >
                            Auto
                          </Button>
                        ),
                      }}
                    />
                  )}
                  <TextField
                    label="Unit"
                    name="unit"
                    margin="normal"
                    variant="outlined"
                    value={form.unit || ""}
                    onChange={handleChange}
                    className={styles.unit}
                  />
                </div>
              );
            })}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSubmit({ close: false, category })}
          color="primary"
          variant="outlined"
          disabled={mutationLoading}
          className={styles.button}
        >
          {mutationLoading ? <Loading size={24} color="#fff" /> : "Create & Add Another"}
        </Button>
        <Button
          onClick={handleSubmit({ close: true, category })}
          color="primary"
          variant="contained"
          disabled={mutationLoading}
          className={styles.button}
        >
          {mutationLoading ? <Loading size={24} color="#fff" /> : "Create"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddMetricDialog;

const GET_USERS_AND_PLANS_METRICS = gql`
  query AddMetricDialog_GetUsersPlansMetrics(
    $organization: ID!
    $category: String
    $planCategory: String
    $sharedPlanId: ID
    $oneYearCorpPlan: ID
    $closed: Boolean
  ) {
    organization(id: $organization) {
      id
      plansOrder
    }

    users(organization: $organization) {
      id
      name {
        first
        last
      }
      profilePicture
      plan {
        id
        departmentName
        sharedPlanId
      }
    }

    plans(organization: $organization, category: $planCategory, oneYearCorpPlan: $oneYearCorpPlan, closed: $closed) {
      id
      departmentName
      sharedPlanId
      year
    }

    metrics(organization: $organization, category: $category, sharedPlanId: $sharedPlanId) {
      id
      value
      measurables {
        value
        notes {
          id
        }
        reference {
          id
        }
      }
      unit
      cumulative
      calculateTotal
      enableFormula
      formula
      formulaScope {
        varType
        value
      }
      status
      plan {
        id
        plan {
          id
        }
      }
    }
    longestMetricMeasurable(org: $organization) {
      measurables {
        value
        notes
        reference
      }
    }

    objectives(organization: $organization, archived: false, category: "3 year", sharedPlanId: $sharedPlanId) {
      id
      value
      category
      number
      plan {
        id
        departmentName
        color
        shortName
        sharedPlanId
      }
    }
  }
`;

const CREATE_METRIC = gql`
  ${METRIC_FIELDS}
  mutation CreateOneYearMetric(
    $value: String!
    $comparator: String!
    $measurables: [[MetricMeasurableInput]]
    $unit: String
    $organization: ID!
    $plan: ID!
    $objectives: [ID!]
    $category: String!
    $users: [ID!]
    $cumulative: Boolean
    $calculateTotal: Boolean
    $enableFormula: Boolean
    $formula: String
    $formulaScope: [FormulaVariableInput!]
  ) {
    createMetric(
      value: $value
      comparator: $comparator
      measurables: $measurables
      unit: $unit
      organization: $organization
      plan: $plan
      objectives: $objectives
      category: $category
      users: $users
      cumulative: $cumulative
      calculateTotal: $calculateTotal
      enableFormula: $enableFormula
      formula: $formula
      formulaScope: $formulaScope
    ) {
      metric {
        ...MetricFields
      }
      plans {
        id
        metrics
      }
      oneYearMetric {
        ...MetricFields
      }
    }
  }
`;

const formatInitialForm = (initForm, longestMetricMeasurable, category) => {
  // add extra year columns
  if (_.isNil(longestMetricMeasurable) || category === "quarter") {
    return initForm;
  }
  let newInitForm = { ...initForm };
  const measurables = longestMetricMeasurable.measurables;
  const measurableLength = measurables.length;

  // if other metric contains more than 3 year columns, add the years to initForm
  let closedMeasurables = [];
  if (measurableLength > 3) {
    measurables.forEach((year, idx) => {
      if (measurableLength - idx > 3) {
        const yearName = year[0].value;
        closedMeasurables.push([{ value: yearName }, { value: "" }, { value: "" }]);
      }
    });

    newInitForm.year = [...closedMeasurables, ...newInitForm.year];
  }

  return newInitForm;
};
