import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { mdiAccount, mdiAccountRemoveOutline, mdiDotsHorizontal, mdiFaceAgent, mdiMenuDown, mdiMenuUp, mdiPlusCircle } from "@mdi/js";
import Icon from "@mdi/react";
import _ from "lodash";
import React from "react";
import EditableText from "./EditableText";
import styles from "./AccountabilityChart.module.scss";
import EditableList from "./EditableList";
import PlanPill from "../PlanPill/PlanPill";

const NodeCard = ({
  node,
  toggleNode,
  handleChangeNode,
  handleUpdateChartAndAddHistory,
  handleSubmit,
  handleAddChildNode,
  handleAddAssistantNode,
  handleDeleteLeafNode,
  displayOptions,
  disableEditing,
  users,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [userAnchorEl, setUserAnchorEl] = React.useState(null);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleOpenUserMenu = (event) => {
    setUserAnchorEl(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setUserAnchorEl(null);
  };

  const handleChangeNodeAndUpdate = (newNode) => {
    const chartCopy = handleChangeNode(newNode);
    handleUpdateChartAndAddHistory(chartCopy);
  };

  const handleSelectUser = (user) => () => {
    let nodeCopy = _.omit(node, ["children", "__rd3t"]);
    _.set(nodeCopy, "user", user);
    _.set(nodeCopy, "title", user.position || "");
    _.set(nodeCopy, "name", `${user.name.first} ${user.name.last}`);
    // _.set(nodeCopy, "roles", user.position || "");
    handleChangeNodeAndUpdate(nodeCopy);
    handleCloseUserMenu();
  };

  const handleRemoveUser = () => {
    let nodeCopy = _.omit(node, ["children", "__rd3t"]);
    _.set(nodeCopy, "user", null);
    _.set(nodeCopy, "title", "");
    _.set(nodeCopy, "name", "");
    handleChangeNodeAndUpdate(nodeCopy);
    handleCloseUserMenu();
  };

  const handleChangeNodeField = (e) => {
    const { name, value } = e.target;
    let nodeCopy = _.omit(node, ["children", "__rd3t"]);
    _.set(nodeCopy, name, value);
    handleChangeNode(nodeCopy);
  };

  const handleDeleteAndCloseMenu = (node) => {
    handleDeleteLeafNode(node);
    handleCloseMenu();
  };

  const handleAddAssistantAndCloseMenu = (node) => {
    handleAddAssistantNode(node);
    handleCloseMenu();
  };

  return (
    <Card variant="outlined" className={styles.nodeCard}>
      <div style={{ marginTop: "16px" }}></div>
      <div className={styles.nodeHeaderTextContainer}>
        {displayOptions.avatar && (
          <>
            <Avatar
              className={styles.nodeCardAvatar}
              src={_.get(node, "user.profilePicture")}
              onClick={(e) => (!disableEditing ? handleOpenUserMenu(e) : {})}
            >
              <Icon path={mdiAccount} size={1} />
            </Avatar>
            <Menu
              anchorEl={userAnchorEl}
              open={Boolean(userAnchorEl)}
              onClose={handleCloseUserMenu}
              style={{ maxHeight: "300px" }}
              transitionDuration={0}
            >
              {!_.isNil(node.user) && (
                <MenuItem onClick={handleRemoveUser}>
                  <ListItemIcon style={{ justifyContent: "center" }}>
                    <Icon path={mdiAccountRemoveOutline} size={1.25} color="#ef5350" />
                  </ListItemIcon>
                  <ListItemText>Remove User</ListItemText>
                </MenuItem>
              )}
              {!_.isEmpty(users) &&
                users.map((user) => (
                  <MenuItem key={user.id} onClick={handleSelectUser(user)}>
                    <ListItemIcon>
                      <PlanPill plan={_.get(user, "plan.0")} />
                    </ListItemIcon>
                    <ListItemText>
                      {user.name.first} {user.name.last}
                    </ListItemText>
                  </MenuItem>
                ))}
            </Menu>
          </>
        )}
        {displayOptions.name && (
          <div style={{ marginLeft: "8px" }}>
            {/* {!_.isNil(node.user) ? (
              <Typography className={styles.nodeNameText}>{node.name}</Typography>
            ) : ( */}
            <EditableText
              value={node.name}
              defaultValue={"Name"}
              name={"name"}
              placeholder={"Name"}
              handleChange={handleChangeNodeField}
              handleSubmit={handleSubmit}
              textClassName={styles.nodeNameText}
              inputClassName={styles.nodeNameInput}
              disabled={disableEditing}
            />
            {/* )} */}
            <EditableText
              value={node.title}
              defaultValue={"Title"}
              name={"title"}
              placeholder={"Title"}
              handleChange={handleChangeNodeField}
              handleSubmit={handleSubmit}
              textClassName={styles.nodeTitleText}
              inputClassName={styles.nodeTitleInput}
              disabled={disableEditing}
            />
          </div>
        )}
      </div>
      {!disableEditing && (
        <>
          <IconButton style={{ strokeWidth: 0, position: "absolute", top: 0, right: 0 }} onClick={handleOpenMenu}>
            <Icon path={mdiDotsHorizontal} size={1} />
          </IconButton>
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleCloseMenu}>
            {_.isEmpty(node.childNodes) && (
              <MenuItem onClick={() => handleDeleteAndCloseMenu(node)}>
                <ListItemText>Delete</ListItemText>
              </MenuItem>
            )}
            {!node.isAssistant && (
              <MenuItem onClick={() => handleAddAssistantAndCloseMenu(node)}>
                <ListItemText>Add an Assistant</ListItemText>
              </MenuItem>
            )}
          </Menu>
        </>
      )}
      <CardContent className={styles.nodeCardContent}>
        {displayOptions.roles && (
          <EditableText
            value={node.roles}
            defaultValue={"Role"}
            name={"roles"}
            placeholder={"Role"}
            handleChange={handleChangeNodeField}
            handleSubmit={handleSubmit}
            textClassName={styles.nodeRolesText}
            inputClassName={styles.nodeRolesInput}
            disabled={disableEditing}
          />
        )}
        {displayOptions.responsibilities && (
          <EditableList
            value={node.responsibilities}
            defaultValue={"Enter Responsibilities..."}
            name={"responsibilities"}
            placeholder={"Enter Responsibilities..."}
            handleChange={handleChangeNodeField}
            handleSubmit={handleSubmit}
            textClassName={styles.nodeListText}
            inputClassName={styles.nodeListName}
            disabled={disableEditing}
          />
        )}
      </CardContent>
      {!node.isAssistant && (
        <CardActions disableSpacing className={styles.nodeCardAction}>
          {!disableEditing ? (
            <IconButton style={{ strokeWidth: 0, color: "green" }} onClick={() => handleAddChildNode(node)}>
              <Icon path={mdiPlusCircle} size={1} />
            </IconButton>
          ) : (
            <div style={{ height: "48px", width: "48px", padding: "12px" }}></div>
          )}
          {!_.isEmpty(node.children) && (
            <IconButton style={{ strokeWidth: 0, color: "orange" }} onClick={toggleNode}>
              <Icon path={node.__rd3t.collapsed ? mdiMenuDown : mdiMenuUp} size={1.25} />
            </IconButton>
          )}
          {!_.isEmpty(node.assistantNodes) ? (
            <IconButton style={{ strokeWidth: 0 }} disabled>
              <Icon path={mdiFaceAgent} style={{ strokeWidth: 0 }} size={1} />
            </IconButton>
          ) : (
            <div style={{ height: "48px", width: "48px", padding: "12px" }}></div>
          )}
        </CardActions>
      )}
    </Card>
  );
};

export default NodeCard;
