import React, { useState, useEffect, useContext } from "react";
import handleViewport from "react-in-viewport";
import styles from "./Rocks.module.scss";
import _ from "lodash";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { TableRow, TableCell, MenuItem, IconButton, Tooltip, Typography } from "@material-ui/core";
import Icon from "@mdi/react";
import { mdiChevronDown, mdiChevronUp, mdiChartTimelineVariant } from "@mdi/js";
import { FetchContext } from "../../context/fetchContext";
import { SnackbarContext } from "../../context/snackbarContext";
import useInViewport from "../../hooks/useInViewport";
import UserAvatars from "../UserAvatars/UserAvatars";
import Menu from "../Menu/Menu";
import SuccessCriteria from "../SuccessCriteria/SuccessCriteria";
import Status from "../Status/Status";
import ProgressBar from "../ProgressBar/ProgressBar";
import NotesButton from "../Notes/NotesButton";
import Skeleton from "./Skeleton";
import { formatAs } from "../../utils/dates";
import ConfirmDeletionDialog from "../ConfirmDeletionDialog/ConfirmDeletionDialog";
import useConfirmDelete from "../../hooks/useConfirmDelete";
import PlanPill from "../PlanPill/PlanPill";

const Rock = ({
  inViewport,
  forwardedRef,
  handleAddSCDialog,
  handleAddIssueDialog,
  handleDuplicate,
  showAllSc,
  canEdit,
  quarterDate,
  rock,
  handleEditDialog,
  planId,
}) => {
  const { id, value, index, users, status, successCriterias, objective, plan } = rock;
  const { requestFetch } = useContext(FetchContext);
  const { snack } = useContext(SnackbarContext);
  const [showSc, setShowSc] = useState(false);

  const [deleteRock] = useMutation(DELETE_ROCK, {
    update(cache, { data: { deleteRock } }) {
      const { deletedRock, deletedSuccessCriterias } = deleteRock;
      const deletedRockId = cache.identify(deletedRock);
      const deletedScIds = deletedSuccessCriterias.map((sc) => cache.identify(sc));
      cache.modify({
        fields: {
          rocks: (existingRocks) => {
            return existingRocks.filter((rockRef) => {
              const rockId = cache.identify(rockRef);
              return rockId !== deletedRockId;
            });
          },
          successCriterias: (existingScs) => {
            return existingScs.filter((scRef) => {
              const scId = cache.identify(scRef);
              return !deletedScIds.includes(scId);
            });
          },
        },
      });
    },
  });

  const [updateRock] = useMutation(UPDATE_ROCK);
  const hasRendered = useInViewport(inViewport);

  const itemType = "rock";
  const { confirmOpen, handleConfirmOpen, handleDelete } = useConfirmDelete({
    id,
    value,
    itemType,
  });

  const handleUpdateStatus = (newStatus) => async () => {
    const ok = await updateRock({
      variables: {
        id,
        status: newStatus,
      },
    });
    if (ok) {
      snack(`Marked "${value}" as ${newStatus}`);
      requestFetch();
    }
  };

  const handleShowSc = () => {
    setShowSc(!showSc);
  };

  useEffect(() => {
    setShowSc(showAllSc);
  }, [showAllSc]);

  if (!hasRendered)
    return (
      <TableRow ref={forwardedRef} className={styles.skeletonRow}>
        <Skeleton />
      </TableRow>
    );

  const isComplete = status === "complete" || (!_.isEmpty(successCriterias) && successCriterias.every((sc) => sc.done));

  return (
    <>
      <TableRow className={styles.noBot} ref={forwardedRef}>
        <TableCell className={styles.firstCell}>
          <div className={styles.flex}>
            {!_.isNil(plan?.departmentName) ? <PlanPill plan={plan} /> : <PlanPill plan={null} />}
            <div className={isComplete ? styles.complete : undefined}>{value}</div>
            <NotesButton
              id={id}
              model="rock"
              value={value.trim()}
              user={_.get(users, "0") ? _.get(users, "0.id") : null}
              doc={rock}
              tabs={["notes", "issues", "todos"]}
              canEditTodo={canEdit}
              planId={planId}
            />
            {canEdit && (
              <Menu>
                {/* <MenuItem onClick={handleAddIssueDialog(id, "Rock", value.trim(), users[0] ? users[0].id : null)}>Add Issue</MenuItem> */}
                <MenuItem onClick={handleEditDialog(true, rock)}>Edit</MenuItem>
                <MenuItem onClick={handleAddSCDialog(id)}>Add Success Criteria</MenuItem>
                <MenuItem onClick={handleDuplicate({ objectiveId: objective.id, index, value, id, successCriterias })}>Copy</MenuItem>
                <MenuItem onClick={handleConfirmOpen(true)} className={styles.delete}>
                  Delete
                </MenuItem>
              </Menu>
            )}
          </div>
        </TableCell>
        <TableCell align="center">
          <Tooltip
            title={
              <Typography variant="body2">
                <span className={styles.label}>
                  Objective: <br />
                </span>
                {objective?.value}
              </Typography>
            }
          >
            <Icon path={mdiChartTimelineVariant} size={1.25} color="rgba(0, 0, 0, 0.54)" />
          </Tooltip>
        </TableCell>
        <TableCell align="center">
          <span className={styles.quarter}>
            {index} {quarterDate && <span className={styles.label}>({formatAs(quarterDate, "MMM d")})</span>}
          </span>
        </TableCell>
        <TableCell align="center">
          <ProgressBar sc={successCriterias} done={isComplete} />
        </TableCell>
        <TableCell>
          <div className={styles.flexCenter}>
            <Status status={status} />
            <Menu icon="arrow">
              <MenuItem onClick={handleUpdateStatus("on track")} disabled={status === "on track"}>
                Mark as on track
              </MenuItem>
              <MenuItem onClick={handleUpdateStatus("off track")} disabled={status === "off track"}>
                Mark as off track
              </MenuItem>
            </Menu>
          </div>
        </TableCell>
        <TableCell className={styles.lastCell}>
          <UserAvatars users={users} />
        </TableCell>
        <TableCell align="right">
          <IconButton onClick={handleShowSc}>
            <Icon path={showSc ? mdiChevronUp : mdiChevronDown} size={1} color="rgba(0, 0, 0, 0.54)" />
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell colSpan="7">
          <div>
            {successCriterias &&
              successCriterias.map((sc) => {
                if (!showSc) return null;
                return (
                  <SuccessCriteria
                    key={sc.id}
                    sc={sc}
                    requestFetch={requestFetch}
                    handleAddIssueDialog={handleAddIssueDialog}
                    snack={snack}
                    showComplete={true}
                  />
                );
              })}
            {_.isEmpty(successCriterias) && showSc && (
              <Typography variant="body2" className={styles.noSc}>
                No success criteria. {canEdit && <u onClick={handleAddSCDialog(id)}>Add one</u>}
              </Typography>
            )}
          </div>
        </TableCell>
      </TableRow>

      <ConfirmDeletionDialog
        itemType={itemType}
        value={value}
        confirmOpen={confirmOpen}
        handleConfirmOpen={handleConfirmOpen}
        handleDeletion={handleDelete(deleteRock)}
      />
    </>
  );
};

export default handleViewport(Rock);

const DELETE_ROCK = gql`
  mutation QuarterRocksDeleteRock($id: ID!) {
    deleteRock(id: $id) {
      deletedRock {
        id
      }
      deletedSuccessCriterias {
        id
      }
      updatedObjective {
        id
        rocks {
          id
        }
      }
      updatedPlan {
        id
        rocks {
          id
        }
      }
    }
  }
`;

const UPDATE_ROCK = gql`
  mutation ($id: ID!, $status: String) {
    updateRock(id: $id, status: $status)
  }
`;
