import React from "react";
import styles from "./WeeklyTargets.module.scss";
import { TableCell } from "@material-ui/core";
import SkeletonMui from "@material-ui/lab/Skeleton";

const Skeleton = ({ long }) => {
  return (
    <>
      <TableCell className={styles.firstCell}>
        <SkeletonMui variant="text" />
      </TableCell>
      <TableCell>
        <div className={styles.flexCenter}>
          <SkeletonMui variant="circle" height={25} width={25} />
        </div>
      </TableCell>
      <TableCell>
        <div className={styles.flexCenter}>
          <SkeletonMui variant="rect" height={25} width={40} />
        </div>
      </TableCell>
      {[...Array(14)].map((_, i) => {
        return (
          <TableCell key={i}>
            <div className={styles.flexCenter}>
              <SkeletonMui variant="rect" height={25} width={100} />
            </div>
          </TableCell>
        );
      })}
    </>
  );
};

export default Skeleton;
