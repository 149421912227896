import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import firebase from "./utils/firebase";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { ThemeProvider, StylesProvider } from "@material-ui/core/styles";
import { QueryParamProvider } from "use-query-params";
import theme from "./utils/theme";

import { SnackbarContextProvider } from "./context/snackbarContext";
import { UserContext } from "./context/userContext";
import { LoadingContext } from "./context/loadingContext";

import { CssBaseline } from "@material-ui/core";

import Layout from "./layout/Layout";
import { SelectOrganization, Signin, Signout, Signup } from "./views";
import Loading from "./components/Loading/Loading";
import Privacy from "./views/Legal/Privacy/Privacy";
import Security from "./views/Legal/Security/Security";
import Terms from "./views/Legal/Terms/Terms";
import Pricing from "./views/Legal/Pricing/Pricing";
import PasswordReset from "./views/PasswordReset/PasswordReset";

const App = () => {
  const { user, refreshUser, setUser } = useContext(UserContext);
  const { resetLoading } = useContext(LoadingContext);

  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [prevPath, setPrevPath] = useState(() => location.pathname);

  //for admin users, their department filter needs resetting when changing over organizations
  useEffect(() => {
    if (location.pathname !== prevPath) {
      const pathDirectories = location.pathname.split("/");
      const prevPathDirectories = prevPath.split("/");

      if (prevPathDirectories[1] !== pathDirectories[1]) {
        if (user) {
          setUser({
            ...user,
            departmentFilter: { id: null, sharedPlanId: null },
          });
        }
      }

      // clear one year objectives session keys when navigating away from the page
      if (prevPathDirectories[2] !== pathDirectories[2] && prevPathDirectories[2] === "1-year") {
        var oneYearSessionKeys = [];
        for (let i = 0; i < sessionStorage.length; i++) {
          if (sessionStorage.key(i).startsWith("oneYearObjs.")) {
            oneYearSessionKeys.push(sessionStorage.key(i));
          }
        }

        for (let i = 0; i < oneYearSessionKeys.length; i++) {
          sessionStorage.removeItem(oneYearSessionKeys[i]);
        }
      }

      resetLoading();
      setPrevPath(location.pathname);
    }
  }, [location]);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (u) => {
      await refreshUser(_.get(u, "uid"));
      setLoading(false);
    });
  }, []);

  if (loading) return <Loading fullscreen size={128} />;

  return (
    <QueryParamProvider ReactRouterRoute={Route}>
      <ThemeProvider theme={theme}>
        <StylesProvider injectFirst>
          <CssBaseline />
          <SnackbarContextProvider>
            <Switch>
              <Route exact path="/privacy" component={Privacy} />
              <Route exact path="/security" component={Security} />
              <Route exact path="/terms" component={Terms} />
              <Route exact path="/pricing" component={Pricing} />
              <Route
                exact
                path="/reset/:id/:token"
                render={(props) => (!_.isNil(user) ? <Redirect to="/" /> : <PasswordReset {...props} />)}
              />
              <Route exact path="/signup" render={(props) => (_.isNil(user) ? <Redirect to="/" /> : <Signup {...props} />)} />
              <Route exact path="/" render={(props) => (_.isNil(user) ? <Signin {...props} /> : <SelectOrganization {...props} />)} />
              <Route exact path="/signout" render={(props) => (_.isNil(user) ? <Redirect to="/" /> : <Signout {...props} />)} />
              <Route path="/:org" render={(props) => (_.isNil(user) ? <Redirect to="/" /> : <Layout {...props} />)} />
              <Route render={() => <Redirect to="/" />} />
            </Switch>
          </SnackbarContextProvider>
        </StylesProvider>
      </ThemeProvider>
    </QueryParamProvider>
  );
};

export default App;
