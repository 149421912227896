import React, { useState, useEffect, useContext, useMemo } from "react";
import styles from "./Rocks.module.scss";
import _ from "lodash";
import { DialogContext } from "../../context/dialogContext";
import { UserContext } from "../../context/userContext";
import { isAuthed } from "../../utils/authorization";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableSortLabel,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from "@material-ui/core";
import Icon from "@mdi/react";
import { mdiPlus, mdiRhombus } from "@mdi/js";
import { getCurrentQuarter } from "../../utils/dates";
import CardTitle from "../CardTitle/CardTitle";
import Rock from "./Rock";
import { quarterDates, addToDate, formatAs } from "../../utils/dates";
import useMobileMenu from "../../hooks/useMobileMenu";
import EditDialog from "../Rock/EditDialog";
import { SnackbarContext } from "../../context/snackbarContext";
// import SelectYear from "../SelectYear/SelectYear";

const Rocks = ({ rocks, fiscalYear, planId, variables }) => {
  const { user } = useContext(UserContext);
  const { snack } = useContext(SnackbarContext);
  const { dialog, setDialog } = useContext(DialogContext);
  const [showAllSc, setShowAllSc] = useState(JSON.parse(localStorage.getItem(`rocks.showAllSc`)) || false);
  const [sort, setSort] = useState({ value: localStorage.getItem(`rocks.sortValue`) || "quarter", order: localStorage.getItem(`rocks.sortOrder`) || "desc" });
  const [selectedQuarter, setSelectedQuarter] = useState(0);

  const [selectedRock, setSelectedRock] = useState({});
  const [editDialog, setEditDialog] = useState(false);

  const { isMobile, renderMobileMenu } = useMobileMenu();

  const handleEditDialog = (open, rock) => () => {
    setEditDialog(open);
    setSelectedRock(rock);
  };

  const rockQuarterDict = rocks.reduce(
    (accum, currRock) => ({ ...accum, [currRock.id]: quarterDates(addToDate(fiscalYear, { days: 1 }), currRock.index)[1] }),
    {}
  );

  const rockProgressDict = getRockProgressDict(rocks);

  // const [rockQuarterDict, setRockQuarterDict] = useState(generateRockQuarterDict);
  // const [rockProgressDict, setRockProgressDict] = useState(generateRockProgressDict);
  const [searchTerm, setSearchTerm] = useState("");

  const handleAddDialog = () => {
    setDialog({
      ...dialog,
      addRockDialog: { open: true, quarter: 1, value: null, objective: [], planId, successCriterias: [], variables },
    });
  };

  const handleAddSCDialog = (id) => () => {
    setDialog({ ...dialog, addSuccessCriteriaDialog: id });
  };

  const handleAddIssueDialog =
    (referenceId = null, referenceModel = null, value = null, user = null) =>
      () => {
        setDialog({
          ...dialog,
          addTodoDialog: {
            open: true,
            category: "issue",
            referenceId,
            referenceModel,
            value,
            user,
            planId
          },
        });
      };

  const handleDuplicate =
    ({ objectiveId, index, value, id, successCriterias }) =>
      () => {
        setDialog({
          ...dialog,
          addRockDialog: {
            open: objectiveId,
            quarter: index,
            value,
            users: [],
            duplicate: id,
            planId,
            successCriterias: successCriterias.map((sc) => _.omit(sc, ["id"])),
            variables,
          },
        });
      };

  const handleShowAllSc = () => {
    localStorage.setItem(`rocks.showAllSc`, JSON.stringify(!showAllSc));
    setShowAllSc(!showAllSc);
  };

  const handleSort = (value) => () => {
    const order = sort.value === value && sort.order === "desc" ? "asc" : "desc";
    setSort({ value, order });
    localStorage.setItem(`rocks.sortValue`, value);
    localStorage.setItem(`rocks.sortOrder`, order);
  };

  useEffect(() => {
    setSelectedQuarter(getCurrentQuarter(fiscalYear));
  }, []);

  // useEffect(() => {
  //   setRockQuarterDict(generateRockQuarterDict);
  //   setRockProgressDict(generateRockProgressDict);
  // }, [rocks]);

  const sortedRocks = useMemo(() => {
    const { value, order } = sort;
    let arr = [],
      filteredRocks = rocks.filter(filterRocks);

    if (value === "quarter") {
      arr = _.sortBy(filteredRocks, (rock) => rockQuarterDict[rock.id]);
    } else if (value === "progress") {
      arr = _.sortBy(filteredRocks, (rock) => rockProgressDict[rock.id]);
    } else if (value === "status") {
      arr = _.sortBy(filteredRocks, (rock) => rock.status);
    } else if (value === "accountable") {
      arr = _.sortBy(filteredRocks, (rock) => _.get(rock, "users[0].name.first"));
    }

    if (order === "asc") {
      arr = arr.reverse();
    }

    return arr;
  }, [sort, rocks, rockQuarterDict, rockProgressDict, searchTerm]);

  function filterRocks(value) {
    // if it the case that there is no entered search-term
    if (searchTerm === "") {
      return value;
    }

    // if the execution goes past this point, this means either the user entered a search-term
    let userNames = value.users ? value.users.map((user) => `${_.get(user, "name.first", "")} ${_.get(user, "name.last", "")}`) : [];
    let quarter = `${_.get(value, "index", "")} (${formatAs(rockQuarterDict[value.id], "MMM d")})`;
    let progress = rockProgressDict[value.id] + "%";
    let successCriterias = value.successCriterias ? value.successCriterias.map((sc) => sc.value) : [];

    let hasSearchTermMatch =
      _.get(value, "value", "").toLowerCase().includes(searchTerm.toLowerCase()) ||
      _.get(value, "status", "").toLowerCase().includes(searchTerm.toLowerCase()) ||
      quarter.toLowerCase().includes(searchTerm.toLowerCase()) ||
      progress.toLowerCase().includes(searchTerm.toLowerCase()) ||
      userNames.find((user) => user.toLowerCase().includes(searchTerm.toLowerCase())) ||
      successCriterias.find((sc) => sc.toLowerCase().includes(searchTerm.toLowerCase()));

    if (hasSearchTermMatch) {
      return value;
    }
  }
  const ensureDefault = (fnc, def) => (fnc === null ? def : fnc);
  return (
    <>
      <Card className={styles.card}>
        <CardTitle vertical color="blue">
          <Icon path={mdiRhombus} size={1} color="#fff" className={styles.icon} />
          <Typography variant="h5" className={styles.title}>
            Rocks
          </Typography>
        </CardTitle>
        <CardActions className={styles.cardActions}>
          {renderMobileMenu(
            <>
              <TextField
                className={styles.searchField}
                label="Search"
                type="search"
                variant="outlined"
                size="small"
                onChange={(event) => {
                  setSearchTerm(event.target.value);
                }}
              />
              <Button variant="outlined" onClick={handleShowAllSc} size="large" className={styles.menuButton}>
                {showAllSc ? "Hide" : "Show"} Success Criteria
              </Button>
              <FormControl variant="outlined" className={styles.selectQuarterForm} size="small">
                <InputLabel>Quarter</InputLabel>
                <Select value={selectedQuarter} label="Quarter" onChange={(e) => setSelectedQuarter(e.target.value)}>
                  {[...Array(5).keys()].map((val, idx) => (
                    <MenuItem value={val} key={idx}>
                      {val > 0 ? `Q${val}` : "All"}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
          {/* {enableYearFilter && <SelectYear handleChange={(e) => setSelectedYear(e.target.value)} selectedYear={selectedYear} />} */}
          {isMobile ? (
            <IconButton
              onClick={handleAddDialog}
              className={styles.menuButtonColor}
              color="primary"
              disabled={!isAuthed(user.user, "department facilitator")}
            >
              <Icon path={mdiPlus} size={0.75} color="#fff" />
            </IconButton>
          ) : (
            <Button
              startIcon={<Icon path={mdiPlus} size={1} color="#fff" />}
              className={styles.menuButtonColor}
              onClick={handleAddDialog}
              variant="contained"
              color="primary"
              disabled={!isAuthed(user.user, "department facilitator")}
            >
              New Rock
            </Button>
          )}
        </CardActions>
        <CardContent className={styles.cardContent}>
          {!_.isEmpty(rocks) ? (
            <Table className={styles.table}>
              <TableHead>
                <TableRow>
                  <TableCell>&nbsp;</TableCell>
                  <TableCell align="center">Tie In</TableCell>
                  <TableCell align="center" sortDirection={sort.value === "quarter" ? sort.order : false}>
                    <TableSortLabel
                      onClick={handleSort("quarter")}
                      active={sort.value === "quarter"}
                      direction={sort.value === "quarter" ? sort.order : "asc"}
                    >
                      Quarter
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center" sortDirection={sort.value === "progress" ? sort.order : false}>
                    <TableSortLabel
                      onClick={handleSort("progress")}
                      active={sort.value === "progress"}
                      direction={sort.value === "progress" ? sort.order : "asc"}
                    >
                      Progress
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center" sortDirection={sort.value === "status" ? sort.order : false}>
                    <TableSortLabel
                      onClick={handleSort("status")}
                      active={sort.value === "status"}
                      direction={sort.value === "status" ? sort.order : "asc"}
                    >
                      Status
                    </TableSortLabel>
                  </TableCell>
                  <TableCell align="center" sortDirection={sort.value === "accountable" ? sort.order : false}>
                    <TableSortLabel
                      onClick={handleSort("accountable")}
                      active={sort.value === "accountable"}
                      direction={sort.value === "accountable" ? sort.order : "asc"}
                    >
                      Accountable
                    </TableSortLabel>
                  </TableCell>
                  <TableCell className={styles.lastCell} />
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedRocks &&
                  sortedRocks.map((rock, i) => {
                    if (rock.index !== selectedQuarter && selectedQuarter !== 0) return null;
                    return (
                      <Rock
                        key={i}
                        rock={rock}
                        quarterDate={rockQuarterDict[rock.id]}
                        handleAddSCDialog={handleAddSCDialog}
                        handleAddIssueDialog={handleAddIssueDialog}
                        handleDuplicate={handleDuplicate}
                        showAllSc={showAllSc}
                        canEdit={isAuthed(user.user, "department facilitator") && !ensureDefault(_.get(rock, "archived"), false)}
                        handleEditDialog={handleEditDialog}
                        planId={planId}
                      />
                    );
                  })}
              </TableBody>
            </Table>
          ) : (
            <Typography variant="body1" align="center">
              Nothing to show <br />
              {isAuthed(user.user, "department facilitator") && (
                <Button color="primary" onClick={handleAddDialog}>
                  Add a rock
                </Button>
              )}
            </Typography>
          )}
        </CardContent>
      </Card>
      {!_.isEmpty(selectedRock) && (
        <EditDialog
          rock={selectedRock}
          open={editDialog}
          handleClose={handleEditDialog(false)}
          snack={snack}
          handleAddIssueDialog={handleAddIssueDialog}
          planId={planId}
        />
      )}
    </>
  );
};

export default Rocks;

const getRockProgressDict = (rocks) => {
  let dict = {};

  rocks.forEach((rock) => {
    let completed = 0,
      total = 0;

    rock.successCriterias.forEach((sc) => {
      total++;
      if (sc.done) {
        completed++;
      }
    });

    let percentage = 0.0;
    if (rock.status === "complete") {
      percentage = 100;
    } else if (total > 0) {
      percentage = (completed / total) * 100;
    }
    dict[rock.id] = percentage;
  });

  return dict;
};
