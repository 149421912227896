import React, { useState } from "react";
import _ from "lodash";
import styles from "./Signin.module.scss";
import useForm from "../../hooks/useForm";
import firebase from "../../utils/firebase";

import { Typography, TextField, Button } from "@material-ui/core";
import ForgotPasswordDialog from "./ForgotPasswordDialog";
import Version from "../../components/Version/Version";

import logo from "../../assets/img/icon-white.png";
import splash from "../../assets/img/splash.svg";
import { Link } from "react-router-dom";

const initForm = { email: null, password: null };

const Signin = () => {
  const [forgotPasswordDialog, setForgotPasswordDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { form, handleChange } = useForm({ initForm });

  const handleForgotPasswordDialog = (open) => () => {
    setForgotPasswordDialog(open);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { email, password } = form;

    setLoading(true);
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);
    } catch (err) {
      setErrorMessage(err.message);
      setLoading(false);
    }
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.splash}>
          <div className={styles.splashOverlay}>
            <img src={logo} alt="Ventrek Logo" className={styles.img} />
            <Typography variant="h3" className={styles.splashTitle}>
              Welcome to Ventrek
            </Typography>
          </div>
          <img src={splash} alt="splash" className={styles.svg} />
        </div>
        <div className={styles.content}>
          <Typography variant="h2" gutterBottom>
            Sign In
          </Typography>

          <form onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoFocus
              value={form.email || ""}
              onChange={handleChange}
            />
            <TextField
              type="password"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="password"
              label="Password"
              name="password"
              value={form.password || ""}
              onChange={handleChange}
            />
            {errorMessage && (
              <Typography variant="body1" color="error" paragraph>
                {errorMessage}
              </Typography>
            )}
            <Button type="submit" margin="normal" fullWidth variant="contained" color="primary" disabled={loading}>
              {loading ? "Signing In..." : "Sign In"}
            </Button>
            <Button color="primary" margin="normal" onClick={handleForgotPasswordDialog(true)} disabled={loading}>
              Forgot password?
            </Button>
          </form>
          <Version className={styles.version} />
          <div className={styles.linkContainer}>
            <Link to="/pricing" target="_blank" rel="noopener noreferrer" className={styles.link}>
              PRICING
            </Link>
            <Link to="/privacy" target="_blank" rel="noopener noreferrer" className={styles.link}>
              PRIVACY
            </Link>
            <Link to="/security" target="_blank" rel="noopener noreferrer" className={styles.link}>
              SECURITY
            </Link>
            <Link to="/terms" target="_blank" rel="noopener noreferrer" className={styles.link}>
              TERMS
            </Link>
          </div>
        </div>
      </div>
      <ForgotPasswordDialog open={forgotPasswordDialog} handleClose={handleForgotPasswordDialog(false)} firebase={firebase} />
    </>
  );
};

export default Signin;
