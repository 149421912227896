import React, { useState, useEffect, useMemo } from "react";
import styles from "./QuarterSummary.module.scss";
import _ from "lodash";
import { Table, TableHead, TableBody, TableRow, TableCell, Typography, TableContainer } from "@material-ui/core";
import { getCurrentQuarter } from "../../utils/dates";
import { getQuarters } from "../../utils/misc";
import PlanPill from "../PlanPill/PlanPill";

const QuarterSummary = ({ rocks = [], plans, closed, fiscalYear, highestReviewedQuarter, currentQuarter }) => {
  const [maxQuarter, setMaxQuarter] = useState(0);
  const [quarterTopHits, setQuarterTopHits] = useState([]);
  const [quarterTopMisses, setQuarterTopMisses] = useState([]);
  const [quarterGrades, setQuarterGrades] = useState([]);

  const toTitleCase = (str) => {
    return _.startCase(_.toLower(str)).trim();
  };

  const quarterData = useMemo(() => {
    let quarterOveralls = {};

    const quarterDataArr = _(rocks)
      .filter((rock) => {
        if (!_.isEmpty(plans)) {
          // The following will only be ran on the summary page
          const rockPlanId = _.get(rock, "plan.id");
          const rockQuarter = _.get(rock, "index");
          const plan = _.find(plans, ["id", rockPlanId]);

          return _.get(plan, ["periodData", (rockQuarter - 1).toString(), "reviewed"]);
        }
        return true;
      })
      .groupBy((rock) => {
        const user = _.get(rock, ["users", "0"]);
        const userId = _.get(user, "id");
        const firstName = toTitleCase(_.get(user, "name.first", ""));
        const lastName = toTitleCase(_.get(user, "name.last", ""));
        return `${userId}|${firstName} ${lastName}`;
      })
      .map((rocksArr, userStr) => {
        let rocksByQuarter = _(rocksArr).sortBy(["index"]).groupBy("index").value();

        let quarterStats = {};
        Object.keys(rocksByQuarter).forEach((quarterStr) => {
          const quarterRocks = rocksByQuarter[quarterStr];

          const completed = quarterRocks.filter((rock) => _.get(rock, "status") === "complete").length;
          const total = quarterRocks.length;

          quarterStats[quarterStr] = { completed, total };

          if (_.isNil(quarterOveralls[quarterStr])) {
            quarterOveralls[quarterStr] = { completed, total };
          } else {
            quarterOveralls[quarterStr]["total"] = quarterOveralls[quarterStr]["total"] + total;
            quarterOveralls[quarterStr]["completed"] = quarterOveralls[quarterStr]["completed"] + completed;
          }
        });

        let [id, name] = userStr.split("|");
        if (id === "undefined") {
          name = "Unassigned";
        }

        return { id, name, quarterStats };
      })
      .sortBy(["name"])
      .value();

    quarterDataArr.unshift({
      name: "Rocks",
      quarterStats: quarterOveralls,
    });

    return quarterDataArr;
  }, [rocks, plans]);

  useEffect(() => {
    if (plans) {
      let quarterGradeGroups = [[], [], [], []];

      for (const plan of plans) {
        const trimmedPlan = _.pick(plan, ["id", "color", "shortName", "departmentName"]);
        const periodData = _.get(plan, "periodData", []);

        periodData.forEach((periodDatum, quarterIdx) => {
          const gradeObj = _.get(periodDatum, "periodGrade", {});

          if (!Object.values(gradeObj).some((val) => _.isNil(val))) {
            quarterGradeGroups[quarterIdx].push({ ...trimmedPlan, ...gradeObj });
          }
        });
      }

      setQuarterTopHits(
        quarterGradeGroups.map((quarterGradesArr) => _.groupBy(quarterGradesArr, ({ topHit }) => `${toTitleCase(topHit)}`))
      );

      setQuarterTopMisses(
        quarterGradeGroups.map((quarterGradesArr) => _.groupBy(quarterGradesArr, ({ topMiss }) => `${toTitleCase(topMiss)}`))
      );

      setQuarterGrades(
        quarterGradeGroups.map((quarterGradesArr) => _.groupBy(quarterGradesArr, ({ grade }) => `${_.toUpper(grade).trim()}`))
      );
    }
  }, [plans]);

  useEffect(() => {
    const quarterCeiling =
      isNaN(highestReviewedQuarter) || currentQuarter > highestReviewedQuarter ? currentQuarter - 1 : highestReviewedQuarter;

    setMaxQuarter(quarterCeiling);
  }, [highestReviewedQuarter]);

  function getQuarterCell(completed = 0, total = 0) {
    const percentage = total === 0 ? 0 : Math.round((completed / total) * 100);
    return (
      <>
        {completed}/{total} = <b>{percentage}%</b>
      </>
    );
  }

  function getGradeStatsRow(gradeStatsArr = [], labelStr) {
    return (
      <TableRow>
        <TableCell>{labelStr}</TableCell>
        {gradeStatsArr.map((statsDict, idx) => {
          const allPlansSameVal = Object.keys(statsDict).length === 1;

          return (
            <TableCell align="center" key={idx}>
              {Object.keys(statsDict).map((statsVal) => {
                const plans = statsDict[statsVal];

                return (
                  <div className={styles.gradesContainer}>
                    {!allPlansSameVal && (
                      <div className={styles.plansContainer}>
                        {plans.map((plan, idx) => (
                          <PlanPill plan={plan} key={IDBIndex}></PlanPill>
                        ))}
                      </div>
                    )}
                    <div className={allPlansSameVal ? styles.statsContainerSolo : styles.statsContainer}>
                      <b>{statsVal}</b>
                    </div>
                  </div>
                );
              })}
            </TableCell>
          );
        })}
      </TableRow>
    );
  }

  const isSingleQuarter = _.isNil(fiscalYear);

  return (
    <TableContainer className={styles.tableContainer}>
      {isSingleQuarter && quarterData.length === 1 ? (
        <Typography style={{ textAlign: "center" }}>No summary data available</Typography>
      ) : (
        <Table size="small">
          {!isSingleQuarter && (
            <>
              <colgroup>
                {[...Array(5).keys()].map((idx) => (
                  <col key={idx} style={{ width: idx > 0 ? "calc(90% / 4)" : "10%" }} />
                ))}
              </colgroup>
              <TableHead className={styles.tableHead}>
                <TableRow>
                  <TableCell style={{ backgroundColor: "white" }} />
                  {[...Array(4).keys()].map((quarterIdx) => {
                    const isCurrentQuarter = quarterIdx + 1 === currentQuarter;
                    return (
                      <TableCell align="center" key={quarterIdx}>
                        <Typography style={{ fontWeight: isCurrentQuarter ? "bold" : "normal" }}>
                          Q{quarterIdx + 1} {getQuarters(fiscalYear, quarterIdx + 1)} {isCurrentQuarter && "[Current]"}
                        </Typography>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
            </>
          )}
          <TableBody className={styles.tableBody}>
            {!isSingleQuarter && (
              <>
                {getGradeStatsRow(quarterTopHits, "Top Hit")}
                {getGradeStatsRow(quarterTopMisses, "Top Miss")}
                {getGradeStatsRow(quarterGrades, "Grade")}
              </>
            )}
            {quarterData.map(({ id, name, quarterStats }) => {
              return (
                <TableRow key={`${id}${name}`}>
                  <TableCell>{name}</TableCell>
                  {isSingleQuarter
                    ? Object.values(quarterStats).map(({ completed, total }, idx) => (
                        <TableCell align="center" key={idx}>
                          {getQuarterCell(completed, total)}
                        </TableCell>
                      ))
                    : [...Array(4).keys()].map((quarterIdx) => {
                        const targetStats = _.get(quarterStats, (quarterIdx + 1).toString(), {});

                        return closed || quarterIdx + 1 <= maxQuarter ? (
                          <TableCell align="center" key={quarterIdx}>
                            <div>{getQuarterCell(targetStats.completed, targetStats.total)}</div>
                          </TableCell>
                        ) : (
                          <TableCell key={quarterIdx} />
                        );
                      })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
    </TableContainer>
  );
};

export default QuarterSummary;
