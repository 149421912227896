import React, { useEffect, useContext, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import useForm from "../../hooks/useForm";
import styles from "./Metrics.module.scss";
import _ from "lodash";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { getYear } from "../../utils/dates";
import { calcQuarterMetricTotal, getQuarterMetricTotal, mergeCustomizer } from "../../utils/misc";

import { FetchContext } from "../../context/fetchContext";
import { UserContext } from "../../context/userContext";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Tooltip,
  Typography,
  IconButton,
  Switch,
  Chip,
  List,
  ListItem,
  ListItemText,
  FormControl,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio,
} from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import HelpIcon from "@material-ui/icons/HelpOutlineRounded";
import Icon from "@mdi/react";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { mdiLinkVariant } from "@mdi/js";
import { deepPurple, blueGrey } from "@material-ui/core/colors";

import SelectUsers from "../SelectUsers/SelectUsers";
import SelectDepartment from "../SelectDepartment/SelectDepartment";
import SelectObjectives from "../SelectObjectives/SelectObjectives";
import PlanPill from "../PlanPill/PlanPill";
import VariableDialog from "../VariableDialog/VariableDialog";
import SyntaxDialog from "../VariableDialog/SyntaxDialog";
import { METRIC_FIELDS } from "../../utils/fragments";

const EditDialog = ({
  open,
  handleClose,
  metric,
  snack,
  category,
  metrics,
  yearOne,
  plansOrder,
  handleOpenAddMetricDialog,
  corpForSelectedYear,
  planId,
}) => {
  const {
    id,
    value,
    comparator,
    measurables,
    unit,
    users,
    status,
    departmentName,
    plan,
    cumulative,
    calculateTotal,
    enableFormula,
    formula,
    formulaScope,
    number,
    objectives,
  } = metric;
  const YEARS = { Y1: getYear(yearOne), Y2: getYear(yearOne) + 1, Y3: getYear(yearOne) + 2 };

  const { user } = useContext(UserContext);
  const { sharedPlanId } = _.get(user, "departmentFilter");
  const params = useParams();
  const { fetch, requestFetch } = useContext(FetchContext);

  const [updateMetric] = useMutation(UPDATE_METRIC);
  const {
    data,
    refetch,
    loading: dialogDataLoading,
  } = useQuery(GET_DIALOG_DATA, {
    variables: {
      organization: params.org,
      category,
      sharedPlanId,
      oneYearCorpPlan: planId, // 3year plan id if edit 3 year, else 1 year plan id
      closed: _.isNil(corpForSelectedYear) ? false : null,
    },
  });

  const [getOneYearPlan, { data: oneYearPlanData, refetch: oneYearPlansRefetch }] = useLazyQuery(GET_ONE_YEAR_PLAN);

  const initForm = {
    value,
    comparator,
    measurables: measurables.map((array) =>
      array.map((obj) => ({ value: obj.value, notes: obj.notes, reference: _.get(obj, "reference.id") }))
    ),
    unit,
    cumulative,
    calculateTotal,
    users: users.map((u) => u.id),
    plan: _.get(plan, "id"),
    enableFormula,
    formula,
    formulaScope: (formulaScope || []).map((variable) => _.omit(variable, "__typename")),
    objectives,
  };

  const initErrorForm = {
    value: ["required"],
    formula: [
      {
        type: "custom",
        callback: (form, value) => (form.enableFormula === true && !(_.isNil(value) || _.isEmpty(value))) || form.enableFormula !== true,
        errorMessage: "This field is required",
      },
    ],
    ..._.get(initForm, "measurables", []).reduce((accum, curr, currIdx) => {
      const numberValidation = [
        {
          type: "custom",
          callback: (form, value) => _.isEmpty(value) || /^-?(\d*\.?\d+|\d{1,3}(,\d{3})*(\.\d+)?)$/g.test(value),
          errorMessage: "Invalid number formatting",
        },
      ];

      accum[`measurables[${currIdx}][1].value`] = numberValidation;
      accum[`measurables[${currIdx}][2].value`] = numberValidation;

      return accum;
    }, {}),
  };

  const {
    form,
    formErrors,
    resetForm,
    handleChange,
    handleChangeManual,
    handleChangeToggleButton,
    handleToggleCheckBox,
    handleChangeCallback,
    validateForm,
    valueAsFormattedNum,
    removeFormattedNum,
  } = useForm({
    initForm,
    initErrorForm,
  });

  const [variableDialogOpen, setVariableDialogOpen] = useState(false);
  const [formulaScopeIdx, setFormulaScopeIdx] = useState(null);

  const [syntaxDialogOpen, setSyntaxDialogOpen] = useState(false);
  const [openAddMetricDialog, setOpenAddMetricDialog] = useState(false);

  const [oneYearMetricsListOpen, setOneYearMetricsListOpen] = useState(false);
  const [selectedYear, setSelectedYear] = useState();
  const [selectedMeasurableIdx, setSelectedMeasurableIdx] = useState();

  const metricsById = _.keyBy(metrics, "id");

  const handleChangeAuto = () => {
    resetForm({ ...form, calculateTotal: !form.calculateTotal });
  };

  const updateTotal = (index) => (clonedForm) => {
    const { calculateTotal, measurables } = clonedForm;
    const clonedMeasurables = _.cloneDeep(measurables);
    if (clonedMeasurables.length === 5 && calculateTotal) {
      const value = calcQuarterMetricTotal(clonedMeasurables, index);
      clonedMeasurables[4][index].value = value.toString();

      if (index === 2) {
        resetForm({ ...form, measurables: clonedMeasurables });
      } else {
        updateTotal(2)({ ...clonedForm, measurables: clonedMeasurables });
      }
    }
  };

  const handleOpenOneYearMetrics = (year, measurableIdx) => () => {
    setSelectedYear(year);
    setSelectedMeasurableIdx(measurableIdx);
    setOneYearMetricsListOpen(true);
  };

  const handleLinkOneYearMetric = (metricId) => {
    const measurableClone = _.cloneDeep(_.get(form, ["measurables", selectedMeasurableIdx.toString(), "2"]));
    const newMeasurable = _.merge({}, measurableClone, { reference: metricId });

    handleChangeManual({ name: `measurables[${selectedMeasurableIdx}][2]`, value: newMeasurable });
    setOneYearMetricsListOpen(false);
  };

  const getOneYearMetricTotal = (metricId) => {
    const metrics = _.get(data, "metrics", []);
    const oneYearMetric = _.find(metrics, ["id", metricId]);
    const measurables = _.get(oneYearMetric, "measurables", []);
    const calculateTotal = _.get(oneYearMetric, "calculateTotal", false);

    return getQuarterMetricTotal(measurables, calculateTotal);
  };

  const openPrefilledAddMetricDialog = () => {
    handleOpenAddMetricDialog({
      definedPlan: _.get(oneYearPlanData, "plan.id"),
      definedUsers: form.users,
      definedUnit: form.unit,
      definedValue: form.value,
      definedCategory: "quarter",
    });
  };

  const handleComparator = (event, newComparator) => {
    // enforce value set
    if (newComparator !== null) {
      handleChangeManual({ name: "comparator", value: newComparator });
    }
  };

  const handleCreateQuarterMetric = () => {
    const plans = _.get(data, "plans", []);
    const threeYearPlan = _.find(plans, { id: form.plan });

    if (oneYearPlanData) {
      openPrefilledAddMetricDialog();
    } else {
      getOneYearPlan({
        variables: {
          organization: params.org,
          sharedPlanId: _.get(threeYearPlan, "sharedPlanId"),
        },
      });

      setOpenAddMetricDialog(true);
    }
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    const {
      value,
      users,
      unit,
      comparator,
      measurables,
      cumulative,
      calculateTotal,
      plan,
      objectives,
      enableFormula,
      formula,
      formulaScope,
    } = form;

    // 5th value is the total for all quarters
    if (category === "1 year" && cumulative) {
      measurables[4][1].value = measurables[3][1].value.toString(); //Year total is the same as the 4th quarter total

      // measurables[4][2].value = measurables.reduce((val, arr) => arr[2].value || val, null);
      measurables[4][2].value = "";
    }

    measurables.forEach((m) => {
      m[1].value = removeFormattedNum(m[1].value);
      m[2].value = removeFormattedNum(m[2].value);

      m.forEach((obj) => {
        obj.notes = (obj.notes || []).map((note) => note.id);
      });
    });

    const ok = await updateMetric({
      variables: {
        id,
        value,
        users,
        plan,
        objectives,
        unit,
        comparator,
        measurables,
        cumulative,
        calculateTotal,
        enableFormula,
        formula,
        formulaScope,
      },
    });

    if (ok) {
      snack(`Updated "${value}" metric`);
      handleClose();
    }
  };

  useEffect(() => {
    if (oneYearPlanData && openAddMetricDialog) {
      setOpenAddMetricDialog(false);
      openPrefilledAddMetricDialog();
    }
  }, [oneYearPlanData]);

  useEffect(() => {
    if (oneYearPlansRefetch) {
      const plans = _.get(data, "plans", []);
      const threeYearPlan = _.find(plans, { id: form.plan });

      oneYearPlansRefetch({
        organization: params.org,
        sharedPlanId: _.get(threeYearPlan, "sharedPlanId"),
      });
    }
  }, [form.plan]);

  useEffect(() => {
    resetForm(initForm);
  }, [id]);

  useEffect(() => {
    refetch();
  }, [fetch]);

  useEffect(() => {
    if (form.calculateTotal) {
      updateTotal(1)(form);
    }
  }, [form.calculateTotal]);

  if (dialogDataLoading) return null;

  const isOneYear = category === "1 year";
  const numOfvariables = _.get(form, "formulaScope.length");
  const currentVariable = _.get(form, `formulaScope.${!_.isNil(formulaScopeIdx) ? formulaScopeIdx : numOfvariables}`, {
    varType: "reference",
    value: null,
  });

  return (
    <>
      <Dialog
        open={open}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            handleClose();
          }
        }}
      >
        <DialogTitle>Edit Metric</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            label="Metric Details"
            name="value"
            fullWidth
            multiline
            variant="outlined"
            value={form.value || ""}
            onChange={handleChange}
            helperText={formErrors.value}
            error={Boolean(formErrors.value)}
          />
          <SelectDepartment
            plans={_.get(data, "plans")}
            name="plan"
            handleChange={handleChange}
            value={form.plan}
            helperText="Which department does this metric belong to?"
          />
          {!isOneYear && (
            <SelectObjectives
              name="objectives"
              objectives={_.get(data, "objectives", null)}
              handleChange={handleChange}
              values={form.objectives}
              category={"1 year"}
              multiple
              helperText="Which 3 year objectives are tied to this metric?"
              plansOrder={plansOrder}
            />
          )}
          {isOneYear && (
            <>
              <ToggleButtonGroup
                exclusive
                value={form.cumulative}
                onChange={handleChangeToggleButton("cumulative")}
                className={styles.flexCenter}
              >
                <ToggleButton value={false}>
                  <Tooltip title="Each entry is the total from that quarter">
                    <div>Add values</div>
                  </Tooltip>
                </ToggleButton>
                <ToggleButton value={true}>
                  <Tooltip title="Each entry includes the totals of previous quarters">
                    <div>Don't add values</div>
                  </Tooltip>
                </ToggleButton>
              </ToggleButtonGroup>
              {!form.cumulative && (
                <Typography align="center">
                  <Button
                    variant={form.calculateTotal ? "contained" : "outlined"}
                    onClick={handleChangeAuto}
                    color="primary"
                    className={styles.auto}
                    disableElevation
                  >
                    Auto Totals
                  </Button>
                </Typography>
              )}
            </>
          )}
          <SelectUsers
            name="users"
            users={_.get(data, "users")}
            handleChange={handleChange}
            values={form.users}
            helperText="Who is accountable for this metric?"
            plan={_.get(form, "plan")}
            allPlans={_.get(data, "plans")}
          />
          <div className={styles.modelButtons}>
            <ToggleButtonGroup exclusive value={form.comparator} onChange={handleComparator}>
              <ToggleButton value="lt">Less Than</ToggleButton>
              <ToggleButton value="lte">Less Than or Equal</ToggleButton>
              <ToggleButton value="eq">Equal To</ToggleButton>
              <ToggleButton value="gte">Greater Than or Equal</ToggleButton>
              <ToggleButton value="gt">Greater Than</ToggleButton>
            </ToggleButtonGroup>
          </div>
          <FormControlLabel
            control={<Switch size="small" name="enableFormula" checked={!!form.enableFormula} onChange={handleToggleCheckBox} />}
            label="Enable Formula"
            style={{ marginLeft: -7 }}
          />
          {form.enableFormula && (
            <div className={styles.formulaInput} style={{ marginBottom: 8 }}>
              <TextField
                placeholder="Formula (e.g. (v0 + 100)/v2)"
                name="formula"
                fullWidth
                margin="dense"
                variant="filled"
                value={form.formula}
                onChange={handleChange}
                helperText={formErrors.formula}
                error={Boolean(formErrors.formula)}
                InputProps={{
                  endAdornment: (
                    <>
                      <Tooltip title="Add Variable">
                        <IconButton onClick={() => setVariableDialogOpen(true)}>
                          <AddCircleIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Help">
                        <IconButton onClick={() => setSyntaxDialogOpen(true)}>
                          <HelpIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  ),
                }}
              />
              <div>
                {form.formulaScope.map((scope, idx) => {
                  const { varType, value: scopeValue } = scope;
                  const displayName = varType === "number" ? scopeValue : _.get(metricsById, `${scopeValue}.value`, "");
                  return (
                    <Chip
                      key={idx}
                      label={
                        <span>
                          <span style={{ fontWeight: "bold" }}>{`v${idx}: `}</span>
                          {`${displayName}`}
                        </span>
                      }
                      variant="outlined"
                      size="small"
                      style={{ marginRight: "4px" }}
                      onDelete={() => {
                        const newScope = form.formulaScope.filter((scope, index) => index !== idx);
                        handleChange({
                          target: {
                            name: "formulaScope",
                            value: newScope,
                          },
                        });
                      }}
                      onClick={() => {
                        setVariableDialogOpen(true);
                        setFormulaScopeIdx(idx);
                      }}
                    />
                  );
                })}
              </div>
              <VariableDialog
                open={variableDialogOpen}
                handleClose={() => {
                  setVariableDialogOpen(false);
                  setFormulaScopeIdx(null);
                }}
                handleChangeVariable={(value) =>
                  handleChange({
                    target: {
                      name: `formulaScope.${!_.isNil(formulaScopeIdx) ? formulaScopeIdx : numOfvariables}`,
                      value,
                    },
                  })
                }
                formulaScope={currentVariable}
                list={metrics}
                listName={"Metric"}
                createMode={_.isNil(formulaScopeIdx)}
                currentId={id}
              />
              <SyntaxDialog open={syntaxDialogOpen} handleClose={() => setSyntaxDialogOpen(false)} />
            </div>
          )}
          {form.measurables.map(([name, projected, actual], i) => {
            const isTotal = name.value === "Total";
            const year = _.get(YEARS, name.value, parseInt(name.value));
            const showLinkBtn = year <= getYear(yearOne);
            const reference = _.get(actual, "reference");
            const hasReference = !_.isNil(reference);

            if (isTotal && form.cumulative) return null;
            return (
              <div className={styles.flex} key={i}>
                <TextField
                  label={`${name.value} Projected Value`}
                  name={`measurables[${i}][1].value`}
                  variant="outlined"
                  value={form.enableFormula ? "TBD" : projected.value}
                  onChange={handleChangeCallback(updateTotal(1))}
                  margin="normal"
                  className={styles.textFields}
                  disabled={(isTotal && form.calculateTotal) || form.enableFormula}
                  helperText={_.get(formErrors, `measurables[${i}][1].value`)}
                  error={Boolean(_.get(formErrors, `measurables[${i}][1].value`))}
                />
                <TextField
                  label={`${name.value} Actual Value`}
                  name={`measurables[${i}][2].value`}
                  variant="outlined"
                  value={hasReference ? getOneYearMetricTotal(reference) : form.enableFormula ? "TBD" : actual.value}
                  onChange={handleChangeCallback(updateTotal(2))}
                  margin="normal"
                  className={styles.textFields}
                  disabled={(isTotal && form.calculateTotal) || hasReference || form.enableFormula}
                  helperText={_.get(formErrors, `measurables[${i}][2].value`)}
                  error={Boolean(_.get(formErrors, `measurables[${i}][2].value`))}
                />
                <TextField
                  label="Unit"
                  name="unit"
                  variant="outlined"
                  margin="normal"
                  value={form.unit || ""}
                  onChange={handleChange}
                  className={styles.unitField}
                  disabled={isTotal && form.calculateTotal}
                />
                {!isOneYear && (
                  <Tooltip title="Link 1 year metric">
                    <IconButton
                      onClick={handleOpenOneYearMetrics(year, i)}
                      style={{ visibility: showLinkBtn ? "visible" : "hidden" }}
                      disabled={!showLinkBtn}
                    >
                      <Icon path={mdiLinkVariant} size={1.25} color={hasReference ? deepPurple[400] : blueGrey[400]} />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button color="primary" onClick={handleSubmit} variant="contained">
            Save
          </Button>
        </DialogActions>
      </Dialog>
      {oneYearMetricsListOpen && (
        <OneYearMetricsList
          open={oneYearMetricsListOpen}
          onClose={() => setOneYearMetricsListOpen(false)}
          metrics={_.get(data, "metrics", [])}
          selectedYear={selectedYear}
          closed={selectedYear < getYear(yearOne)}
          onSubmit={handleLinkOneYearMetric}
          formMetric={_.get(form, ["measurables", selectedMeasurableIdx.toString(), "2", "reference"])}
          plansOrder={plansOrder}
          handleCreateQuarterMetric={handleCreateQuarterMetric}
        />
      )}
    </>
  );
};

function OneYearMetricsList({ open, onClose, metrics, selectedYear, closed, onSubmit, formMetric, plansOrder, handleCreateQuarterMetric }) {
  const [selectedMetric, setSelectedMetric] = useState(formMetric ? formMetric : null);
  const [metricsForSelYear, setMetricsForSelYear] = useState([]);

  const metricsByYear = useMemo(() => {
    const corpMetrics = metrics.filter((metric) => _.get(metric, "plan.departmentName") === "Corporate");
    const nonCorpMetrics = _.difference(metrics, corpMetrics);

    const metricsByYear1 = _.groupBy(corpMetrics, "plan.year");
    const metricsByYear2 = _.groupBy(nonCorpMetrics, "plan.plan.year");

    return _(metricsByYear1)
      .mergeWith(metricsByYear2, mergeCustomizer)
      .map((metrics, year) => ({ year: getYear(parseInt(year)), metrics }))
      .value();
  }, [metrics]);

  useEffect(() => {
    if (metricsByYear) {
      const mtrcsForSelYear = _.get(_.find(metricsByYear, ["year", selectedYear]), "metrics", []);
      const sortedMtrcsForSelYear = _.sortBy(mtrcsForSelYear, [
        function (mtrc) {
          // this preliminary sorting step is required if the list of metrics includes multiple plans
          return plansOrder.indexOf(_.get(mtrc, "plan.sharedPlanId"));
        },
        "number",
      ]);

      setMetricsForSelYear(sortedMtrcsForSelYear);
    }
  }, [metricsByYear, selectedYear, plansOrder]);

  const handleListItemClick = (id) => () => {
    setSelectedMetric((prev) => (id === prev ? null : id));
  };

  const handleSubmit = () => {
    onSubmit(selectedMetric);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        {closed && `Closed `}1 Year Metrics ({selectedYear})
      </DialogTitle>
      <DialogContent>
        <List>
          {_.isEmpty(metricsForSelYear) ? (
            <Typography>No metrics for this year</Typography>
          ) : (
            metricsForSelYear.map(({ id, value, number, plan }, idx) => (
              <ListItem button selected={selectedMetric === id} onClick={handleListItemClick(id)} className={styles.metricListItem}>
                <ListItemText>
                  {!_.isNil(plan?.departmentName) ? <PlanPill plan={plan} /> : <PlanPill plan={null} />}
                  {number}. {value}
                </ListItemText>
              </ListItem>
            ))
          )}
        </List>
      </DialogContent>
      <DialogActions>
        {!closed && (
          <Button onClick={handleCreateQuarterMetric} color="primary" style={{ marginRight: "auto" }}>
            Create
          </Button>
        )}
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default EditDialog;

const UPDATE_METRIC = gql`
  ${METRIC_FIELDS}
  mutation MetricsEditDialog_UpdateMetric(
    $id: ID!
    $value: String
    $unit: String
    $users: [ID!]
    $plan: ID
    $objectives: [ID!]
    $comparator: String
    $measurables: [[MetricMeasurableInput]]
    $cumulative: Boolean
    $calculateTotal: Boolean
    $enableFormula: Boolean
    $formula: String
    $formulaScope: [FormulaVariableInput!]
  ) {
    updateMetric(
      id: $id
      value: $value
      unit: $unit
      comparator: $comparator
      measurables: $measurables
      users: $users
      plan: $plan
      objectives: $objectives
      cumulative: $cumulative
      calculateTotal: $calculateTotal
      enableFormula: $enableFormula
      formula: $formula
      formulaScope: $formulaScope
    ) {
      metric {
        ...MetricFields
      }
      metrics {
        id
        number
      }
      plans {
        id
        metrics
      }
      objectives {
        id
        metrics
      }
    }
  }
`;

const GET_ONE_YEAR_PLAN = gql`
  query MetricsEditDialog_GetOneYearPlan($organization: ID!, $sharedPlanId: ID) {
    plan(organization: $organization, sharedPlanId: $sharedPlanId, category: "1 year", closed: false) {
      id
    }
  }
`;

const GET_DIALOG_DATA = gql`
  query MetricsEditDialog_GetDialogData($organization: ID!, $category: String, $sharedPlanId: ID, $oneYearCorpPlan: ID, $closed: Boolean) {
    users(organization: $organization) {
      name {
        first
        last
      }
      profilePicture
      id
      plan {
        id
        departmentName
        sharedPlanId
      }
    }

    plans(organization: $organization, category: $category, oneYearCorpPlan: $oneYearCorpPlan, closed: $closed) {
      id
      departmentName
      sharedPlanId
    }

    objectives(organization: $organization, archived: false, category: "3 year", sharedPlanId: $sharedPlanId) {
      id
      value
      category
      number
      plan {
        id
        departmentName
        color
        shortName
        sharedPlanId
      }
    }

    metrics(organization: $organization, category: "quarter", sharedPlanId: $sharedPlanId) {
      id
      value
      number
      measurables {
        value
        reference {
          id
        }
      }
      plan {
        id
        departmentName
        sharedPlanId
        year
        plan {
          id
          year
        }
        color
        shortName
      }
    }
  }
`;
