import React, { useState } from "react";
import { IconButton, Button, Menu as MaterialUiMenu } from "@material-ui/core";
import Icon from "@mdi/react";
import { mdiDotsHorizontal, mdiChevronDown, mdiPencil } from "@mdi/js";

const Menu = ({ icon, button, color = "rgba(0, 0, 0, 0.54)", children, className }) => {
  const [menuOpen, setMenuOpen] = useState(null);

  let path;

  switch (icon) {
    case "arrow":
      path = mdiChevronDown;
      break;
    case "pencil":
      path = mdiPencil;
      break;
    default:
      path = mdiDotsHorizontal;
      break;
  }

  const handleMenuOpen = (open) => (e) => {
    e.stopPropagation();
    if (open) {
      setMenuOpen(e.currentTarget);
    } else {
      setMenuOpen(null);
    }
  };

  return (
    <>
      <div className={className}>
        {button ? (
          <Button onClick={handleMenuOpen(true)}>{button}</Button>
        ) : (
          <IconButton onClick={handleMenuOpen(true)} size="small">
            <Icon path={path} size={0.75} color={color} />
          </IconButton>
        )}
      </div>

      <MaterialUiMenu anchorEl={menuOpen} open={Boolean(menuOpen)} onClose={handleMenuOpen(false)}>
        {children}
      </MaterialUiMenu>
    </>
  );
};

export default Menu;
