import React, { useState, useEffect, useContext, useMemo } from "react";
import styles from "./OneYear.module.scss";
import _ from "lodash";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import gql from "graphql-tag";
import {
  setYear,
  fullDate,
  fiscalYearStart,
  getYear,
  isBetween,
  after,
  before,
  getCurrentQuarter,
  getNextPlanFiscalYear,
  year,
} from "../../utils/dates";
import { useQueryParam, StringParam } from "use-query-params";
import { isAuthed } from "../../utils/authorization";

import { FetchContext } from "../../context/fetchContext";
import { SnackbarContext } from "../../context/snackbarContext";
// import { LoadingContext } from "../../context/loadingContext";

import {
  Container,
  Grid,
  Button,
  Typography,
  IconButton,
  MenuItem,
  Tooltip,
  Backdrop,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
} from "@material-ui/core";
import { Edit as EditIcon, Lock, LockOpen } from "@material-ui/icons";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

import EditDialog from "./EditDialog";
import CloseYearDialog from "./CloseYearDialog";
import CreateYearDialog from "./CreateYearDialog";
import Menu from "../../components/Menu/Menu";
import Loading from "../../components/Loading/Loading";
import Objectives from "../../components/Objectives/Objectives";
import Metrics from "../../components/Metrics/Metrics";
import NotesButton from "../../components/Notes/NotesButton";
import CloseQuarterDialog from "./CloseQuarterDialog";
import QuarterSummary from "../../components/QuarterSummary/QuarterSummary";
import { addYears } from "date-fns";
import Icon from "@mdi/react";
import { mdiAlert } from "@mdi/js";
import { red } from "@material-ui/core/colors";
import { GET_METRICS, GET_OBJECTIVES } from "../../utils/query";

const OneYear = ({ user, params, org }) => {
  const isDepartmentAdmin = isAuthed(user.user, "department admin");
  const isCompanyAdmin = isAuthed(user.user, "company admin");
  const fiscalYear = org.fiscalYear;
  const { fetch, requestFetch } = useContext(FetchContext);
  const { snack } = useContext(SnackbarContext);
  // const { updateLoading } = useContext(LoadingContext);

  const [editDialog, setEditDialog] = useState(false);
  // const [closeYearDialog, setCloseYearDialog] = useState(false);
  const [createDialog, setCreateDialog] = useState(false);
  const [closeQuarterDialog, setCloseQuarterDialog] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [corpForSelectedYear, setCorpForSelectedYear] = useState({});
  const [loadedFirstTime, setLoadedFirstTime] = useState(false);

  const [quarterForReview, setQuarterForReview] = useState();
  const [plansForReview, setPlansForReview] = useState([]);
  const [highestReviewedQuarter, setHighestReviewedQuarter] = useState();
  // const [plansNeedingReview, setPlansNeedingReview] = useState([]);
  const [tab, setTab] = useState("objectives");

  const handleChangeTab = (e, value) => {
    if (!_.isNil(value)) {
      setTab(value);
    }
  };

  const {
    loading: oneYear_corpPlansOrgLoading,
    data: corpPlansOrgData,
    refetch: corpPlansOrgRefetch,
  } = useQuery(GET_CORP_PLANS_ORG, {
    variables: {
      organization: params.org,
    },
  });

  const [getOneYearPlans, { called, loading: oneYear_plansLoading, data, refetch: refetchOneYearPlans }] = useLazyQuery(GET_ONE_YEAR_PLANS, {
    fetchPolicy: 'network-only'
  });
  const [getObjectives, { data: objectivesData, loading: objectivesLoading, refetch: refetchObjectives }] = useLazyQuery(GET_OBJECTIVES);
  const [getMetrics, { data: metricsData, loading: metricsLoading, refetch: refetchMetrics }] = useLazyQuery(GET_METRICS);

  const [togglePlanLock] = useMutation(TOGGLE_PLAN_LOCK);

  const handleTogglePlanLock = async () => {
    const locked = _.get(corpForSelectedYear, "locked");
    const ok = await togglePlanLock({
      variables: { corporatePlanId: _.get(corpForSelectedYear, "id") },
    });

    if (ok.data.togglePlanLock) {
      snack(`Year has been ${locked ? "un" : ""}locked`);
      requestFetch();
    }
  };

  const handleEditDialog = (open) => () => {
    setEditDialog(open);
  };

  // const handleCloseYearDialog = (open) => () => {
  //   setCloseYearDialog(open);
  // };

  const handleCreateYearDialog = (open) => () => {
    setCreateDialog(open);
  };

  const handleCloseQuarterDialog = (open) => () => {
    setCloseQuarterDialog(open);
  };

  const getYearRange = (fiscalYear, planYear) => {
    return `${fullDate(fiscalYearStart(setYear(fiscalYear, getYear(planYear))))} - ${fullDate(setYear(fiscalYear, getYear(planYear)))}`;
  };

  const initForm = useMemo(
    () => ({
      departmentName: _.get(selectedPlan, "departmentName"),
      useDepartmentTheme: _.get(selectedPlan, "useDepartmentTheme"),
      theme: _.get(selectedPlan, "theme"),
      color: _.get(selectedPlan, "color"),
      shortName: _.get(selectedPlan, "shortName"),
    }),
    [selectedPlan]
  );

  // useEffect(() => {
  //   updateLoading({
  //     loadingObj: {
  //       ...(!_.isNil(oneYear_corpPlansLoading) && { oneYear_corpPlansLoading }),
  //       ...(!_.isNil(oneYear_plansLoading) && { oneYear_plansLoading }),
  //     },
  //   });
  // }, [oneYear_corpPlansLoading, oneYear_plansLoading]);

  useEffect(() => {
    if (corpPlansOrgData) {
      const corporatePlans = _.get(corpPlansOrgData, "corporatePlans", []);
      let corpPlan;
      if (_.isEmpty(corpForSelectedYear)) {
        corpPlan = _.find(corporatePlans, ({ year }) => fiscalYear === year);
      } else {
        corpPlan = _.find(corporatePlans, ({ id }) => id === _.get(corpForSelectedYear, "id"));
      }
      setCorpForSelectedYear(corpPlan);
    }
  }, [corpPlansOrgData]);

  useEffect(() => {
    if (!_.isEmpty(corpForSelectedYear)) {
      // gets one year plan for selected year
      getOneYearPlans({
        variables: {
          organization: params.org,
          id: corpForSelectedYear.id,
          sharedPlanId: user.departmentFilter.sharedPlanId,
        },
      });
      getObjectives({
        variables: {
          organization: params.org,
          planId: corpForSelectedYear.id,
          sharedPlanId: user.departmentFilter.sharedPlanId,
        },
      });
      getMetrics({
        variables: {
          organization: params.org,
          planId: corpForSelectedYear.id,
          sharedPlanId: user.departmentFilter.sharedPlanId,
        },
      });
    }
  }, [user.departmentFilter.sharedPlanId, corpForSelectedYear]);

  useEffect(() => {
    corpPlansOrgRefetch();
    if (refetchOneYearPlans) {
      refetchOneYearPlans();
    }
    if (refetchMetrics) {
      refetchMetrics();
    }
    if (refetchObjectives) {
      refetchObjectives();
    }
  }, [fetch]);

  useEffect(() => {
    if (data) {
      let selPlan;
      if (_.isNil(user.departmentFilter.id)) {
        selPlan = _.pick(corpForSelectedYear, ["id", "theme", "year", "notes", "color", "shortName"]);
      } else {
        selPlan = _.pick(_.first(data.plans), [
          "id",
          "theme",
          "year",
          "departmentName",
          "useDepartmentTheme",
          "notes",
          "color",
          "shortName",
        ]);
      }
      setSelectedPlan(selPlan);

      let minUnreviewedQuarter,
        unreviewedPlansForMinQuarter = [],
        maxReviewedQuarter;

      _.get(data, "plans", []).forEach((plan) => {
        const { periodData } = plan;

        periodData.forEach(({ periodNumber, reviewed }) => {
          if (reviewed) {
            if (_.isNil(maxReviewedQuarter) || periodNumber > maxReviewedQuarter) {
              maxReviewedQuarter = periodNumber;
            }
          } else {
            if (_.isNil(minUnreviewedQuarter) || periodNumber < minUnreviewedQuarter) {
              minUnreviewedQuarter = periodNumber;
              unreviewedPlansForMinQuarter = [plan];
            } else if (periodNumber === minUnreviewedQuarter) {
              unreviewedPlansForMinQuarter.push(plan);
            }
          }
        });
      });

      // // for company admin or higher, this will actually return all plans/departments instead of just plans/departments assigned to user
      // const allUnreviewedPlans = _.get(data, "allPlans", []).filter(({ periodData }) => _.findIndex(periodData, { reviewed: false }) > -1);
      // setPlansNeedingReview(allUnreviewedPlans);

      setQuarterForReview(minUnreviewedQuarter);
      setPlansForReview(unreviewedPlansForMinQuarter);
      setHighestReviewedQuarter(maxReviewedQuarter);

      setLoadedFirstTime(true);
    }
  }, [data, corpForSelectedYear]);

  if ((oneYear_plansLoading || objectivesLoading || metricsLoading)) {
    return <Loading />;
  }

  const plansOrder = _.get(corpPlansOrgData, "organization.plansOrder", []);

  const nextPlanYearStart = `${year(fiscalYearStart(getNextPlanFiscalYear(fiscalYear)))}`;
  const nextPlanYearEnd = `${year(getNextPlanFiscalYear(fiscalYear))}`;
  const nextPlanYearRange = nextPlanYearStart === nextPlanYearEnd ? nextPlanYearStart : `${nextPlanYearStart} - ${nextPlanYearEnd}`

  const plans = _.get(data, "plans", []);
  const corpPlans = _.get(corpPlansOrgData, "corporatePlans", []);
  // const openCorpPlans = _.find(corpPlans, ["closed", false]);
  const currentCorpPlan = _.find(corpPlans, (plan) => plan.year === fiscalYear);
  const nextYearPlanCreated = corpPlans.some((plan) => parseInt(plan.year) > parseInt(fiscalYear));
  const currentQuarter = getCurrentQuarter(corpForSelectedYear.year);
  const overdue = quarterForReview && currentQuarter > quarterForReview;
  const isFuturePlan = parseInt(corpForSelectedYear.year) > parseInt(fiscalYear);
  return (
    <>
      <Container maxWidth={false}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className={styles.flexBetween}>
              <div className={styles.container}>
                <div>
                  <div className={styles.flex}>
                    <Typography variant="h5">
                      <span className={styles.label}>Theme: </span>
                      {selectedPlan.useDepartmentTheme ? selectedPlan.theme : corpForSelectedYear.theme}
                    </Typography>

                    {selectedPlan.id && <NotesButton id={selectedPlan.id} model="plan" doc={selectedPlan} tabs={["notes"]} />}
                    {isDepartmentAdmin && (
                      <IconButton onClick={handleEditDialog(true)} size="small" disabled={corpForSelectedYear.closed}>
                        <EditIcon fontSize="inherit" />
                      </IconButton>
                    )}
                  </div>

                  <div className={styles.flex}>
                    <Typography variant="subtitle1">
                      <div className={`${styles.label} ${styles.flex}`}>
                        {corpForSelectedYear.year && (
                          <>
                            <span>Year: {getYearRange(fiscalYear, corpForSelectedYear.year)}</span>
                            {isDepartmentAdmin && (
                              <>
                                {corpForSelectedYear.locked ? (
                                  <Tooltip title="Unlock year">
                                    <IconButton onClick={handleTogglePlanLock} size="small">
                                      <Lock fontSize="inherit" />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <Tooltip title="Lock year">
                                    <IconButton onClick={handleTogglePlanLock} size="small">
                                      <LockOpen fontSize="inherit" />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </Typography>

                    <Menu icon="arrow">
                      {_.sortBy(corpPlans, (p) => parseInt(-p.year)).map((plan) => (
                        <MenuItem
                          key={plan.id}
                          onClick={() => setCorpForSelectedYear(plan)}
                          disabled={plan.id === corpForSelectedYear.id}
                          selected={plan.id === corpForSelectedYear.id}
                        >
                          {getYearRange(fiscalYear, plan.year)} {plan.locked && <Lock fontSize="inherit" />}
                        </MenuItem>
                      ))}
                    </Menu>
                  </div>
                </div>
              </div>
              <ToggleButtonGroup exclusive value={tab} onChange={handleChangeTab} style={{ margin: "0 16px" }}>
                <ToggleButton value="objectives">
                  <div>Objectives</div>
                </ToggleButton>
                <ToggleButton value="metrics">
                  <div>Metrics</div>
                </ToggleButton>
                <ToggleButton value="summary">
                  <div>Summary</div>
                </ToggleButton>
              </ToggleButtonGroup>
              <div className={styles.flexCenter}>
                {isCompanyAdmin && (
                  <>
                    <Tooltip
                      title={`${_.isEmpty(plansForReview) ? "All quarters for the selected department(s) have been reviewed." : ""}${!_.isNil(user.departmentFilter.sharedPlanId) ? " All departments must be selected" : ""
                        }`}
                    >
                      <div>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={handleCloseQuarterDialog(true)}
                          disabled={_.isEmpty(plansForReview) || !_.isNil(user.departmentFilter.sharedPlanId) || isFuturePlan}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          {overdue && !isFuturePlan && (
                            <Tooltip title={`Quarter is past due`}>
                              <Icon path={mdiAlert} color={red[400]} size={0.75} />
                            </Tooltip>
                          )}
                          Close Out {_.isNil(quarterForReview) ? "Quarter" : `Q${quarterForReview}`}
                        </Button>
                      </div>
                    </Tooltip>
                    {/* <Tooltip title={corpForSelectedYear.closed ? "Please navigate to the current year" : ""}>
                      <div>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={handleCloseYearDialog(true)}
                          disabled={corpForSelectedYear.closed}
                        >
                          Close Year
                        </Button>
                      </div>
                    </Tooltip> */}
                    <Tooltip title={nextYearPlanCreated ? "Next Year Already Created" : "Create next year plan"}>
                      <div>
                        <Button color="primary" variant="contained" onClick={handleCreateYearDialog(true)} disabled={nextYearPlanCreated}>
                          Create {nextPlanYearRange} Plan
                        </Button>
                      </div>
                    </Tooltip>
                  </>
                )}
              </div>
            </div>
          </Grid>
          <Grid item xs={12}></Grid>
          {tab === "objectives" && (
            <Grid item xs={12}>
              <Objectives
                objectives={_.get(objectivesData, "objectives", [])}
                fiscalYear={fiscalYear}
                plansOrder={plansOrder}
                category="1 year"
                corpForSelectedYear={corpForSelectedYear}
                currentQuarter={currentQuarter}
                variables={{
                  organization: params.org,
                  planId: corpForSelectedYear.id,
                  sharedPlanId: user.departmentFilter.sharedPlanId,
                }}
              />
            </Grid>
          )}
          {tab === "metrics" && (
            <Grid item xs={12}>
              <Metrics
                metrics={_.get(metricsData, "metrics", [])}
                fiscalYear={fiscalYear}
                plansOrder={plansOrder}
                category="1 year"
                closedYear={corpForSelectedYear.closed}
                locked={_.get(corpForSelectedYear, "locked", true)}
                planId={_.get(corpForSelectedYear, "id")}
                corpForSelectedYear={corpForSelectedYear}
                currentQuarter={currentQuarter}
                variables={{
                  organization: params.org,
                  planId: corpForSelectedYear.id,
                  sharedPlanId: user.departmentFilter.sharedPlanId,
                }}
              />
            </Grid>
          )}
          {tab === "summary" && (
            <Grid item xs={12}>
              <Typography variant="h6" style={{ marginBottom: 16, textAlign: "center", fontWeight: "bold", color: "#7e57c2" }}>
                Quarter Summary
              </Typography>
              <TableContainer className={styles.summaryYearTableContainer}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell colSpan={corpPlans.length + 2} align="center">
                        <Typography>3-Year Plan</Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      {_.sortBy(corpPlans, (p) => parseInt(p.year)).map(({ year }, idx) => {
                        const isCurrentYear = year === _.get(currentCorpPlan, "year");
                        return (
                          <TableCell align="center" className={isCurrentYear ? styles.currentYear : undefined}>
                            <Typography>
                              Year {idx + 1} {isCurrentYear && "[Current]"}
                            </Typography>
                            <Typography>{getYearRange(fiscalYear, year)}</Typography>
                          </TableCell>
                        );
                      })}
                      {corpPlans.length < 3 &&
                        [...Array(3 - corpPlans.length).keys()].map((val, idx) => {
                          const futureYearCount = corpPlans.length + idx + 1;
                          const futureYear = addYears(parseInt(_.get(currentCorpPlan, "year")), idx + 1);

                          return (
                            <TableCell align="center">
                              <Typography>Year {futureYearCount}</Typography>
                              <Typography>{getYearRange(fiscalYear, futureYear)}</Typography>
                            </TableCell>
                          );
                        })}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <QuarterSummary
                rocks={_.flatten(_.get(objectivesData, "objectives", []).map((obj) => _.get(obj, "rocks", [])))}
                fiscalYear={fiscalYear}
                closed={corpForSelectedYear.closed}
                highestReviewedQuarter={highestReviewedQuarter}
                plans={plans.map((plan) => _.pick(plan, ["id", "periodData", "color", "shortName", "departmentName"]))}
                currentQuarter={currentQuarter}
              />
            </Grid>
          )}
        </Grid>
      </Container>

      {selectedPlan.id && (
        <EditDialog open={editDialog} handleClose={handleEditDialog(false)} planId={selectedPlan.id} initForm={initForm} />
      )}

      {corpForSelectedYear.year && (
        <>
          {/* {closeYearDialog && (
            <CloseYearDialog
              open={closeYearDialog}
              handleClose={handleCloseYearDialog(false)}
              year={corpForSelectedYear.year}
              organizationId={params.org}
            />
          )} */}
          {createDialog && (
            <CreateYearDialog
              open={createDialog}
              handleClose={handleCreateYearDialog(false)}
              nextPlanYearStart={nextPlanYearStart}
              nextPlanYearRange={nextPlanYearRange}
              organizationId={params.org}
            />
          )}
          {closeQuarterDialog && (
            <CloseQuarterDialog
              open={closeQuarterDialog}
              handleClose={handleCloseQuarterDialog(false)}
              oneYearObjectives={_.get(objectivesData, "objectives", [])}
              oneYearMetrics={_.get(metricsData, "metrics", []).filter((metric) => !_.get(metric, "enableFormula"))}
              plansOrder={plansOrder}
              plansInReview={plansForReview}
              quarterInReview={quarterForReview}
              oneYearCorpPlan={_.get(corpForSelectedYear, "id")}
              organizationId={params.org}
              fiscalYear={fiscalYear}
              snack={snack}
            />
          )}
        </>
      )}
    </>
  );
};

export default OneYear;

const TOGGLE_PLAN_LOCK = gql`
  mutation OneYear_TogglePlanLock($corporatePlanId: ID!) {
    togglePlanLock(corporatePlanId: $corporatePlanId)
  }
`;

const GET_CORP_PLANS_ORG = gql`
  query OneYear_GetCorpPlans($organization: ID!) {
    corporatePlans: plans(organization: $organization, departmentName: "Corporate", category: "1 year") {
      id
      year
      closed
      locked
      theme
      color
      shortName
      notes {
        id
        date
      }
    }
    organization(id: $organization) {
      id
      fiscalYear
      plansOrder
    }
  }
`;

const GET_ONE_YEAR_PLANS = gql`
  query OneYear_GetOneYear($organization: ID!, $id: ID, $sharedPlanId: ID) {
    plans(organization: $organization, category: "1 year", oneYearCorpPlan: $id, sharedPlanId: $sharedPlanId) {
      id
      theme
      category
      useDepartmentTheme
      departmentName
      sharedPlanId
      targetDate
      year
      closed
      color
      shortName
      notes {
        id
        date
      }
      periodData {
        periodNumber
        reviewed
        periodGrade {
          topHit
          topMiss
          grade
        }
      }
      plan {
        id
        theme
      }
    }
  }
`;
