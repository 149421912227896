import React, { useState, useMemo, useEffect, useContext } from "react";
import _ from "lodash";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { isSameWeek, isBefore } from "date-fns";
import styles from "../WeeklyReview.module.scss";
import { Typography, Card, CardContent, TextField, IconButton, Grid, Dialog, DialogContent, DialogTitle } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Icon from "@mdi/react";
import {
  mdiCodeGreaterThan,
  mdiCodeLessThan,
  mdiEqualBox,
  mdiChevronLeft,
  mdiChevronRight,
  mdiCodeLessThanOrEqual,
  mdiCodeGreaterThanOrEqual,
  mdiCodeBrackets,
} from "@mdi/js";
import { FetchContext } from "../../../context/fetchContext";
import { SnackbarContext } from "../../../context/snackbarContext";
import WeeklyTargets from "../../../components/WeeklyTargets/WeeklyTargets";
import UserAvatar from "../../../components/UserAvatar/UserAvatar";
import { startOfWeek, endOfWeek, formatAs, after, subToDate } from "../../../utils/dates";

const WeeklyMetrics = ({ weeklyTargets, fiscalYear }) => {
  const { requestFetch } = useContext(FetchContext);
  const { snack } = useContext(SnackbarContext);
  const [selectedKPI, setSelectedKPI] = useState([]);
  const [weekTab, setWeekTab] = useState(4);
  const [values, setValues] = useState({});
  const [updateWeeklyTarget] = useMutation(UPDATE_WEEKLY_TARGET);

  const handleChange = (e) => {
    const { name } = e.target;
    const value = _.toNumber(e.target.value);
    if (isNaN(value)) return;
    setValues({ ...values, [name]: { ...values[name], value } });
  };

  const handleEnlarge = (id) => () => {
    setSelectedKPI(id);
  };

  const handleWeekTab = (inc) => () => {
    setWeekTab(weekTab + inc);
  };

  const handleUpdate = (id, value) => async () => {
    const measureableInput = Object.keys(values).map((key) => {
      return { week: new Date(key), value: _.toNumber(values[key].value), notes: values[key].notes };
    });

    const ok = await updateWeeklyTarget({ variables: { id, measurables: measureableInput } });
    if (ok.data.updateWeeklyTarget) {
      snack(`Updated "${value}" KPI`);
      // requestFetch();
    }
  };

  const getUnit = (kpi) => {
    if (!kpi.target) return null;
    if (!kpi.unit) return Number(kpi.target).toLocaleString();
    if (kpi.unit === "$") return `${kpi.unit}${kpi.target}`;
    return `${Number(kpi.target).toLocaleString()} ${kpi.unit}`;
  };

  const weekHeaders = useMemo(() => {
    let week = startOfWeek(new Date());
    const end = subToDate(week, { weeks: 13 });
    const arr = [];

    while (after(week, end)) {
      arr.push(week);
      week = subToDate(week, { weeks: 1 });
    }

    return arr.reverse();
  }, []);

  const highlightedKPI = useMemo(() => {
    if (selectedKPI) {
      return _.find(weeklyTargets, (wt) => wt.id === selectedKPI);
    }
    return {};
  }, [selectedKPI]);

  useEffect(() => {
    if (!_.isEmpty(highlightedKPI) && !_.isEmpty(highlightedKPI.measurables)) {
      setValues(
        highlightedKPI.measurables.reduce((o, i) => {
          o[new Date(i["week"])] = i.value;
          return o;
        }, {})
      );
    }
  }, [highlightedKPI]);

  const today = new Date();

  return (
    <div>
      <WeeklyTargets weeklyTargets={weeklyTargets} fiscalYear={fiscalYear} handleEnlarge={handleEnlarge} checked={selectedKPI} />

      <div className={styles.issueCardContainer}>
        {!_.isEmpty(highlightedKPI) && (
          <Dialog
            open={!_.isEmpty(highlightedKPI)}
            onClose={(event, reason) => {
              if (reason !== "backdropClick") {
                handleEnlarge()();
              }
            }}
            fullWidth={true}
            maxWidth="md"
          >
            <Card>
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <div className={styles.flexSpaceBetween}>
                      <Typography variant="h4">
                        {highlightedKPI.value} {highlightedKPI.unit && `(${highlightedKPI.unit})`}
                      </Typography>
                      <IconButton onClick={handleEnlarge()}>
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="h6" className={styles.label}>
                      Target
                    </Typography>
                    <Typography variant="h5" paragraph className={styles.flex}>
                      <Icon
                        path={accuracyIcon[highlightedKPI.accuracy]}
                        size={1}
                        color="rgba(0, 0, 0, 0.54)"
                        className={styles.iconAccuracy}
                      />

                      {getUnit(highlightedKPI)}
                    </Typography>

                    <Typography variant="h6" className={styles.label}>
                      Accountable
                    </Typography>
                    <div className={styles.parapgraph}>
                      <UserAvatar user={highlightedKPI.user} variant="h5" />
                    </div>
                  </Grid>
                  <Grid item xs={1}>
                    {weekTab !== 0 && (
                      <IconButton onClick={handleWeekTab(-1)}>
                        <Icon path={mdiChevronLeft} size={1.5} color="rgba(0, 0, 0, 0.54)" />
                      </IconButton>
                    )}
                  </Grid>
                  <Grid item xs={7}>
                    <Grid container spacing={3}>
                      {weekHeaders.slice(weekTab * 3, (weekTab + 1) * 3).map((week) => {
                        const isPast = isBefore(week, today);
                        const isCurrent = isSameWeek(week, today);

                        if (!isCurrent && !isPast) return null;

                        let aboveTarget = false;
                        switch (highlightedKPI.accuracy) {
                          case "lt":
                            aboveTarget = parseFloat(values[week]) < parseFloat(highlightedKPI.target);
                            break;
                          case "eq":
                            aboveTarget = parseFloat(values[week]) === parseFloat(highlightedKPI.target);
                            break;
                          case "gt":
                            aboveTarget = parseFloat(values[week]) > parseFloat(highlightedKPI.target);
                            break;
                          default:
                            break;
                        }
                        let formattedString = "";
                        if (values[week]) {
                          formattedString = parseFloat(values[week]).toLocaleString();
                        }

                        return (
                          <Grid item xs={4} key={week}>
                            <div>
                              <Typography variant="h6" align="center" paragraph>
                                {formatAs(startOfWeek(week), "MMM d")} - {formatAs(endOfWeek(week), "MMM d")}
                              </Typography>
                              <div className={styles.metricToggle}>
                                <TextField
                                  value={values[week] || ""}
                                  onChange={handleChange}
                                  name={week.toString()}
                                  className={aboveTarget ? styles.valueAbove : styles.valueBelow}
                                  id={week}
                                  onBlur={handleUpdate(highlightedKPI.id, highlightedKPI.value)}
                                />
                                <Typography align="center" variant="h5" component="label" htmlFor={week} className={styles.valueText}>
                                  {formattedString}
                                </Typography>
                              </div>{" "}
                            </div>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                  <Grid item xs={1}>
                    {weekTab !== 4 && (
                      <IconButton disabled={weekTab === 4} onClick={handleWeekTab(1)}>
                        <Icon path={mdiChevronRight} size={1.5} color="rgba(0, 0, 0, 0.54)" />
                      </IconButton>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Dialog>
        )}
      </div>
    </div>
  );
};

export default WeeklyMetrics;

const UPDATE_WEEKLY_TARGET = gql`
  mutation ($id: ID!, $measurables: [MeasurableInput!]) {
    updateWeeklyTarget(id: $id, measurables: $measurables) {
      weeklyTarget {
        id
        measurables {
          week
          value
        }
      }
    }
  }
`;

const accuracyIcon = {
  lt: mdiCodeLessThan,
  lte: mdiCodeLessThanOrEqual,
  gt: mdiCodeGreaterThan,
  gte: mdiCodeGreaterThanOrEqual,
  eq: mdiEqualBox,
  btw: mdiCodeBrackets,
};
