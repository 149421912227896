import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import { Droppable, Draggable } from "react-beautiful-dnd";
import styles from "./Rock.module.scss";
import { useMutation } from "@apollo/client";
import gql from "graphql-tag";
import { isAuthed } from "../../utils/authorization";
import { isDate, startOfToday } from "date-fns";

import { FetchContext } from "../../context/fetchContext";
import { SnackbarContext } from "../../context/snackbarContext";
import { DialogContext } from "../../context/dialogContext";
import { UserContext } from "../../context/userContext";

import Icon from "@mdi/react";
import { mdiCheckboxBlankCircle, mdiDecagram } from "@mdi/js";
import { green, red } from "@material-ui/core/colors";
import { List, ListItem, ListItemText, ListItemIcon, MenuItem, Collapse } from "@material-ui/core";

import Menu from "../Menu/Menu";
import NotesButton from "../Notes/NotesButton";
import EditDialog from "./EditDialog";
import ScEditDialog from "../SuccessCriteria/EditDialog";
import UserAvatars from "../UserAvatars/UserAvatars";
import ConfirmDeletionDialog from "../ConfirmDeletionDialog/ConfirmDeletionDialog";
import useConfirmDelete from "../../hooks/useConfirmDelete";
import PlanPill from "../PlanPill/PlanPill";
import { formatAs, before } from "../../utils/dates";

// Rock Widget
const Rock = ({ isDragging, objectiveId, canEdit, rock, rockPath, rockScStrategy, planId }) => {
  const { id, value, users, index, successCriterias, status, hide, plan } = rock;
  const { requestFetch } = useContext(FetchContext);
  const { user } = useContext(UserContext);
  const { snack } = useContext(SnackbarContext);
  const { dialog, setDialog } = useContext(DialogContext);

  const [deleteRock] = useMutation(DELETE_ROCK, {
    update(cache, { data: { deleteRock } }) {
      const { deletedRock, deletedSuccessCriterias } = deleteRock;
      const deletedRockId = cache.identify(deletedRock);
      const deletedScIds = deletedSuccessCriterias.map((sc) => cache.identify(sc));
      cache.modify({
        fields: {
          rocks: (existingRocks) => {
            return existingRocks.filter((rockRef) => {
              const rockId = cache.identify(rockRef);
              return rockId !== deletedRockId;
            });
          },
          successCriterias: (existingScs) => {
            return existingScs.filter((scRef) => {
              const scId = cache.identify(scRef);
              return !deletedScIds.includes(scId);
            });
          },
        },
      });
    },
  });

  const [updateRock] = useMutation(UPDATE_ROCK);
  const [editDialog, setEditDialog] = useState(false);
  const [scEditDialog, setScEditDialog] = useState({ id: null, initForm: { value: null, targetDate: null, done: null } });
  const [showSC, setShowSC] = useState(JSON.parse(sessionStorage.getItem(`oneYearObjs.${id.toString()}.showSC`)) || false);
  const [progress, setProgress] = useState(0);

  const itemType = "rock";
  const { confirmOpen, handleConfirmOpen, handleDelete } = useConfirmDelete({
    id,
    value,
    itemType,
  });

  const handleEditDialog = (open) => () => {
    setEditDialog(open);
  };

  const handleDuplicate = () => {
    setDialog({
      ...dialog,
      addRockDialog: {
        open: objectiveId,
        quarter: index,
        value,
        users: [],
        duplicate: id,
        successCriterias: successCriterias.map((sc) => _.omit(sc, ["id"])),
        planId
      },
    });
  };

  const handleAddIssueDialog =
    (referenceId = null, referenceModel = null, value = null, user = null) =>
      () => {
        setDialog({
          ...dialog,
          addTodoDialog: {
            open: true,
            category: "issue",
            referenceId,
            referenceModel,
            value,
            user,
            planId
          },
        });
      };

  const handleUpdateStatus = (status) => async (e) => {
    e.stopPropagation();
    const ok = await updateRock({ variables: { id, status } });

    if (ok.data.updateRock) {
      snack(`"${value}" marked as ${status === "complete" ? "complete" : "incomplete"}`);
      requestFetch();
    }
  };

  const handleUpdateHide = async (e) => {
    e.stopPropagation();
    const ok = await updateRock({ variables: { id, hide: !hide } });

    if (ok.data.updateRock) {
      snack(`"${value}" marked as ${hide ? "visible" : "hidden"}`);
      requestFetch();
    }
  };

  const handleAddSuccessCriteriaDialog = () => {
    setDialog({
      ...dialog,
      addSuccessCriteriaDialog: id,
    });
  };

  const handleScEditDialog =
    (id, value = null, targetDate = null, done = null) =>
      () => {
        if (!canEdit) return;
        if (id) {
          setScEditDialog({
            id,
            initForm: { value, targetDate: isDate(targetDate) || _.isNil(targetDate) ? targetDate : new Date(parseInt(targetDate)), done },
          });
        } else {
          setScEditDialog({ id: null, initForm: { value: null, targetDate: null, done: null } });
        }
      };

  const handleShowSC = () => {
    sessionStorage.setItem(`oneYearObjs.${id.toString()}.showSC`, JSON.stringify(!showSC));
    setShowSC((prev) => !prev);
  };

  useEffect(() => {
    const done = successCriterias.reduce((sum, sc) => (sc.done ? sum + 1 : sum), 0);
    setProgress(done);
  }, [successCriterias]);

  const percentageCompleted = (progress / successCriterias.length) * 100;
  const isComplete = (percentageCompleted === 100 || successCriterias.length === 0) && status === "complete";
  const droppableId = `SC_${id}_${rockPath}.successCriterias`;

  return (
    <>
      <List
        dense
        className={`${isDragging ? styles.listDragging : styles.list} ${percentageCompleted > 0 ? styles.hasRockProgress : ""} ${isComplete ? styles.listComplete : ""
          }`}
        id={`${id}-drag`}
      >
        <ListItem onClick={handleShowSC} className={styles.cursor}>
          <ListItemIcon style={{ flexDirection: "column" }}>
            <UserAvatars users={users} />
            {!_.isNil(plan?.departmentName) ? <PlanPill plan={plan} /> : <PlanPill plan={null} />}
          </ListItemIcon>
          <ListItemText>
            <span className={isComplete ? styles.complete : undefined}>{value}</span>
          </ListItemText>
          <NotesButton
            id={id}
            model="rock"
            value={value.trim()}
            user={_.get(users, "0") ? users[0].id : null}
            doc={rock}
            tabs={["notes", "issues", "todos"]}
            canEditTodo={canEdit}
            planId={planId}
          />
          {canEdit && (
            <Menu>
              {/* <MenuItem onClick={handleAddSuccessCriteriaDialog}>Add Success Criteria</MenuItem> */}
              {/* <MenuItem onClick={handleAddIssueDialog(id, "Rock", value.trim(), users[0] ? users[0].id : null)}>Add Issue</MenuItem> */}
              <MenuItem onClick={handleEditDialog(true)}>Edit</MenuItem>
              <MenuItem onClick={handleUpdateStatus(isComplete ? "on track" : "complete")}>
                {isComplete ? "Revert complete" : "Complete"}
              </MenuItem>
              <MenuItem onClick={handleUpdateHide}>{hide ? "Show" : "Hide"}</MenuItem>
              <MenuItem onClick={handleDuplicate}>Copy</MenuItem>
              <MenuItem onClick={handleConfirmOpen(true)} className={styles.delete}>
                Delete
              </MenuItem>
            </Menu>
          )}
        </ListItem>

        <Droppable droppableId={droppableId} key={id} type="successCriteria">
          {(provided, snapshot) => {
            return (
              <Collapse
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={snapshot.isDraggingOver ? styles.droppableDragging : styles.droppable}
                in={showSC}
              >
                {_.isEmpty(successCriterias) ? (
                  <ListItem>
                    <ListItemText className={styles.listItemNoRock}>
                      No success criteria.{" "}
                      {canEdit && (
                        <u onClick={handleAddSuccessCriteriaDialog} className={styles.cursor}>
                          Add one
                        </u>
                      )}
                    </ListItemText>
                  </ListItem>
                ) : (
                  successCriterias.map(({ id: scId, value, targetDate, done }, idx) => {
                    return (
                      <Draggable
                        key={`${scId}_${id}`}
                        draggableId={`SC_${scId}_${id}`}
                        index={idx}
                        isDragDisabled={!isAuthed(user.user, "department facilitator") || !canEdit}
                      >
                        {(provided, snapshot) => {
                          return (
                            <>
                              <ListItem
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                ref={provided.innerRef}
                                style={{ ...provided.draggableProps.style }}
                                key={`${scId}_${id}`}
                              >
                                <ListItemIcon className={styles.listIcon}>
                                  {done ? (
                                    <Icon path={mdiCheckboxBlankCircle} size={1} color={green[300]} />
                                  ) : (
                                    <Icon path={mdiDecagram} size={1} color={red[300]} />
                                  )}
                                </ListItemIcon>
                                <ListItemText
                                  className={done ? styles.complete : styles.listItem}
                                  onClick={handleScEditDialog(scId, value, targetDate, done)}
                                >
                                  <span>{value}</span>
                                  {targetDate && (
                                    <span className={before(targetDate, startOfToday()) ? styles.targetDatePast : styles.targetDate}>
                                      {` (${formatAs(targetDate, "MMM dd, yyyy")})`}
                                    </span>
                                  )}
                                </ListItemText>
                              </ListItem>
                              {rockScStrategy === "copy" && snapshot.isDragging && snapshot.draggingOver !== droppableId && (
                                <ListItem key={`${scId}_${id}`}>
                                  <ListItemIcon className={styles.listIcon}>
                                    {done ? (
                                      <Icon path={mdiCheckboxBlankCircle} size={1} color={green[300]} />
                                    ) : (
                                      <Icon path={mdiDecagram} size={1} color={red[300]} />
                                    )}
                                  </ListItemIcon>
                                  <ListItemText className={done ? styles.complete : styles.listItem}>{value}</ListItemText>
                                </ListItem>
                              )}
                            </>
                          );
                        }}
                      </Draggable>
                    );
                  })
                )}
                {snapshot.isDraggingOver && provided.placeholder}
              </Collapse>
            );
          }}
        </Droppable>

        <div className={`${styles.rockProgress} ${isComplete ? styles.rpComplete : ""}`} style={{ width: `${percentageCompleted}%` }}>
          {percentageCompleted > 0 && <div style={{ padding: "0 4px" }}>{Math.round(percentageCompleted)}%</div>}
        </div>
      </List>

      <ConfirmDeletionDialog
        itemType={itemType}
        value={value}
        confirmOpen={confirmOpen}
        handleConfirmOpen={handleConfirmOpen}
        handleDeletion={handleDelete(deleteRock)}
      />
      {editDialog && (
        <EditDialog
          open={editDialog}
          handleClose={handleEditDialog(false)}
          rock={rock}
          snack={snack}
          handleAddIssueDialog={handleAddIssueDialog}
          planId={planId}
          objectiveId={objectiveId}
        />
      )}
      <ScEditDialog
        open={Boolean(scEditDialog.id)}
        handleClose={handleScEditDialog(false)}
        id={scEditDialog.id}
        initForm={scEditDialog.initForm}
        snack={snack}
        handleAddIssueDialog={handleAddIssueDialog}
      />
    </>
  );
};

export default Rock;

const DELETE_ROCK = gql`
  mutation OneYearDeleteRock($id: ID!) {
    deleteRock(id: $id) {
      deletedRock {
        id
      }
      deletedSuccessCriterias {
        id
      }
      updatedObjective {
        id
        rocks {
          id
        }
      }
      updatedPlan {
        id
        rocks {
          id
        }
      }
    }
  }
`;

const UPDATE_ROCK = gql`
  mutation ($id: ID!, $status: String, $hide: Boolean) {
    updateRock(id: $id, status: $status, hide: $hide)
  }
`;
