import React, { useEffect, useContext, useState } from "react";
import styles from "./Dashboard.module.scss";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { Container, Grid, Hidden, Typography } from "@material-ui/core";
import { FetchContext } from "../../context/fetchContext";
import Loading from "../../components/Loading/Loading";
import Rocks from "../../components/Rocks/Rocks";
import Todos from "../../components/Todos/Todos";
import WeeklyTargets from "../../components/WeeklyTargets/WeeklyTargets";
import SelectCorpYear from "../../components/SelectCorpYear/SelectCorpYear";
import _ from "lodash";
import useTableFilters from "../../hooks/useTableFilters";
import useCorpPlan from "../../hooks/useCorpPlan";
import { TODO_FIELDS, USER_FIELDS, WEEKLY_TARGET_FIELDS } from "../../utils/fragments";

// localstorage location
const TODO_LOCATION = "dashboardTodo";
const ISSUE_LOCATION = "dashboardIssue";

const Dashboard = ({ user, params, org }) => {
  const { fetch } = useContext(FetchContext);
  const fiscalYear = org.fiscalYear;
  const { corpForSelectedYear, setCorpForSelectedYear, corpPlans } = useCorpPlan({ orgId: params.org, fiscalYear });
  // todo
  const {
    page,
    rowsPerPage,
    sort,
    searchTerm,
    debouncedSearchTerm,
    showCompleted,
    handleChangePage,
    handleRowsPerPage,
    setSort,
    setSearchTerm,
    setShowCompleted,
  } = useTableFilters({
    location: TODO_LOCATION,
    initialValue: {
      rowsPerPage: JSON.parse(localStorage.getItem(`${TODO_LOCATION}.rowsPerPage`)) || 10,
      showCompleted: JSON.parse(localStorage.getItem(`${TODO_LOCATION}.showCompleted`)) || false,
      sortValue: JSON.parse(localStorage.getItem(`${TODO_LOCATION}.sortValue`)) || "priorityValue",
      sortOrder: JSON.parse(localStorage.getItem(`${TODO_LOCATION}.sortOrder`)) || "asc",
    },
  });

  // issue
  const {
    page: page2,
    rowsPerPage: rowsPerPage2,
    sort: sort2,
    searchTerm: searchTerm2,
    debouncedSearchTerm: debouncedSearchTerm2,
    showCompleted: showCompleted2,
    handleChangePage: handleChangePage2,
    handleRowsPerPage: handleRowsPerPage2,
    setSort: setSort2,
    setSearchTerm: setSearchTerm2,
    setShowCompleted: setShowCompleted2,
  } = useTableFilters({
    location: ISSUE_LOCATION,
    initialValue: {
      rowsPerPage: JSON.parse(localStorage.getItem(`${ISSUE_LOCATION}.rowsPerPage`)) || 10,
      showCompleted: JSON.parse(localStorage.getItem(`${ISSUE_LOCATION}.showCompleted`)) || false,
      sortValue: JSON.parse(localStorage.getItem(`${ISSUE_LOCATION}.sortValue`)) || "priorityValue",
      sortOrder: JSON.parse(localStorage.getItem(`${ISSUE_LOCATION}.sortOrder`)) || "asc",
    },
  });

  useEffect(() => {
    handleChangePage({}, 0);
    handleChangePage2({}, 0);
  }, [corpForSelectedYear]);

  const { data, loading, refetch } = useQuery(GET_DATA, {
    variables: {
      organization: params.org,
      user: user.user.id,
      sharedPlanId: user.departmentFilter.sharedPlanId,
      oneYearCorpPlan: corpForSelectedYear.id,
      // todos
      page,
      rowsPerPage,
      searchTerm: debouncedSearchTerm,
      sortBy: sort.value,
      sortDir: sort.order,
      done: showCompleted === true ? null : false,
      //issues
      page2,
      rowsPerPage2,
      searchTerm2: debouncedSearchTerm2,
      sortBy2: sort2.value,
      sortDir2: sort2.order,
      done2: showCompleted2 === true ? null : false,
    },
    fetchPolicy: "network-only", // Used for first execution
    nextFetchPolicy: "cache-first", // Used for subsequent executions,
  });

  useEffect(() => {
    refetch();
  }, [fetch]);

  if (loading) return <Loading />;

  const { todos, todosCount, issues, issuesCount, rocks, weeklyTargets } = data;

  return (
    <Container maxWidth={false}>
      <Grid container spacing={3}>
        <Hidden>
          <Grid item xs={12}>
            <Typography variant="h6" className={styles.label}>
              Dashboard
            </Typography>
            <SelectCorpYear
              corporatePlans={corpPlans}
              fiscalYear={fiscalYear}
              corpForSelectedYear={corpForSelectedYear}
              setCorpForSelectedYear={setCorpForSelectedYear}
            />
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={6}>
          <Todos
            todos={todos}
            handleChangePage={handleChangePage}
            handleRowsPerPage={handleRowsPerPage}
            page={page}
            rowsPerPage={rowsPerPage}
            sort={sort}
            searchTerm={searchTerm}
            setSort={setSort}
            setSearchTerm={setSearchTerm}
            setShowCompleted={setShowCompleted}
            showCompleted={showCompleted}
            location={TODO_LOCATION}
            total={todosCount.total}
            totalCompleted={todosCount.totalCompleted}
            planId={_.get(corpForSelectedYear, "id")}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Todos
            todos={issues}
            issues
            handleChangePage={handleChangePage2}
            handleRowsPerPage={handleRowsPerPage2}
            page={page2}
            rowsPerPage={rowsPerPage2}
            sort={sort2}
            searchTerm={searchTerm2}
            setSort={setSort2}
            setSearchTerm={setSearchTerm2}
            setShowCompleted={setShowCompleted2}
            showCompleted={showCompleted2}
            location={ISSUE_LOCATION}
            total={issuesCount.total}
            totalCompleted={issuesCount.totalCompleted}
            planId={_.get(corpForSelectedYear, "id")}
          />
        </Grid>
        <Grid item xs={12}>
          <Rocks rocks={rocks} fiscalYear={fiscalYear} planId={corpForSelectedYear.id} />
        </Grid>
        <Grid item xs={12}>
          <WeeklyTargets
            weeklyTargets={weeklyTargets}
            fiscalYear={fiscalYear}
            planId={corpForSelectedYear.id}
            variables={{
              organization: params.org,
              user: user.user.id,
              sharedPlanId: user.departmentFilter.sharedPlanId,
              oneYearCorpPlan: corpForSelectedYear.id,
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Dashboard;

const GET_DATA = gql`
  query Dashboard_GetData(
    $organization: ID!
    $user: ID!
    $sharedPlanId: ID
    $oneYearCorpPlan: ID
    $page: Int
    $rowsPerPage: Int
    $searchTerm: String
    $sortBy: String
    $sortDir: String
    $done: Boolean
    $page2: Int
    $rowsPerPage2: Int
    $searchTerm2: String
    $sortBy2: String
    $sortDir2: String
    $done2: Boolean
  ) {
    rocks(organization: $organization, user: $user, oneYearCorpPlan: $oneYearCorpPlan, sharedPlanId: $sharedPlanId) {
      id
      value
      index
      status
      objective {
        id
      }
      users {
        ...UserFields
      }
      plan {
        id
        departmentName
        shortName
        color
        year
        plan {
          id
          year
        }
      }
      successCriterias {
        id
        value
        targetDate
        done
      }
      notes {
        id
        date
      }
      todos {
        id: _id
        createdAt
      }
    }

    todos(
      organization: $organization
      user: $user
      sharedPlanId: $sharedPlanId
      category: "todo"
      oneYearCorpPlan: $oneYearCorpPlan
      page: $page
      rowsPerPage: $rowsPerPage
      searchTerm: $searchTerm
      sortBy: $sortBy
      sortDir: $sortDir
      done: $done
    ) {
      ...TodoFields
    }
    todosCount: todosCount(
      organization: $organization
      user: $user
      sharedPlanId: $sharedPlanId
      category: "todo"
      oneYearCorpPlan: $oneYearCorpPlan
      searchTerm: $searchTerm
    ) {
      total
      totalCompleted
    }

    issues: todos(
      organization: $organization
      user: $user
      sharedPlanId: $sharedPlanId
      category: "issue"
      oneYearCorpPlan: $oneYearCorpPlan
      page: $page2
      rowsPerPage: $rowsPerPage2
      searchTerm: $searchTerm2
      sortBy: $sortBy2
      sortDir: $sortDir2
      done: $done2
    ) {
      ...TodoFields
    }

    issuesCount: todosCount(
      organization: $organization
      user: $user
      sharedPlanId: $sharedPlanId
      category: "issue"
      oneYearCorpPlan: $oneYearCorpPlan
      searchTerm: $searchTerm
    ) {
      total
      totalCompleted
    }

    weeklyTargets(organization: $organization, user: $user, oneYearCorpPlan: $oneYearCorpPlan, sharedPlanId: $sharedPlanId) {
      ...WeeklyTargetFields
    }
  }

  ${WEEKLY_TARGET_FIELDS}
  ${TODO_FIELDS}
  ${USER_FIELDS}
`;
